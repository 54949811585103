<template>
  <component :bom-item="bomItem"
             :is="renderer()"
             :column="column"
             :has-cart-prompt-for-qty-enabled="hasCartPromptForQtyEnabled"
             :has-enabled-widget-comments="hasEnabledWidgetComments"
             :has-enabled-widget-tags="hasEnabledWidgetTags"
             :is-library-beta-user="isLibraryBetaUser"
             :is-widget="isWidget"
             :is-widget-cart-enabled="isWidgetCartEnabled"
             :has-widget-info-landing-page="hasWidgetInfoLandingPage"
             :is-selected="isSelected"
             :hotpoint="hotpoint"
             :item-label="itemLabel"
             :popover-tags="popoverTags"
             :popover-comments="popoverComments"
             @openHotpointLink="val => $emit('openHotpointLink', val)"
             @openThumbnailPopover="val => $emit('openThumbnailPopover', val)"
             @openHotpointPopover="val => $emit('openHotpointPopover', val)"
             @closeThumbnailPopover="$emit('closeThumbnailPopover', $event)"
             @addToCart="add"
             @open="open"
             @hotpointClicked="showPreview = !showPreview"
             @openTagsPopover="$emit('openTagsPopover', $event)"
             @closeTagsPopover="$emit('closeTagsPopover', $event)"
             @openCommentsPopover="$emit('openCommentsPopover', $event)"
             @closeCommentsPopover="$emit('closeCommentsPopover', $event)"
             @widgetAddToCart="$emit('widgetAddToCart', {
                content: bomItem,
                qty: $event,
                hasCartPromptForQtyEnabled,
                addToCartQtyBehavior
              })"
            @openWidgetPartInfo="$emit('openWidgetPartInfo', $event)"/>
</template>

<script>
import BomCellActions from './BomCellActions'
import BomCellIndicator from './BomCellIndicator'
import BomCellItem from './BomCellItem'
import BomCellName from './BomCellName'
import BomCellPartNumber from './BomCellPartNumber'
import BomCellPrice from './BomCellPrice'
import BomCellQuantity from './BomCellQuantity'
import BomCellTag from './BomCellTag'
import partLocationUtil from '@/components/common/partLocationUtil'
import { toRaw } from 'vue'

export default {
  name: 'BomCell',
  props: {
    addToCartQtyBehavior: {
      type: String,
      required: true
    },
    bomItem: {
      type: Object,
      required: true
    },
    hotpoint: {
      type: Object
    },
    column: {
      type: Object,
      required: true
    },
    hasCartPromptForQtyEnabled: Boolean,
    isWidget: Boolean,
    hasEnabledWidgetTags: Boolean,
    hasEnabledWidgetComments: Boolean,
    isLibraryBetaUser: Boolean,
    isWidgetCartEnabled: Boolean,
    hasWidgetInfoLandingPage: Boolean,
    isSelected: Boolean,
    itemLabel: String,
    popoverTags: {
      type: Array,
      default: () => []
    },
    popoverComments: {
      type: Array,
      default: () => []
    }
  },
  components: {
    BomCellActions,
    BomCellIndicator,
    BomCellItem,
    BomCellName,
    BomCellPartNumber,
    BomCellPrice,
    BomCellQuantity,
    BomCellTag
  },
  methods: {
    renderer() {
      switch (this.column.type) {
        case 'price':
          return 'bom-cell-price'
        case 'tag':
          return 'bom-cell-tag'
        default:
          return `bom-cell-${this.column.property}`
      }
    },
    open ({ content, route }) {
      if (route.includes('details')) {
        // Prevent briefly rendering Page Landing Page
        // before Part content is set in Vuex
        // for opening part Landing Page
        this.$store.dispatch('content/setContentLoading')

        const regexForExtensions = /(\/info$)/
        const toPath = this.$route.path.replace(regexForExtensions, '')
        this.$router.push({ path:  `${toPath}/pagepart:${content.pagePartId}/details`,
          query: {
            tab: route.includes('related') ? 'related' 
              : route.includes('comments') ? 'comments'
              : ''
          } })
      } else {
        const fullPath = this.$route.path
        let path = `${fullPath}${content.type}:${content.id}${route}`
        if (content.pagePartId) {
          path = `${fullPath}/pagepart:${content.pagePartId}${route}`
        }
        this.$store.dispatch('content/navigateToContentPath', { path })
      }
    },
    add (qty) {
      let quantity = 1
      switch (this.addToCartQtyBehavior) {
        case 'qtyZero':
          quantity = this.hasCartPromptForQtyEnabled ? 0 : 1
          break
        case 'qtyFromBom':
          quantity = parseInt(this.bomItem.quantity, 10) || 1
          break
        default:
          quantity = 1
      }

      const params = {
        part: toRaw(this.bomItem),
        qty: qty || quantity,
        partLocation: partLocationUtil.getPartLocationData(this.$route.path)
      }
      this.$store.dispatch('cart/addToCart', params)
    }
  }
}
</script>
