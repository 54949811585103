<template>
  <bulma-field  class="is-flex">
    <bulma-number-input :min="min"
                        :max="max"
                        :value="quantity"
                        data-int="info-quantity-input"
                        @input="$emit('update:quantity', $event)"/>
    <button v-if="includeAddBtn"
            data-testid="add-quantity-button"
            data-int="info-add-quantity-button"
            class="button is-primary no-border-radius-left"
            :disabled="quantity < 1"
            @click="add">
      {{ $t('add') }}
    </button>
  </bulma-field>
</template>

<script>
import partLocationUtil from '@/components/common/partLocationUtil'
import BulmaField from '@/components/common/BulmaField.vue'
import BulmaNumberInput from '@/components/common/BulmaNumberInput.vue'

export default {
  components: { BulmaField, BulmaNumberInput },
  emits: ['addToCart', 'update:quantity'],
  props: {
    quantity: {
      type: Number,
      required: true,
      default: 1
    },
    content: {
      type: Object
    },
    includeAddBtn: {
      type: Boolean,
      required: true
    },
    expanded: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    min () {
      return 0
    },
    max () {
      return 999999999
    },
    partLocation () {
      return partLocationUtil.getPartLocationData(this.$route.path)
    }
  },
  methods: {
    add () {
      this.$emit('addToCart', {
        part: this.content,
        qty: this.quantity,
        partLocation: this.partLocation
      })
    }
  }
}
</script>
<style>
  .center-input-text input {
    text-align: center;
  }
</style>
