<template>
  <td
    class="cell"
    data-int="cart-cell-qty"
  >
    <input
      v-if="canEdit"
      v-model="quantity"
      type="number"
      data-int="cart-cell-qty-input"
      class="input"
      min="0"
      max="999999999"
      :data-key="uuid"
      :key="uuid"
    >
    <div
      class="has-text-centered"
      v-else
    >
      {{ quantity }}
    </div>
  </td>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import debounce from 'lodash.debounce'
export default {
  name: 'CartCellQty',
  props: {
    item: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    quantity: {
      get: function () {
        return this.item.qty
      },
      set: debounce(function (value) {
        if (value !== '') {
          this.$emit('update', {
            item: this.item,
            qty: value
          })
        }
      }, 1000)
    }
  },
  setup () {
    return {
      uuid: uuidv4()
    }
  }
}
</script>

<style scoped>
.cell, .input {
  width: 5rem;
}
</style>
