<template>
  <div class="details-page-wrapper">
    <div class="details-wrapper">
      <div  class="card top-card">
        <info-wrapper :showTags="false"/>
      </div>
      <div  v-if="content"
            class="card p-1" 
            :class="{'top-card': !isTabletView }">
        <info-tags :id="castIdToNumber(content.id)"
                   :type="content.type"
                   :style="infoTagsStyle"/>
      </div>
    </div>
    <div  v-if="hasAnyTabMetadata"
          id="about-wrapper"
          class="about-wrapper card m-4">
      <div class='container'
           :class="{'tabbed-accordian': mediaQuery <= 770}">
        <div class="columns is-centered">
          <div class="column">
            <div class="is-centered"
                 :class="{'tabs':  mediaQuery > 770, 'accordian': mediaQuery <= 770}">
              <ul>
                <li  v-if="canViewComments"
                     :class="{'is-active': tabStates.comments}"
                     id="comment"
                     @click="selectTab('comments')">
                  <a  class="tab-link">
                    <div>
                      <pills-tag  :text="$tc('comment', 2)"
                                  :total="content.comments.total"/>
                    </div>
                    <span class="drawer-icons">
                      <i  v-if="tabStates.comments && isTabletView"
                          class="fa fa-minus" />
                      <i v-else-if="isTabletView" class="fa fa-plus"/>
                    </span>
                  </a>
                </li>
                <transition  v-if="canViewComments"
                             name="slide" mode="in-out"
                             enter-from-class="slide-up-enter"
                             leave-from-class=""
                             leave-to-class="">
                  <div v-if="tabStates.comments"
                       class="library-tab-content">
                    <comments></comments>
                  </div>
                </transition>
                <li   v-if="canViewRelated"
                      :class="{'is-active': tabStates.related}"
                      id="related"
                      @click="selectTab('related')">
                  <a  class="tab-link">
                    <div>
                      <pills-tag  :text="$t('related')"
                                  :total="content.related.total"/>
                    </div>
                    <span class="drawer-icons">
                      <i  v-if="tabStates.related && isTabletView" 
                          class="fa fa-minus" />
                      <i v-else-if="isTabletView" class="fa fa-plus"/>
                    </span>
                  </a>
                </li>
                <transition  v-if="canViewRelated"
                             name="slide" mode="in-out"
                             enter-from-class="slide-up-enter"
                             leave-from-class=""
                             leave-to-class="">
                  <div v-if="tabStates.related"
                       class="library-tab-content">
                    <related></related>
                  </div>
                </transition>
                <li v-if="canViewWhereUsed"
                    :class="{'is-active': tabStates.whereused}"
                    id="whereused"
                    @click="selectTab('whereused')">
                  <a  class="tab-link">
                    <div>
                      <pills-tag  :text="$t('whereUsed')"
                                  :total="content.whereused.total"/>
                    </div>
                    <span class="drawer-icons">
                      <i  v-if="tabStates.whereused && isTabletView" 
                          class="fa fa-minus" />
                      <i v-else-if="isTabletView" class="fa fa-plus"/>
                    </span>
                  </a>
                </li>
                <transition name="slide" mode="in-out"
                            enter-from-class="slide-up-enter"
                            leave-from-class=""
                            leave-to-class="">
                  <div v-if="tabStates.whereused"
                       class="library-tab-content">
                      <where-used :height="'25em'"
                                  :open-links-in-new-tab="null"
                                  :show-title="false">
                      </where-used>
                  </div>
                </transition>
                <li v-if="canViewSuggestions"
                    :class="{'is-active': tabStates.suggestions}"
                    id="suggestions"
                    @click="selectTab('suggestions')">
                  <a  class="tab-link">
                    <div>
                      <pills-tag  :text="$tc('suggestion', 2)"
                                  :total="content.suggestions.total"/>
                    </div>
                    <span class="drawer-icons">
                      <i  v-if="tabStates.suggestions && isTabletView" 
                          class="fa fa-minus" />
                      <i v-else-if="isTabletView" class="fa fa-plus"/>
                    </span>
                  </a>
                </li>
                <transition v-if="canViewSuggestions"
                            name="slide" mode="in-out"
                            enter-from-class="slide-up-enter"
                            leave-from-class=""
                            leave-to-class="">
                  <div v-if="tabStates.suggestions"
                       class="library-tab-content">
                    <suggestions></suggestions>
                  </div>
                </transition>
              </ul>
            </div>
          </div>
        </div>
        <div  class="tab-view-contents">
        <!-- For mobile, multiples tabs may be opened at once
             For normal desktop view, only one should open at a time
         -->
          <comments v-if="tabStates.comments && canViewComments"/>
          <related  v-if="tabStates.related && canViewRelated"/>
          <where-used   v-if="tabStates.whereused && canViewWhereUsed"
                        :height="'40em'"
                        :open-links-in-new-tab="null"
                        :show-title="false"/>
          <suggestions  v-if="tabStates.suggestions && canViewSuggestions"/>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script setup>
import InfoWrapper from "@/components/library/content/info/InfoWrapper.vue"
import InfoTags from "@/components/library/content/info/tags/InfoTags.vue"
import Comments from "@/components/library/content/comments/Comments.vue"
import Suggestions from "@/components/library/content/suggestions/Suggestions.vue"
import WhereUsed from'../whereused/WhereUsed.vue'
import Related from "@/components/library/content/related/Related.vue"
import PillsTag from "@/components/common/PillsTag.vue"
import { onMounted, computed, reactive, watch } from "vue"
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useBreakpoints } from "@/helpers"
import { EntityType } from "@/interfaces/global"

const { mediaQuery } = useBreakpoints()
const store = useStore()
const route = useRoute()

const tabStates = reactive({
  comments: false,
  whereused: false,
  suggestions: false,
  related: false
})

const content = computed(() => store.state['content'])
const isPart = computed(() => content.value.type === EntityType.part)
const isPage = computed(() => content.value.type === EntityType.page)

const canViewComments =  computed(() => store.getters['user/canViewComments'])
const canViewRelated = computed(() => store.getters['user/canViewRelated'])
const canViewWhereUsed = computed(() => isPart.value || isPage.value)
const canViewSuggestions = computed(() => {
  return store.getters['user/hasPartOrderSuggestions'] && isPart.value
})
const isTabletView = computed(() => mediaQuery.value <= 770)
const hasAnyTabMetadata = computed(() => {
  return canViewComments.value || canViewRelated.value
    || canViewWhereUsed.value || canViewSuggestions.value
})
const infoTagsStyle = computed(() => {
  return isTabletView.value ? ""
    : "max-height: 100%; display: flex; flex-direction: column;"
})

watch(isTabletView, val => {
  if (val !== false) return

  let hasActiveField = false

  for (const field in tabStates) {
    if (hasActiveField) {
      tabStates[field] = false
    } 
    else if (tabStates[field] === true) {
      hasActiveField = true
    }
  }

  if (hasActiveField === false) {
    selectFirstTab()
  }
})

function castIdToNumber (id) {
  return parseInt(id)
}

function selectTab (tab) {
  if (isTabletView.value) {
    tabStates[tab] = !tabStates[tab]
  } else {
    for (const property in tabStates) {
      tabStates[property] = (property.toString() === tab)
    }
  }
}

function selectFirstTab() {
  if (canViewComments.value) {
    tabStates.comments = true
  } else if (canViewRelated.value) {
    tabStates.related = true
  } else if (canViewWhereUsed.value) {
    tabStates.whereused = true
  } else if (canViewSuggestions) {
    tabStates.suggestions = true
  }
}

onMounted(async () => {
  if(route.fullPath.includes('tab=related')) {
    tabStates.related = true
    document.getElementById("related").scrollIntoView()
  } else if (route.fullPath.includes('tab=comments')) { 
    tabStates.comments = true
    document.getElementById("comment").scrollIntoView()
  } else if (!isTabletView.value) {
    selectFirstTab()
  }
})
</script>

<style scoped lang="scss">
.details-wrapper {
  display: flex;
  flex-direction: row;

  > .card {
    margin: 1em;
    width: 50%;
  }
  div:last-child {
    flex-grow: 1
  }
}
.card:hover {
  border: none;
}
.columns {
  overflow: unset;
}
.top-card {
  height: 428px;
}
.about-wrapper {
  .container {
    min-height: 10em;
  }
}

@media screen and (max-width: 768px) {
  .details-wrapper {
    flex-direction: column;

    > .card {
      margin: 0;
      width: 100%;
    }
  }
  .tab-link {
    color: black;
  }
  .top-card {
    height: fit-content;
  }
}
</style>
