<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div data-int="export-modal"
         class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <strong data-int="export-modal-title">{{ $t('export') }}</strong>
        </p>
        <button data-int="export-modal-cancel"
                class="delete"
                aria-label="cancel"
                @click="cancel"/>
      </header>
      <section class="modal-card-body">
        <div v-if="!!book"
             class="field">
          <label data-testid="section"
                 class="label">
            {{ $t('section') }}
          </label>
          <div v-if="canPrintBook && book && book.id !== content.id"
               class="control truncate"
               @click="selectedSelection = book">
            <input type="radio"
                   :checked="selectedSelection === book"
                   class="is-checkradio">
            <label data-testid="book">{{ $tc('book', 1) }} | {{ book.name }}</label>
          </div>
          <div class="control truncate"
               @click="selectedSelection = content">
            <input type="radio"
                   :checked="selectedSelection === content"
                   class="is-checkradio">
            <label data-testid="content">{{ contentType }} | {{ content.name }}</label>
          </div>
        </div>

        <div class="field">
          <label data-testid="format"
                 class="label">
            {{ $t('format') }}
          </label>
          <div v-if="!fixedFormat || fixedFormat ==='pdf'"
               class="control"
               @click="format = 'pdf'">
            <input type="radio"
                   :checked="format === 'pdf'"
                   class="is-checkradio">
            <label data-testid="export-as-pdf">{{ $t('exportAsPdf') }}</label>
          </div>
          <div v-if="selectionType === 'illustration' && isPublisher && !isEssentialsTenant"
               class="control"
               @click="format = 'archive'">
            <input type="radio"
                   :checked="format === 'archive'"
                   class="is-checkradio">
            <label data-testid="export-as-plz">{{ $t('exportAsPlz') }}</label>
          </div>
        </div>

        <div v-if="format === 'pdf'"
             class="field">
          <label data-testid="options"
                 class="label options">
            {{ $t('options') }}
          </label>
          <div class="columns is-multiline is-mobile">
            <div class="column is-half">
              <div v-if="!!book"
                   class="control pb-2">
                <div class="select is-fullwidth">
                  <select v-model="toc">
                    <option v-for="preference in tocPreferences"
                            :key="preference.id"
                            :value="preference.id">
                      {{ preference.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="control"
                   @click="twosided=!twosided">
                <input type="checkbox"
                       :checked="twosided"
                       class="is-checkradio">
                <label>{{ $t('twoSided') }}</label>
              </div>
              <div class="control"
                   @click="includeImages = !includeImages">
                <input type="checkbox"
                       :checked="includeImages"
                       class="is-checkradio">
                <label>{{ $t('includeImages') }} </label>
              </div>
              <div class="control"
                   @click="includeParts = !includeParts">
                <input type="checkbox"
                       :checked="includeParts"
                       class="is-checkradio">
                <label>{{ $t('includePartsList') }} </label>
              </div>
              <div v-if="selection.type === 'chapter'"
                   class="control"
                   @click="includeSubChapters = !includeSubChapters">
                <input type="checkbox"
                       :checked="includeSubChapters"
                       class="is-checkradio">
                <label>{{ $t('includeSubChapters') }}</label>
              </div>
            </div>
            <div class="column is-half">
              <div class="control pb-2">
                <div class="select is-fullwidth">
                  <select v-model="size">
                    <option v-for="size in pageSizes"
                            :key="size.id"
                            :value="size.id">
                      {{ size.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="control"
                   @click="orientation = 'PORTRAIT'">
                <input type="radio"
                       :checked="orientation === 'PORTRAIT'"
                       class="is-checkradio">
                <label class="radio">{{ $t('portrait') }} </label>
              </div>
              <div class="control"
                   @click="orientation = 'LANDSCAPE'">
                <input type="radio"
                       :checked="orientation === 'LANDSCAPE'"
                       class="is-checkradio">
                <label class="radio">{{ $t('landscape') }} </label>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!localeHidden"
             class="field">
          <label data-testid="locale"
                 class="label locales">
            {{ $t('locale') }}
          </label>
          <div class="control">
            <div class="select is-fullwidth">
              <select data-testid="displayed-locale"
                      v-model="locale">
                <option v-for="locale in locales"
                        :key="locale.id"
                        :value="locale">
                  {{ locale.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button data-testid="submit-btn"
                class="button is-primary"
                @click="submit">
          {{ $t('submit') }}
        </button>
        <button data-testid="cancel-btn"
                class="button"
                @click="cancel">
          {{ $t('cancel') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ExportModal',
  props: {
    /**
     * use this to set format to PLZ. Currently,
     * we only are able to export page illustrations
     * as PLZ and not PDF.
     */
    fixedFormat: {
      type: String,
      default: null,
      validator: (val) => ['pdf', 'archive'].indexOf(val) > -1
    },
    disableRedirect: {
      type: Boolean,
      default: false,
      required: false
    },
    localeHidden: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      selection: undefined,
      format: '',
      locale: undefined,
      size: undefined,
      toc: undefined,
      twosided: false,
      includeImages: true,
      includeParts: true,
      includeSubChapters: false,
      orientation: undefined,
      pageSizes: [
        { id: 'LETTER', name: this.$i18n.t('letter') },
        { id: 'LEGAL', name: this.$i18n.t('legal') },
        { id: 'TABLOID', name: this.$i18n.t('tabloid') },
        { id: 'LEDGER', name: this.$i18n.t('ledger') },
        { id: 'A3', name: 'A3' },
        { id: 'A4', name: 'A4' },
        { id: 'A5', name: 'A5' }
      ],
      tocPreferences: [
        { id: 'ALL', name: `${this.$i18n.t('toc')}: ${this.$i18n.t('all')}` },
        { id: 'NONE', name: `${this.$i18n.t('toc')}: ${this.$i18n.t('none')}` },
        { id: 'CHAPTERS_ONLY', name: `${this.$i18n.t('toc')}: ${this.$i18n.t('chaptersOnly')}` }
      ]
    }
  },
  watch: {
    locales () {
      this.init()
    },
    content () {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      hasDisableBookPrinting: 'user/hasDisableBookPrinting',
      isPublisher: 'user/isPublisher',
      isEssentialsTenant: 'user/isEssentialsTenant'
    }),
    ...mapState({
      content: (state) => state.content,
      book: (state) => state.content.toc.book,
      locales: (state) => state.locales.items
    }),
    canPrintBook () {
      return !this.hasDisableBookPrinting && this.book
    },
    contentType () {
      return this.$i18n.tc(this.content.contentType || this.content.type, 1)
    },
    selectionType () {
      return this.selection ? this.selection.contentType : ''
    },
    selectedSelection: {
      get () {
        return this.selection
      },
      set (value) {
        this.selection = value
        if (this.selection.type === 'chapter') {
          this.format = 'pdf'
        }
      }
    }
  },
  methods: {
    init () {
      this.selection = this.content
      this.format = this.fixedFormat || 'pdf'
      this.toc = this.tocPreferences[0].id
      this.size = this.pageSizes[0].id
      this.orientation = 'PORTRAIT'

      if (this.locales) {
        this.locale = this.locales.find((locale) => locale.code4 === this.$i18n.locale)
      }
    },
    async submit () {
      const payload = {
        contentId: this.selection.id,
        contentType: this.selection.contentType || this.selection.type,
        generateChildChapters: this.includeSubChapters,
        includeImages: this.includeImages,
        includePartsList: this.includeParts,
        localeLanguageCode: this.locale.code4,
        mediaId: this.book ? this.book.id : undefined,
        orientation: this.orientation,
        pageSize: this.size,
        tocPref: this.toc,
        twoSidedPrinting: this.twosided,
        format: this.format
      }
      await this.$emit('exportContent', payload)
      /**
       * Disable redirect inside this component to let
       * page illustration call it's export illustration
       * function then handle redirecting if request successful.
       * This is kind of a cheap-last-minute-solution...
       *
       */
      if (!this.disableRedirect) {
        this.$router.push({ name: 'JobManager' })
      }
    },
    cancel () {
      this.$emit('cancel')
    }
  },
  async mounted () {
    this.init()
  },
  created () {
    this.$store.dispatch('locales/getLocales')
  }
}
</script>

<style scoped>
.modal-card-body {
  flex-direction: column;
}
.column {
  padding-top: .1rem;
  padding-bottom: .1rem;
}
.options {
  padding-bottom: .75rem;
}
.locales {
  padding-top: .5rem;
}
.button {
  margin-right: .5rem;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .modal-card {
    height: 40rem !important;
  }
}
@media only screen and (max-width: 769px) {
  .modal-card {
    width: 95%;
  }
}
</style>
