<template>
  <nav
    v-if="(content.orderable && (hasWidgetCart || pricing.isLoaded))"
    class="level"
  >
    <div
      v-if="displayRetail && pricing.retailAmount >= 0"
      class="level-item price"
    >
      <p
        class="heading"
        data-int="info-retail-title"
        data-testid="info-retail-title"
      >
        {{ $t('retail') }}
      </p>
      <p
        class="subtitle is-6"
        data-int="info-retail-price"
        data-testid="info-retail-price"
      >
        {{ pricing.retailAmount === 0 ? $t('noPrice') : pricing.retailPrice }}
      </p>
    </div>
    <div
      v-if="displayDiscounted && pricing.discountAmount >= 0"
      class="level-item price"
    >
      <p
        class="heading"
        data-int="info-discounted-title"
        data-testid="info-discounted-title"
      >
        {{ $t('discounted') }}
      </p>
      <p
        class="subtitle is-6"
        data-int="info-discounted-price"
        data-testid="info-discounted-price"
      >
        {{ pricing.discountAmount === 0 ? $t('noPrice') : pricing.discountPrice }}
      </p>
    </div>
    <div
      v-if="displayWholeSale && pricing.wholesaleAmount >= 0"
      class="level-item price"
    >
      <p
        class="heading"
        data-int="info-wholesale-title"
        data-testid="info-wholesale-title"
      >
        {{ $t('wholesale') }}
      </p>
      <p
        class="subtitle is-6"
        data-int="info-wholesale-price"
        data-testid="info-wholesale-price"
      >
        {{ pricing.wholesaleAmount === 0 ? $t('noPrice') : pricing.wholesalePrice }}
      </p>
    </div>
    <div
      v-if="displayAvailability && pricing.availability"
      class="level-item price"
    >
      <p
        class="heading"
        data-int="info-availablity-title"
      >
        {{ $t('availability') }}
      </p>
      <p
        class="subtitle is-6 subtitle-limit"
        data-int="info-availability"
      >
        {{ pricing.availability }}
      </p>
    </div>
    <div
      v-if="displayEta && pricing.eta"
      class="level-item price"
    >
      <p
        class="heading"
        data-int="info-eta-title"
      >
        {{ $t('eta') }}
      </p>
      <p
        class="subtitle is-6 subtitle-limit"
        data-int="info-eta-price"
      >
        {{ pricing.eta }}
      </p>
    </div>
    <div
      v-if="isCartOrErpCartEnabled"
      class="add-to-cart-container"
    >
      <add-to-cart v-model:quantity="quantity"
                   :content="content"
                   :expanded="false"
                   :include-add-btn="true"
                   @addToCart="handleAddToCart"/>
    </div>
  </nav>
</template>

<script>
import { mapActions } from 'vuex'
import AddToCart from './AddToCart.vue'

export default {
  name: 'InfoAddToCart',
  props: {
    content: {
      type: Object,
      required: true
    },
    pricing: {
      type: Object,
      default: () => ({})
    },
    isCartOrErpCartEnabled: {
      type: Boolean,
      default: false
    },
    displayRetail: {
      type: Boolean,
      default: false
    },
    displayWholeSale: {
      type: Boolean,
      default: false
    },
    displayDiscounted: {
      type: Boolean,
      default: false
    },
    displayEta: {
      type: Boolean,
      default: false
    },
    displayAvailability: {
      type: Boolean,
      default: false
    },
    addToCartQtyBehavior: {
      type: String,
      default: ''
    },
    hasWidgetCart: Boolean
  },
  components: {AddToCart},
  data() {
    return {
      quantity: 0
    }
  },
  methods: {
    ...mapActions({
      addToCart: 'cart/addToCart'
    }),
    handleAddToCart(dto) {
      if (this.hasWidgetCart) {
        this.$emit('widgetAddToCart', dto)
      } else {
        this.addToCart(dto)
      }
    },
    getInitialQuantity() {
      switch (this.addToCartQtyBehavior) {
        case 'qtyZero':
          return 0
        case 'qtyFromBom':
          return this.content.quantity ? parseInt(this.content.quantity, 10) : 1
        default:
          return 1
      }
    }
  },
  mounted() {
    this.quantity = this.getInitialQuantity()
  }
}
</script>

<style scoped>
.level {
  margin-top: 1.25rem;
  margin-bottom: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.subtitle {
  font-style: oblique;
  margin-bottom: .5rem;
  margin-left: .5rem;
  margin-right: .5rem;
}

.subtitle-limit {
  margin: auto;
  max-width: 150px;
}

.input {
  width: 4.25rem;
}

.price {
  display: block;
  text-align: center;
}

.add-to-cart-container {
  margin-top: .5rem;
}

@media only screen and (max-width: 768px) {
  .price {
    display: flex;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 0;
  }
}
</style>
