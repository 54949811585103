<template>
  <b-modal v-model="isActive"
           :can-cancel="false"
           has-modal-card
           trap-focus>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t('save') }}
        </p>
        <button class="delete"
                type="button"
                @click="isActive=false"/>
      </header>

      <section class="modal-card-body">
        <template v-if="isLoaded">
          <b-field :label="$tc('type', 1)"
                   class="is-full-width">
            <b-radio v-if="isSavedCartEnabled"
                     v-model="selectedType"
                     :native-value="$options.typeValues.newSaved"
                     data-unit="newMyCart">
              {{ $t('newItem', {item: $tc('myCart', 1)}) }}
            </b-radio>
          </b-field>
          <b-field class="is-full-width">
            <b-radio v-if="isSharedCartEnabled && isSharedCartMaintainer && !isEssentialsTenant"
                     v-model="selectedType"
                     :native-value="$options.typeValues.newShared"
                     data-unit="newSharedCart">
              {{ $t('newItem', {item: $tc('sharedCart', 1)}) }}
            </b-radio>
          </b-field>
          <b-field class="is-full-width">
            <b-radio v-model="selectedType"
                     :native-value="$options.typeValues.replace">
              {{ $t('replaceExisting') }}
            </b-radio>
          </b-field>

          <template v-if="selectedType === $options.typeValues.replace">
            <search-bar expanded
                        @change="filter=$event"
                        @refresh="load"/>
            <b-table v-model:selected="selectedCartToReplace"
                     :data="filteredCarts"
                     data-unit="replaceTable"
                     default-sort="name"
                     focusable
                     hoverable
                     sort-icon="chevron-up"
                     sort-icon-size="is-small">
              <b-table-column :label="$t('name')"
                              field="name"
                              sortable>
                <template v-slot:name="props">
                  <div v-if="props">
                    {{ props.row.name }}
                    <template v-if="props.row.public">
                      <b-tag rounded
                             type="is-warning">
                        {{ $t('shared') }}
                      </b-tag>
                    </template>
                  </div>
                </template>
              </b-table-column>
              <b-table-column :label="$t('numberOf', {item: $tc('part', 2)})"
                              field="size"
                              header-class="header"
                              sortable
                              width="100">
                <template v-slot:size="props">
                  <div v-if="props">
                    {{ props.row.size }}
                  </div>
                </template>
              </b-table-column>
              <b-table-column :label="$t('updated')"
                              field="updated"
                              sortable
                              width="200">
                <template v-slot:updated="props">
                  <div v-if="props">
                    {{ $d(props.row.updated, 'long') }}
                  </div>
                </template>
              </b-table-column>
            </b-table>
          </template>
          <template v-else>
            <b-field :label="$t('name')"
                     :message="$t('itemMustBeUnique', {item: $t('name')})"
                     :type="{'is-danger': isNameInUse}"
                     data-unit="newNameField">
              <b-input v-model="newCartName"
                       data-unit="newNameInput"/>
            </b-field>
          </template>
        </template>
        <template v-else>
          <utility-block class="is-full-width has-text-centered"/>
        </template>
      </section>

      <footer class="modal-card-foot">
        <b-field class="is-full-width"
                 grouped
                 position="is-right">
          <b-button data-int="cart-open-modal-cancel-button"
                    @click="isActive=false">
            {{ $t('cancel') }}
          </b-button>
          <b-button :disabled="!canSave"
                    data-int="cart-open-modal-open-button"
                    data-unit="saveButton"
                    type="is-primary"
                    @click="save">
            {{ $t('save') }}
          </b-button>
        </b-field>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import SearchBar from '@/components/common/SearchBar'
import UtilityBlock from '@/components/common/UtilityBlock'
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'SaveCartModal',
  components: {
    SearchBar,
    UtilityBlock
  },
  data() {
    return {
      filter: '',
      isActive: false,
      newCartName: '',
      selectedType: this.$options.typeValues.newSaved,
      selectedCartToReplace: null
    }
  },
  typeValues: {
    newSaved: 'NEW_SAVED',
    newShared: 'NEW_SHARED',
    replace: 'REPLACE'
  },
  computed: {
    ...mapState({
      isSavedCartsLoaded: (state) => state.savedCarts.isLoaded,
      isSharedCartsLoaded: (state) => state.sharedCarts.isLoaded,
      otherSharedCartNames: (state) => state.sharedCarts.allNames,
      savedCarts: (state) => state.savedCarts.items,
      sharedCarts: (state) => state.sharedCarts.items
    }),
    ...mapGetters({
      isSavedCartEnabled: 'user/isSavedCartEnabled',
      isSharedCartEnabled: 'user/isSharedCartEnabled',
      isSharedCartMaintainer: 'user/isSharedCartMaintainer',
      isEssentialsTenant: 'user/isEssentialsTenant'
    }),
    canSave() {
      if (this.selectedType === this.$options.typeValues.replace) {
        return this.selectedCartToReplace !== null
      } else {
        return this.newCartName !== null &&
          this.newCartName.length > 0 &&
          !this.isNameInUse
      }
    },
    existingCarts() {
      const carts = []
      carts.push(...this.savedCarts)
      if (this.isSharedCartEnabled && this.isSharedCartMaintainer) {
        carts.push(...this.sharedCarts)
      }

      return carts.sort((a, b) => {
        if (a.name > b.name) return 1
        else if (a.name < b.name) return -1
        else return 0
      })
    },
    filteredCarts() {
      if (!this.filter?.length) return this.existingCarts
      const f = this.filter.toLocaleLowerCase()
      return this.existingCarts.filter((item) => item.name.toLocaleLowerCase().includes(f))
    },
    isLoaded() {
      return this.isSavedCartsLoaded && this.isSharedCartsLoaded
    },
    isNameInUse() {
      const name = this.newCartName.toLocaleLowerCase()
      if (this.selectedType === this.$options.typeValues.newSaved) {
        return this.otherSavedCartNames.indexOf(name) > -1
      } else if (this.selectedType === this.$options.typeValues.newShared) {
        return this.otherSharedCartNames.indexOf(name) > -1
      } else {
        return false
      }
    },
    otherSavedCartNames() {
      return this.savedCarts.map((item) => item.name.toLocaleLowerCase())
    }
  },
  methods: {
    ...mapActions({
      saveCart: 'savedCarts/save',
      saveSharedCart: 'sharedCarts/save'
    }),
    async open() {
      await this.load()

      this.selectedType = this.isSavedCartEnabled
        ? this.$options.typeValues.newSaved
        : this.$options.typeValues.newShared
      this.newCartName = ''
      this.selectedCartToReplace = null
      this.isActive = true
    },
    close() {
      this.isActive = false
    },
    async load() {
      await Promise.all(
        [
          this.$store.dispatch('savedCarts/fetchAll'),
          this.$store.dispatch('sharedCarts/fetchAll')
        ]
      )
    },
    async save() {
      let name = this.newCartName

      switch (this.selectedType) {
        case this.$options.typeValues.newSaved:
          await this.saveCart({name})
          break
        case this.$options.typeValues.newShared:
          await this.saveSharedCart({name})
          break
        case this.$options.typeValues.replace:
          name = this.selectedCartToReplace.name
          if (this.selectedCartToReplace.public) {
            await this.saveSharedCart({name})
          } else {
            (
              await this.saveCart({name})
            )
          }
          break
        default:
          console.warn('Failed to use predefined values for Type')
      }

      await this.load()
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-card-body {
  display: block;
}
</style>
