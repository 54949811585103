<template>
  <component v-if="renderer"
             :is="renderer"
             :item="item"
             :data="data"
             :can-edit="canEdit"
             :can-save-local="canSaveLocal"
             @change="onFieldChange"/>
</template>

<script>
import { mapActions } from 'vuex';
import OrderCellAddress from './OrderCellAddress';
import OrderCellDate from './OrderCellDate';
import OrderCellEmailList from './OrderCellEmailList';
import OrderCellHorizontalLine from './OrderCellHorizontalLine';
import OrderCellLabel from './OrderCellLabel';
import OrderCellListBox from './OrderCellListBox';
import OrderCellLogo from './OrderCellLogo';
import OrderCellOrderNumber from './OrderCellOrderNumber';
import OrderCellTag from './OrderCellTag';
import OrderCellTextArea from './OrderCellTextArea';
import OrderCellTextInput from './OrderCellTextInput';

export default {
  name: 'OrderCell',
  components: {
    OrderCellAddress,
    OrderCellDate,
    OrderCellEmailList,
    OrderCellHorizontalLine,
    OrderCellLabel,
    OrderCellListBox,
    OrderCellLogo,
    OrderCellOrderNumber,
    OrderCellTag,
    OrderCellTextArea,
    OrderCellTextInput
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    /* eslint-disable */
    // TODO - find out what the type(s) should be, and add a descriptor for it
    data: {
      default: null
    },
    /* eslint-enable */
    canEdit: {
      type: Boolean,
      default: false
    },
    canSaveLocal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    renderer() {
      switch (this.item.type) {
        case 'ADDRESS':
          return 'order-cell-address';
        case 'DATE':
          return 'order-cell-date';
        case 'EMAIL_LIST':
          return 'order-cell-email-list';
        case 'HORIZONTAL_LINE':
          return 'order-cell-horizontal-line';
        case 'LISTBOX':
          return 'order-cell-list-box';
        case 'LOGO':
          return 'order-cell-logo';
        case 'ORDER_NUMBER':
          return 'order-cell-order-number';
        case 'TAG':
          return 'order-cell-tag';
        case 'TEXTAREA':
          return 'order-cell-text-area';
        case 'TEXTFIELD':
          return 'order-cell-text-input';
        default:
          return 'order-cell-label';
      }
    }
  },
  methods: {
    ...mapActions({
      updateFieldValue: 'order/updateFieldValue'
    }),
    onFieldChange (event) {
      this.updateFieldValue({
        ...event,
        canSaveLocal: this.canSaveLocal
      })
    }
  }
};
</script>

<style scoped>

</style>
