<script setup lang="ts">
import DrawMode = Communicator.DrawMode
import SliderInput from '@/components/common/SliderInput.vue'
import ThreeDModal from '@/components/admin/content/editor/part/three-d/ThreeDModal.vue'
import {
  acceptedFileTypes,
  CuttingPlaneType,
  getRawValue,
  HoopsDrawModeOptions,
  HoopsModelView,
  HoopsOrientationAndProjectionOptionsForMobile,
  HoopsPerspective,
  HoopsSlice,
  HoopsSliceOptionsForMobile,
} from '@/plugins/hoops'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { CuttingPlaneState } from '@/store/modules/hoopsWebViewer'
import { useStore } from 'vuex'

const emit = defineEmits(['close', 'delete', 'upload'])
const fileInput = ref<HTMLInputElement | null>(null)
const props = defineProps({
  disabled: Boolean, // affects all buttons except upload
  hasCloseOption: Boolean, // add and emit close option, useful for modals
  hasEditOptions: Boolean, // controls visibility of properties, upload and delete option
  hasFileProcessing: Boolean, // disables upload option
  title: String
})

const store = useStore()
const captureSnapshot = () => {
  store.dispatch('hoopsWebViewer/captureSnapshot')
  isBurgerMenuOpen.value = false
}
const changeModelView = async (modelView: HoopsModelView) => {
  await store.dispatch('hoopsWebViewer/changeModelView', modelView)
  isBurgerMenuOpen.value = false
}
const currentModelView = computed(() => store.getters['hoopsWebViewer/currentModelView'])
const dismissDeleteModal = () => showDeleteModal.value = false
const dismissPropertiesModal = () => showPropertiesModal.value = false
const showDeleteModal = ref(false)
const showPropertiesModal = ref(false)
const modelProperties = computed( () => store.getters['hoopsWebViewer/modelProperties'])
const modelViewOptions = computed( () => store.getters['hoopsWebViewer/modelViewOptions'])

// mobile variables
const isDrawModeDropdownOpen = ref(false)
const isSliceDropdownOpen = ref(false)
const isOrientationDropdownOpen = ref(false)
const isProjectionDropdownOpen = ref(false)
const isViewDropdownOpen = ref(false)
const isBurgerMenuOpen = ref(false) // relevant in mobile resolutions ( < 1024px )

const toggleDrawModeDropdown = () => {
  if (!props.disabled) {
    isDrawModeDropdownOpen.value = !isDrawModeDropdownOpen.value
  }
}
const toggleOrientationDropdown = () => {
  if (!props.disabled) {
    isOrientationDropdownOpen.value = !isOrientationDropdownOpen.value
  }
}
const toggleProjectionDropdown = () => {
  if (!props.disabled) {
    isProjectionDropdownOpen.value = !isProjectionDropdownOpen.value
  }
}
const toggleSliceDropdown = () => {
  if (!props.disabled) {
    isSliceDropdownOpen.value = !isSliceDropdownOpen.value
  }
}
const toggleViewDropdown = () => {
  if (!props.disabled) {
    isViewDropdownOpen.value = !isViewDropdownOpen.value
  }
}

// bottom toolbar
const currentDrawMode = computed(() => store.getters['hoopsWebViewer/currentDrawMode'])
const currentExplodeMagnitude = computed({
  get () {
    return store.getters['hoopsWebViewer/currentExplodeMagnitude']
  },
  set (newMagnitude: number) {
    store.dispatch('hoopsWebViewer/changeExplodeMagnitude', newMagnitude)
  }
})
const currentProjection = computed(() => store.getters['hoopsWebViewer/currentProjection'])
const currentOrientation = computed(() => store.getters['hoopsWebViewer/currentOrientation'])

// Orientation or Projection
const changePerspective = async (projection: HoopsPerspective) => {
  isBurgerMenuOpen.value = false
  closeDropdowns()
  await store.dispatch('hoopsWebViewer/changePerspective', projection)
}
const changeDrawMode = async (mode: DrawMode) => {
  isBurgerMenuOpen.value = false
  closeDropdowns()
  await store.dispatch('hoopsWebViewer/changeDrawMode', mode)
}
const changeSliceMode = (option: HoopsSlice) => {
  switch (option.type) {
    case CuttingPlaneType.PLANE_SECTION:
      store.dispatch('hoopsWebViewer/toggleCuttingPlaneSection')
      break
    case CuttingPlaneType.PLANE_VISIBILITY:
      store.dispatch('hoopsWebViewer/toggleCuttingPlaneVisibility')
      break
    case CuttingPlaneType.PLANE_X:
      store.dispatch('hoopsWebViewer/toggleCuttingPlaneX')
      break
    case CuttingPlaneType.PLANE_Y:
      store.dispatch('hoopsWebViewer/toggleCuttingPlaneY')
      break
    case CuttingPlaneType.PLANE_Z:
      store.dispatch('hoopsWebViewer/toggleCuttingPlaneZ')
      break
    case CuttingPlaneType.RESET:
      store.dispatch('hoopsWebViewer/removeAllCuttingPlanes')
      break
    default:
    // CuttingPlaneType.PLANE_FACE is unused for part
  }
}
const resetCameraView = async () => {
  await store.dispatch('hoopsWebViewer/resetCameraView')
  isBurgerMenuOpen.value = false
  closeDropdowns()
}
const zoomIn = () => store.dispatch('hoopsWebViewer/zoomIn')
const zoomOut = () => store.dispatch('hoopsWebViewer/zoomOut')

/**
 * Helper to reset/close dropdowns when burger menu closes
 */
const closeDropdowns = () => {
  if (!isBurgerMenuOpen.value) {
    isDrawModeDropdownOpen.value = false
    isOrientationDropdownOpen.value = false
    isProjectionDropdownOpen.value = false
    isSliceDropdownOpen.value = false
    isViewDropdownOpen.value = false
  }
}
/**
 * Slice options can have two-states and three-states. :|
 * @param option
 */
const getSliceStyleClass = (option: HoopsSlice) => {
  function getClassForThreeState (state: CuttingPlaneState) {
    switch (state) {
      case CuttingPlaneState.OFF:
        return null
      case CuttingPlaneState.POSITIVE:
        return 'has-background-primary'
      case CuttingPlaneState.NEGATIVE:
        return 'has-background-primary-dark has-text-white'
    }
  }

  switch (option.type) {
    case CuttingPlaneType.PLANE_SECTION:
      return store.getters['hoopsWebViewer/cuttingPlaneSectionEnabled'] ?
        'has-background-primary' : null
    case CuttingPlaneType.PLANE_VISIBILITY:
      return store.getters['hoopsWebViewer/cuttingPlaneVisibilityEnabled'] ?
        'has-background-primary' : null
    case CuttingPlaneType.PLANE_X:
      return getClassForThreeState(store.getters['hoopsWebViewer/cuttingPlaneXState'])
    case CuttingPlaneType.PLANE_Y:
      return getClassForThreeState(store.getters['hoopsWebViewer/cuttingPlaneYState'])
    case CuttingPlaneType.PLANE_Z:
      return getClassForThreeState(store.getters['hoopsWebViewer/cuttingPlaneZState'])
    case CuttingPlaneType.RESET:
      return // No color classes for RESET
    default:
    // PLANE_FACE is unused for part
  }
}

const isDisabledSliceOption = (option: HoopsSlice) => {
  switch (option.type) {
    case CuttingPlaneType.PLANE_SECTION:
      return store.getters['hoopsWebViewer/cuttingPlanesCount'] < 2 ? true : null
    case CuttingPlaneType.PLANE_VISIBILITY:
    case CuttingPlaneType.RESET:
      return store.getters['hoopsWebViewer/cuttingPlanesCount'] === 0 ? true : null
    default:
      return null // PLANE_FACE is unused for part
  }
}
// end of bottom toolbar

const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement

  if (target.files && target.files.length > 0) {
    emit('upload', Array.from(target.files))
  }
  target.value = ''
}
const toggleBurgerMenu = () => {
  isBurgerMenuOpen.value = !isBurgerMenuOpen.value
  if (!isBurgerMenuOpen.value) {
    closeDropdowns()
  }
}
const triggerFileInput = () => {
  if (fileInput.value) {
    fileInput.value.click();
  }
  isBurgerMenuOpen.value = false
  closeDropdowns()
}
const handleOpenForPropertiesModal = () => {
  if (isBurgerMenuOpen.value) {
    showPropertiesModal.value = true
    isBurgerMenuOpen.value = false
    closeDropdowns()
  }
}
const handleOpenForDeleteModal = () => {
  isBurgerMenuOpen.value = false
  closeDropdowns()
  showDeleteModal.value = true
}
const handleSubmitForDeleteModal = async () => {
  emit('delete')
  showDeleteModal.value = false
}

const navbarClickawayEventListener = (event: Event) => {
  const navbarBurger = document.getElementById('part-3d-navbar-burger-mobile');
  const navbarMenu = document.getElementById('part-3d-navbar-menu-mobile');

  if (!navbarBurger || !navbarMenu) {
    console.warn('Missing Part 3D navbar item(s) for clickaway')
    return
  }

  const target = event.target as HTMLElement;

  if (!navbarMenu.contains(target) && !navbarBurger.contains(target)) {
    isBurgerMenuOpen.value = false;
    closeDropdowns()
    navbarBurger.classList.remove('is-active');
    navbarMenu.classList.remove('is-active');
  }
}

const resizeEventListener = () => {
  isBurgerMenuOpen.value = false
  closeDropdowns()
}

onMounted(() => {
  document.addEventListener('click', navbarClickawayEventListener);
  window.addEventListener('resize', resizeEventListener);
})
onBeforeUnmount(() => {
  document.removeEventListener('click', navbarClickawayEventListener);
  window.removeEventListener('resize', resizeEventListener);
})
</script>

<template>
  <!-- Hidden until this is completed -->
  <div class="is-hidden-desktop">
    <h4 class="is-hidden-touch title-four mb-0">{{ $t('threeD.viewerTitle') }}</h4>
    <nav class="custom-navbar-style navbar is-transparent mb-1">
      <div class="navbar-brand">
        <h4 class="navbar-item mb-0 p-0 title-four title-overflow">
          {{ title ?? $t('threeD.viewerTitle') }}
        </h4>
        <a aria-expanded="false"
           aria-label="menu"
           class="navbar-burger"
           id="part-3d-navbar-burger-mobile"
           role="button"
           :class="{ 'is-active': isBurgerMenuOpen }"
           @click="toggleBurgerMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu mb-0"
           id="part-3d-navbar-menu-mobile"
           :class="{ 'is-active': isBurgerMenuOpen }">
        <div class="navbar-start">
          <div class="has-dropdown navbar-item"
               :class="{ 'is-active': isViewDropdownOpen, 'is-hoverable': modelViewOptions.length > 0 }">
            <a class="burger-item-styling custom-dropdown is-arrowless navbar-link"
               :class="{ 'custom-disabled': disabled || modelViewOptions.length === 0 }"
               @click="toggleViewDropdown">
              <span>{{ $t('view') }}</span>
              <span class="icon">
                <i v-show="!isViewDropdownOpen" class="fa fa-angle-down"></i>
                <i v-show="isViewDropdownOpen" class="fa fa-angle-up"></i>
              </span>
            </a>
            <div v-show="modelViewOptions.length > 0 && isViewDropdownOpen"
                 class="navbar-dropdown">
              <template v-for="option in modelViewOptions">
                <a class="navbar-item"
                   :class="{ 'is-selected': currentModelView?.nodeId === option.nodeId }"
                   :style="{ 'display': isViewDropdownOpen ? 'block' : 'none' }"
                   @click="changeModelView(option)">
                  {{ option.label }}
                </a>
              </template>
            </div>
          </div>
          <a class="burger-item-styling navbar-item"
             :class="{ 'custom-disabled': disabled }"
             @click="captureSnapshot">
            {{ $t('snapshot') }}
          </a>
          <a v-if="hasEditOptions"
             class="burger-item-styling navbar-item"
             :class="{ 'custom-disabled': disabled, 'is-hoverable': !disabled }"
             @click="handleOpenForPropertiesModal">
            {{ $tc('property', 2) }}
          </a>
          <a v-if="hasEditOptions"
             class="navbar-item"
             :class="{ 'custom-disabled': hasFileProcessing }"
             @click="triggerFileInput">
            {{ $t('upload') }}
          </a>
          <input class="is-hidden"
                 multiple
                 ref="fileInput"
                 type="file"
                 :accept="acceptedFileTypes"
                 @change="handleFileChange" />
          <a v-if="hasEditOptions"
             class="navbar-item"
             :class="{ 'custom-disabled': disabled }"
             @click="handleOpenForDeleteModal">
            {{ $t('delete') }}
          </a>
          <a v-if="hasCloseOption"
             class="navbar-item"
             :class="{ 'custom-disabled': disabled }"
             @click="emit('close')">
            {{ $t('close') }}
          </a>
          <div class="dropdown-divider mx-4" />
          <a class="is-arrowless navbar-item"
             :class="{ 'custom-disabled': disabled }"
             @click="resetCameraView">
            {{ $t('threeD.resetCamera') }}
          </a>
          <div class=" has-dropdown navbar-item"
               :class="{ 'is-hoverable': !disabled }">
            <a class="burger-item-styling custom-dropdown is-arrowless navbar-link"
               :class="{ 'custom-disabled': disabled }"
               @click="toggleDrawModeDropdown">
              <span>{{ $t('threeD.drawMode.label') }}</span>
              <span class="icon">
                <i v-show="!isDrawModeDropdownOpen" class="fa fa-angle-down"></i>
                <i v-show="isDrawModeDropdownOpen" class="fa fa-angle-up"></i>
              </span>
            </a>
            <div v-show="isDrawModeDropdownOpen"
                 class="navbar-dropdown">
              <template v-for="option in HoopsDrawModeOptions"
                        :key="`${option.iconType}-${currentDrawMode}`">
                <a class="navbar-item"
                   :class="{ 'has-background-primary': currentDrawMode === option.value, 'is-selected': currentDrawMode === option.value }"
                   :style="{ 'display': isDrawModeDropdownOpen ? 'block' : 'none' }"
                   @click="changeDrawMode(option.value)">
                  {{ option.label }}
                </a>
              </template>
            </div>
          </div>
          <a class="burger-item-styling navbar-item"
             :class="{ 'custom-disabled': disabled }">
            {{ $t('threeD.explode') }}
            <slider-input v-model="currentExplodeMagnitude"
                          class="mt-2"
                          :disabled="disabled" />
          </a>
          <div class="has-dropdown navbar-item"
               :class="{ 'is-hoverable': !disabled }">
            <a class="burger-item-styling custom-dropdown is-arrowless navbar-link"
               :class="{ 'custom-disabled': disabled }"
               @click="toggleSliceDropdown">
              {{ $t('threeD.slice.label') }}
              <span class="icon">
                <i v-show="!isSliceDropdownOpen" class="fa fa-angle-down"></i>
                <i v-show="isSliceDropdownOpen" class="fa fa-angle-up"></i>
              </span>
            </a>
            <div v-show="isSliceDropdownOpen"
                   class="navbar-dropdown">
              <template v-for="option in HoopsSliceOptionsForMobile">
                <a v-show="isSliceDropdownOpen"
                     class="navbar-item"
                     :class="getSliceStyleClass(option)"
                     :disabled="isDisabledSliceOption(option)"
                     :id="`${option.elementId}`"
                     @click="isDisabledSliceOption(option) ? null : changeSliceMode(option)">
                  {{ option.label }}
                </a>
              </template>
            </div>
          </div>
          <div class="has-dropdown navbar-item"
               :class="{ 'is-hoverable': !disabled }">
            <a class="burger-item-styling custom-dropdown is-arrowless navbar-link"
               :class="{ 'custom-disabled': disabled }"
               @click="toggleOrientationDropdown">
              {{ $t('orientation') }}
              <span class="icon">
                <i v-show="!isOrientationDropdownOpen" class="fa fa-angle-down"></i>
                <i v-show="isOrientationDropdownOpen" class="fa fa-angle-up"></i>
              </span>
            </a>
            <div v-show="isOrientationDropdownOpen"
                 class="navbar-dropdown">
              <template v-for="option in HoopsOrientationAndProjectionOptionsForMobile.get('orientation')">
                <a v-show="isOrientationDropdownOpen"
                     class="navbar-item"
                      :class="{ 'has-background-primary': getRawValue(option) === currentOrientation }"
                     @click="changePerspective(option)">
                  {{ option.label }}
                </a>
              </template>
            </div>
          </div>
          <div class="has-dropdown navbar-item"
               :class="{ 'is-hoverable': !disabled }">
            <a class="burger-item-styling custom-dropdown is-arrowless navbar-link"
               :class="{ 'custom-disabled': disabled }"
               @click="toggleProjectionDropdown">
              {{ $t('threeD.orientationAndProjection.projection') }}
              <span class="icon">
                <i v-show="!isProjectionDropdownOpen" class="fa fa-angle-down"></i>
                <i v-show="isProjectionDropdownOpen" class="fa fa-angle-up"></i>
              </span>
            </a>
            <div v-show="isProjectionDropdownOpen"
                 class="navbar-dropdown">
              <template v-for="option in HoopsOrientationAndProjectionOptionsForMobile.get('projection')">
                <a v-show="isProjectionDropdownOpen"
                   class="navbar-item"
                   :class="{ 'has-background-primary': getRawValue(option) === currentProjection }"
                   @click="changePerspective(option)">
                  {{ option.label }}
                </a>
              </template>
            </div>
            <a v-if="hasEditOptions"
               class="navbar-item"
               :class="{ 'custom-disabled': disabled }"
               @click="zoomIn">
              {{ $t('zoomIn') }}
            </a>
            <a v-if="hasEditOptions"
               class="navbar-item"
               :class="{ 'custom-disabled': disabled }"
               @click="zoomOut">
              {{ $t('zoomOut') }}
            </a>
          </div>
        </div>
      </div>
    </nav>
    <three-d-modal v-if="showPropertiesModal"
                   @close="dismissPropertiesModal">
      <template #body>
        <table class="table">
          <thead>
          <tr>
            <th>Property</th>
            <th>Value</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>Name</th>
            <td>{{ modelProperties.nodeName }}</td>
          </tr>
          <template v-for="(value, key) in modelProperties.properties">
            <tr>
              <th>{{ key }}</th>
              <td>{{ value }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </template>
    </three-d-modal>
    <three-d-modal v-if="showDeleteModal"
                   @close="dismissDeleteModal">
      <template #title>
        {{ $t('threeD.deleteTitle') }}
      </template>
      <template #actions>
        <button class="button"
                @click="dismissDeleteModal">
          {{ $t('cancel') }}
        </button>
        <button class="button is-danger"
                @click="handleSubmitForDeleteModal">
          {{ $t('delete') }}
        </button>
      </template>
    </three-d-modal>
  </div>
</template>

<style scoped lang="scss">
a:hover {
  color: var(--grey-dark) !important;
}

.burger-item-styling {
  color: var(--grey-dark) !important;
  padding: 0.5rem 0.75rem !important;
}

.custom-dropdown {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.custom-disabled {
  color: #dbdbdb !important;
  cursor: default;
  pointer-events: none;
}

.custom-navbar-style {
  border: unset;
  z-index: 1; // required to compensate for the app navbar dropdown
}

// current bulma version does not have .is-selected for .navbar-item yet
.is-selected {
  background-color: var(--primary);
  color: var(--primary--color-invert);
}
.is-selected:hover {
  background-color: var(--primary) !important;
  color: var(--primary--color-invert);
}

.navbar-item[disabled] {
  color: #dbdbdb !important;
  cursor: default;
  pointer-events: none;
}
.navbar-menu.is-active {
  border-radius: 4px;
  height: min(50vh, 350px);
  overflow-y: scroll;
  position: absolute;
  right: 0;
  width: 160px;
  z-index: 1000;
}

.title-overflow {
  align-content: center;
  display: block !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 63vw; // measured for tablet
  word-break: break-all;
}
@media(max-width: 768px) {
  .title-overflow {
    width: 75vw;
  }
}
</style>
