import i18n from '@/locales'
import router from '@/router'
import { ActionContext, GetterTree, MutationTree } from 'vuex'
import {
  DraftFieldError,
  DraftFieldType,
  isPublishingDraftPage,
  publishCurrentDraft,
  validateDraftPage
} from '@/controllers/admin/draftPage'
import {SchedulerJobState, SchedulerJobType} from '@/interfaces/admin/draftPage'
import { getPage } from '@/controllers/admin'
import {notify} from "@kyvg/vue3-notification";
// Interfaces and Types
interface State {
  draftFieldErrors: Array<DraftFieldError>
  publishing: PublishingState,
  showDraftFieldErrors: boolean,
}

interface NewPollData {
  pageId: number,
  intervalId: number
}

interface PublishingState {
  hasSeenPublish: boolean,
  intervalId: number|null,
  pageId: number|null,
  publisherMessages: []|null,
  state: string|null,
  type: string|null
}

export enum MutationTypes {
  CLEAR_POLLING = 'CLEAR_POLLING',
  RECEIVED_NEW_PUBLISH_STATE = 'RECEIVED_NEW_PUBLISH_STATE',
  START_PUBLISH_POLLING = 'START_PUBLISH_POLLING',
  VALIDATED_DRAFT = 'VALIDATED_DRAFT',
  VIEWED_DRAFT_ERRORS = 'VIEWED_DRAFT_ERRORS'
}

type Context = ActionContext<State, any>

const PARAMS = {
  autoGenerateDraftPage: false,
  loadDraftSheets: false,
  loadDraftBomItems: false,
  loadDraftPageTagItems: false
}

const COMPLETED_JOB_STATES = [
  SchedulerJobState.COMPLETED.toString(),
  SchedulerJobState.COMPLETED_WITH_ERROR.toString()
]

const state: State = {
  draftFieldErrors: [],
  publishing: {
    hasSeenPublish: false,
    intervalId: null,
    pageId: null,
    publisherMessages: null,
    state: null,
    type: null
  },
  showDraftFieldErrors: false
}

const getters: GetterTree<State, any> = {
  draftFieldErrors: (state) => state.draftFieldErrors,
  hasLastDraftPublishErrored: (state) => state?.publishing?.state == SchedulerJobState.COMPLETED_WITH_ERROR.toString(),
  isDraftPagePublishing: (state) =>
    state?.publishing?.state == null
      ? false
      : state?.publishing?.type == SchedulerJobType.PUBLISH_DOCUMENTS_FOR_DRAFT_PAGE && !COMPLETED_JOB_STATES.includes(state?.publishing?.state ?? ''),
  isDraftPageProcessingPlz: (state) =>
    state?.publishing?.state == null
      ? false
      : state?.publishing?.type == SchedulerJobType.PUBLISH_ARCHIVED_DRAFT_PARENT && !COMPLETED_JOB_STATES.includes(state?.publishing?.state ?? ''),
  showDraftErrorModal: (state) => state.showDraftFieldErrors
}

const actions = {
  async clearPolling ({ commit }: Context) {
    commit(MutationTypes.CLEAR_POLLING)
  },
  /*
  Entry point to poll publishing page; new states for any listeners is available via getters.
  This will terminate and clear when publishing finishes. Rejects any request if the page is
  the same as the existing polled page id.
   */
  async pollPublishingPage({ commit }: Context, pageId: number) {
    const intervalId = setInterval(
      async () => {
        await poll()
      },
      5000
    )
    async function poll() {
      const response = await getPage(pageId)
      /*
      Delicate balance between polling intervals and the async /publish. 'hasSeenPublish' is present
      in the case the new router-view loads faster than the new schedule id is created and readable.
       */
      if (
        state.publishing?.hasSeenPublish && (!response.draft || (response.draft
          && COMPLETED_JOB_STATES.includes(response.draft.draftSchedulerJobState)))
      ) {
        clearInterval(intervalId)
        if (response.draft && response.draft.draftSchedulerJobState) {
          if (response.draft.draftSchedulerJobState === SchedulerJobState.COMPLETED_WITH_ERROR.toString()) {
            notify({
              title: i18n.global.t('publishingErrors'),
              type: 'error',
              duration: 5000
            })
          }
        }
      }

      commit(MutationTypes.RECEIVED_NEW_PUBLISH_STATE, {
        hasSeenPublish: state.publishing?.hasSeenPublish
          ? state.publishing?.hasSeenPublish
          : (response.draft ? !!response.draft.draftSchedulerJobState : true),
        intervalId: intervalId,
        pageId: response.id,
        publisherMessages: response.draft ? response.draft.publisherMessages : null,
        state: response.draft ? response.draft.draftSchedulerJobState : SchedulerJobState.COMPLETED,
        type: response.draft ? response.draft.draftSchedulerJobType : null
      })
    }

    if (state.publishing?.pageId == pageId) return

    // Run poll immediately so that there is data for the initial state
    await poll()
    commit(MutationTypes.START_PUBLISH_POLLING, {
      intervalId: intervalId,
      pageId: pageId
    })
  },
  /*
   Stops all polling, regardless of completion. Expect to be handled by original invoker of 'pollPublishingPage'.
   */
  async publishDraftPage ({ commit, rootState, dispatch }: Context, draftPageId: number) {
    try {
      // Throw custom error toast and do not publish if publishing job is in progress
      const hasPublishRunning = await isPublishingDraftPage(rootState.draftPage.targetPageId, PARAMS)
      if (hasPublishRunning) {
        notify({
          title: i18n.global.t('publishingInProgress'),
          type: 'error',
          duration: 5000
        })
        return
      }

      // Initiate publishing of draft page
      await publishCurrentDraft(draftPageId)
      await dispatch('pollPublishingPage', rootState.draftPage.targetPageId)

      // Redirect to Page Viewer
      await router.push({name: 'PageIllustration'})
    } catch (error) {
      notify({
        title: i18n.global.t('draftBomError'),
        type: 'error',
        duration: 5000
      })
    }
  },
  async validateDraft ({ commit, rootState }: Context, draftPageId: number) {
    const data = await validateDraftPage(draftPageId)
    const locales = rootState.locales.items
    data.forEach((item) => {
      // @ts-ignore
      item.lang = locales.find(l => l.code4 === item.lang)?.name
      if (item.fieldType === DraftFieldType.SUPERSEDED_PART) {
        const itemIdx = item.instanceId - 1
        item.supersededPartNumber = rootState.draftPage.draftBomItems[itemIdx].supersededPartNumber
      }

    })

    commit(MutationTypes.VALIDATED_DRAFT, data)
  },
  async viewedDraftErrors ({ commit }: Context) {
    commit(MutationTypes.VIEWED_DRAFT_ERRORS)
  }
}

const mutations: MutationTree<State> = {
  [MutationTypes.CLEAR_POLLING] (state) {
    const intervalId = state?.publishing?.intervalId ? state?.publishing?.intervalId : undefined

    if (intervalId) {
      clearInterval(intervalId)
      Object.assign(
        state.publishing,
        {
          hasSeenPublish: false,
          intervalId: null,
          pageId: null,
          publisherMessages: null,
          state: null
        }
      )
    }
  },
  [MutationTypes.RECEIVED_NEW_PUBLISH_STATE] (state, payload: PublishingState) {
    Object.assign(state.publishing, payload)
  },
  [MutationTypes.START_PUBLISH_POLLING] (state, payload: NewPollData) {
    Object.assign(
      state.publishing,
      {
        hasSeenPublish: false,
        intervalId: payload.intervalId,
        pageId: payload.pageId,
        publisherMessages: null,
        state: SchedulerJobState.SUBMITTED
      }
    )
  },
  [MutationTypes.VALIDATED_DRAFT] (state, payload: Array<DraftFieldError>) {
    state.draftFieldErrors = payload
    state.showDraftFieldErrors = true
  },
  [MutationTypes.VIEWED_DRAFT_ERRORS] (state) {
    state.showDraftFieldErrors = false
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
