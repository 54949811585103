import http from '@/http'
import i18n from '@/locales'
import { notify } from "@kyvg/vue3-notification"

import {
  CONTENT_RECEIVED, TAGS_RECEIVED, TAGS_LOADING, CLEAR_TAGS
} from './contentTypes'
import { asyncFetcher } from '@/store'

const state = getDefaultState()

const getters = {
  // returns Array<{ name: string, id: number, values: string}>
  // where values is a stringified list of tag values
  tagNamesToValuesList(state) {
    const mappingObj = state.items.reduce((map, item) => {
      const dto = {
        ...(map[item.name] ?? {})
      }
      dto.id ??= item.id
      dto.name ??= item.name
      dto.values = (dto.values ?? []).concat(item.value)
      map[item.name] = dto
      return map
    }, {})
    return Object.values(mappingObj)
      .map(it => ({ ...it, values: it.values.join(", ")}))
  }
}

const actions = {
  async getTags ({ commit, rootState, dispatch, rootGetters }, { filter, id, type, pagePartId }) {
    commit(TAGS_LOADING)
    const params = {
      filter: filter || ''
    }
    try {
      const entityType = type
      const entityId = id
      const fetch = [
        http.get(`${entityType}-tags/by-entity/${entityId}`, { params }),
        dispatch('getTagsFromERP', {
          entityType,
          entityId,
          mediaIdentifier: rootState.content.toc && rootState.content.toc.book
            ? rootState.content.toc.book.identifier
            : '',
          quantity: 1
        })
      ]

      const pagePartIdForTags = rootGetters['widgets/isWidget']
        ? pagePartId
        : rootState.content.pagePartId

      /*  When a widget, the user won't be able to nav to the part
          on clicking it from the BOM. Consequently, content
          will still contain page-level data for the BOM.
      */
      if (!!pagePartIdForTags) {
        const pagePartEntity = 'page-part'
        const pagePartId = pagePartIdForTags
        fetch.push(http.get(`${pagePartEntity}-tags/by-entity/${pagePartId}`, { params }))
      }
      const [result1, result2, result3] = await Promise.all(fetch)
      let tags = [...result1.data.tags, ...result2]
      // Get our unfiltered total
      let unfilteredTotal = result1.data.unfilteredTotal

      if (pagePartIdForTags) {
        tags = [...tags, ...result3.data.tags]
        // If we're using both list than add that unfilteredTotal, too
        unfilteredTotal += result3.data.unfilteredTotal
      }

      const find = (acc, predicate) => {
        for (let i = 0; i < acc.length; i += 1) {
          if (predicate(acc[i])) {
            return acc[i]
          }
        }
        return null
      }

      // deduplicate Tags
      const dedup = tags.reduce((acc, cur) => {
        const tag = find(acc, (t) => {
          if (t.name === cur.name) {
            if (t.upperBound || t.lowerBound) {
              return t.upperBound === cur.upperBound && t.lowerBound === cur.lowerBound &&
                t.prefix === cur.prefix && t.suffix === cur.suffix
            }
            return t.value === cur.value
          }
          return false
        })

        if (!tag) {
          acc.push(cur)
        }
        return acc
      }, [])

      const compare = (a, b) => {
        if (a < b) return -1
        if (a > b) return 1
        return 0
      }

      const compareBy = (...args) => (a, b) => {
        for (let i = 0; i < args.length; i += 1) {
          const it = args[i]
          const A = it(a)
          const B = it(b)
          const r = compare(A, B)
          if (r !== 0) {
            return r
          }
        }
        return 0
      }

      const sortFields = []
      sortFields.push((it) => it.displayOrder || Infinity)
      sortFields.push((it) => it.name)

      const sorted = dedup.sort(compareBy(...sortFields))

      commit(TAGS_RECEIVED, { tags: sorted, unfilteredTotal: unfilteredTotal })
    } catch (err) {
      // na
    }
  },
  async getTagsFromERP ({ rootState, rootGetters }, { entityType, entityId, mediaIdentifier, quantity }) {
    let tags = []
    try {
      if (entityType === 'part' && !rootGetters['widgets/isWidget']) {
        if (rootState.user.erpEnabled && !rootState.content.partCode &&
          rootState.user.accessPrivileges.indexOf('PARTS_LIST_FETCH_PART_INFO_FROM_ERP_ENABLED') >= 0) {
          const params = {
            partId: entityId,
            mediaIdentifier: mediaIdentifier || '',
            qty: quantity || 1
          }

          let allowed = window.location.href.includes(`/part/${entityId}`)

          if (!allowed) {
            const pagepart = window.location.href.match(/\/pagepart:([0-9]+)/)

            if (pagepart !== null && pagepart[1]) {
              const resp = await http.get(`admin/page-parts/${pagepart[1]}`)
              allowed = (resp.data !== null && resp.data.partId !== null &&
                `${resp.data.partId}` === `${entityId}`)
            }
          }

          if (allowed) {
            tags = await asyncFetcher('erp/part-info-async', null,
              { params }, 'get', 'erp/part-info-async-poll',
              'partInfoResponse', 1000, 1000, 600000,
              (message) => {
                notify({
                  title: i18n.global.t('error'),
                  text: message,
                  type: 'error',
                  duration: 5000
                })
              })
          }
        }
      }
    } catch (err) {
      console.log('err: ', err)
    }
    return tags
  }
}

const mutations = {
  [TAGS_RECEIVED] (state, { tags, unfilteredTotal }) {
    state.items = tags
    state.total = tags.length
    state.unfilteredTotal = unfilteredTotal
    state.isLoaded = true
  },
  [CONTENT_RECEIVED] (state, { content }) {
    state.items = []
    state.total = 0
    state.unfilteredTotal = 0
    state.isLoaded = false
  },
  [TAGS_LOADING] (state) {
    // Left out state.unfilteredTotal to reduce flicker
    // on ContentTabs
    state.items = []
    state.total = 0
    state.isLoaded = false
  },
  [CLEAR_TAGS] (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

function getDefaultState() {
  return {
    total: 0,
    items: [],
    isLoaded: false,
    unfilteredTotal: 0
  }
}
