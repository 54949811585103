<template>
  <section v-if="suggestions.length" class="section is-paddingless">
    <section class="suggestions-results">
      <div  data-int="suggestions" 
            class="columns is-multiline is-mobile is-justify-content-flex-start">
        <div  v-for="item in suggestions" 
              :key="item.id" 
              style="min-width: fit-content;"
              class="column is-2-desktop is-3-tablet is-4-mobile">
          <suggestions-card data-int="suggestions-card"
                            :item="item"
                            :qty-behavior="qtyBehavior"
                            :is-widget="isWidget"
                            :has-access-to-carts="hasAccessToCarts"
                            :has-navigable-widget="hasNavigableWidget"
                            @addToCart="add"
                            @widgetAddToCart="emitWidgetAddToCartEvent"
                            @widgetNavToContent="handleWidgetNav"
                            @click="select(item)"/>
        </div>
      </div>
    </section>
    <pagination v-if="suggestions.length"
                :offset="offset"
                :limit="limit"
                :total="total"
                @changePage="changePage"
                @changePerPage="changePerPage"/>
  </section>
  <section v-else-if="!total" class="no-data">
    <h1 data-int="suggestions-no-data" class="subtitle has-text-centered">
      {{ $t('assetsNotFound', {type: $tc('suggestion', 2)}) }}
    </h1>
  </section>
</template>

<script>
import { computed } from 'vue';
import { mapState, mapActions, useStore } from 'vuex';
import SuggestionsCard from './SuggestionsCard';
import Pagination from '@/components/common/Pagination';
import { useWidgetAddToCart } from '@/components/widgets/composables/useWidgetAddToCart';
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage';
import { WidgetTypes } from '@/interfaces/global';

export default {
  name: 'Suggestions',
  components: {
    SuggestionsCard,
    Pagination
  },
  setup() {
    const store = useStore()
    const { emitWidgetAddToCartEvent } = useWidgetAddToCart()
    const { navigateToSuggestedPart } = useInfoLandingPage()

    const isWidget = computed(() => store.getters['widgets/isWidget'])
    const isWidgetCartEnabled = computed(() => {
      return store.getters['widgets/isWidgetCartEnabled']
    })
    const hasAccessToCarts = computed(() => {
      return isWidget.value
        ? isWidgetCartEnabled.value
        : store.getters['user/isCartEnabled']
    })
    const widgetType = computed(() => store.getters['widgets/widgetType'])
    const hasNavigableWidget = computed(() => {
      return isWidget.value && (widgetType.value === WidgetTypes.library)
    })

    async function handleWidgetNav(dto) {
      if (hasNavigableWidget.value) {
        await navigateToSuggestedPart(dto)
      }
    }

    return { isWidget, hasAccessToCarts, 
      hasNavigableWidget, emitWidgetAddToCartEvent,
      handleWidgetNav }
  },
  computed: {
    ...mapState({
      contentLoaded: ({ content }) => content.isLoaded,
      suggestions: ({ content }) => content.suggestions.items,
      total: ({ content }) => content.suggestions.total,
      offset: ({ content }) => content.suggestions.offset,
      limit: ({ content }) => content.suggestions.limit,
      addToCartQtyBehavior: ({ user }) => {
        return user.preferences.addToCartQtyBehavior
      }
    })
  },
  watch: {
    async contentLoaded() {
      await this.getSuggestions();
    },
    offset() {
      this.scrollToTop();
    }
  },
  methods: {
    ...mapActions({
      getSuggestions: 'content/getSuggestions',
      add: 'cart/addToCart',
      navigateToContent: 'content/navigateToContent',
      changePage: 'content/setSuggestionsPage',
      changePerPage: 'content/setSuggestionsResultsPerPage'
    }),
    scrollToTop() {
      const results = document.getElementsByClassName('suggestions-results');
      if (results.length) {
        results[0].scrollTo(0, 0);
      }
    },
    async select(content) {
      await this.navigateToContent({ content });
    }
  },
  async created() {
    await this.getSuggestions();
  }
};
</script>


<style scoped>
.suggestions-results {
  flex: 1;
  overflow: auto;
  padding: 1rem;
  border-bottom: lightgray 1px solid;
}
.no-data {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
</style>
