import { onMounted, onBeforeUnmount } from 'vue'

export function usePopoverKeyUp(emits: Function, closeEvent: string) {
  function keyPress({ key }: { key: string }) {
    if (key === 'Escape' || key === 'Esc') {
      emits(closeEvent)
    }
  }

  function closePopover() {
    emits(closeEvent)
  }

  onMounted(() => {
    if (!window) return
    document.addEventListener('keyup', keyPress)
  })
  
  onBeforeUnmount(() => {
    if (!window) return
    document.removeEventListener('keyup', keyPress)
  })

  return { closePopover }
}