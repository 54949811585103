import http from '@/http'
import { Part3dFile } from '@/interfaces/global'

const API_BASE_URL = '3d-thumbnails/parts'

/**
 * Deletes association of the 3D file to this part only. If other parts
 * use the same 3D file, the file remains on the file system.
 *
 * @param partId
 */
export const deleteCurrentByPartId = async (partId: number): Promise<void> => {
  return http.delete(`${API_BASE_URL}/delete/${partId}`)
}

/**
 * Provides a URL path to content or an empty string if no file is present
 *
 * @param partId
 */
export const getCurrentScsFile = async (partId: number): Promise<Part3dFile> => {
  return http.get(`${API_BASE_URL}/current-scs/${partId}`)
    .then(res => {
      const data = res.data
      const part3dFile: Part3dFile = {
        jobId: parseInt(data['jobId']) ?? null,
        jobStatus: data['jobStatus'] ?? null,
        originalTopLevelFilename: data['originalTopLevelFilename'] ?? null,
        scsFileUrl: data['scsFileUrl'] ?? '',
      }

      return part3dFile
    })
}

/**
 * Used to determine if other parts use the provided filename.
 * Warning! Conflicts are relative. This endpoint counts all
 * parts using this filename, including the one you may be
 * working on.
 *
 * @param filename
 */
export const getFilenameUsedCount = async (filename: string): Promise<number> => {
  return http.get(`${API_BASE_URL}/filename-used-count/${filename}`)
    .then(res => res.data)
}

/**
 * Process a new 3D file for this part. And this may affect other parts
 * using the same filename when 'useForAllFilenameOccurrences' set to true.
 *
 * @param payload
 */
export const uploadFile = async (payload: {
  files: File[],
  partId: number,
  topAssemblyFileName: string,
  useForAllFilenameOccurrences: boolean|null
}): Promise<number> => {
  const formData = new FormData()
  payload.files.forEach((f: File) => formData.append('file', f))
  formData.append('partId', payload.partId.toString())
  formData.append('topAssemblyFileName', payload.topAssemblyFileName)
  formData.append('useForAllFilenameOccurrences', payload.useForAllFilenameOccurrences?.toString() ?? 'false')

  return await http.post(
    `${API_BASE_URL}/upload-files/${payload.partId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  ).then(res => parseInt(res.data))
}
