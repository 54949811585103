<template>
  <button :class="['button', stylingClass]"
          @click="emit('click')">
    <bulma-icon :iconClass="iconClass"/>
    <span v-if="text">
      {{ text }}
    </span>
  </button>
</template>

<script setup>
import BulmaIcon from './BulmaIcon.vue'

defineProps({
  stylingClass: { type: String, default: '' },
  iconClass: { type: String, default: '' },
  text: { type: String, default: '' }
})
const emit = defineEmits(['click'])
</script>
