import mutations from '../mutations'
import * as types from '../mutations/mutations-types'
import actions from '../actions'
import { getOrderFields } from '@/controllers/admin'
import i18n from '@/locales'

export default {
  namespaced: true,
  state: {
    data: [],
    searchTerm: '',
    sort: {
      field: 'translatedName',
      order: 'asc'
    },
    loading: false,
    error: false
  },
  mutations: {
    ...mutations,
    'RESET_FILTERS' (state) {
      Object.assign(state, {
        searchTerm: '',
        sort: {
          field: 'translatedName',
          order: 'asc'
        }
      })
    },
    'SET_SEARCH_TERM' (state, payload) {
      state.searchTerm = payload
    },
    'SET_SORT' (state, payload) {
      state.sort = payload
    },
    'REMOVE_ORDER_FIELD' (state, payload) {
      state.data = state.data.filter(el => el.id !== payload)
    }
  },
  actions: {
    ...actions,
    setSearchTerm (context, term) {
      context.commit('SET_SEARCH_TERM', term)
    },
    resetFilters (context) {
      context.commit('RESET_FILTERS')
    },
    setSort (context, opt) {
      context.commit('SET_SORT', opt)
    },
    removeOrderField (context, id) {
      context.commit('REMOVE_ORDER_FIELD', id)
    },
    async getProperty (context, url) {
      context.commit(types.DATA_REQUEST)

      try {
        const results = await getOrderFields()
        if (results) {
          results.forEach(field => {
            switch (field.type) {
              case 'HORIZONTAL_LINE':
                field.translatedName = i18n.global.t('columnItemType.horizontalLine').toString()
                break
              case 'LOGO':
                field.translatedName = i18n.global.tc('logo', 1).toString()
                break
              default:
                field.translatedName = field.translatedName ?? field.name
            }
          })
          context.commit(types.DATA_SUCCESS, results)
        }
      } catch (e) {
        console.log('e', e)
        context.commit(types.DATA_ERROR)
      } finally {
        context.commit('DATA_LOADING', false)
      }
    }
  }
}
