<template>
  <slot :title="title">
    <library-content-header v-if="hasInfoLandingPage"
                            :title="title"
                            :style="contentHeaderStyle"
                            :content="content">
    <template #navigation>
      <h3 class="title-five ml-1">
        {{ title }}
      </h3>
      <bulma-button-icon  v-if="!hasInfoPageOpen"
                          iconClass="fas fa-info-circle links-color"
                          class="pointer-on-hover ml-2"
                          @click="openInfoPage"/>
      <bulma-button-icon  v-if="hasInfoPageOpen"
                          iconClass="fas fa-arrow-left black-color"
                          class="pointer-on-hover mr-1"
                          :text="$t('back')"
                          @click="backToContentFromInfoPage"/>
    </template>
    <template #contentActions>
    <!-- Override default content with nothing -->
      <div  style="display: none"></div>
    </template>
  </library-content-header>
  </slot>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import i18n from '@/locales'
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage'
import LibraryContentHeader from '@/components/library/content/header/LibraryContentHeader'
import BulmaButtonIcon from '@/components/common/BulmaButtonIcon.vue'

const store = useStore()
const { hasInfoPageOpen,
  hasInfoLandingPage,
  openInfoPage,
  backToContentFromInfoPage } = useInfoLandingPage()

const content = computed(() => store.state.content)

const type = computed(() => (content.value.type === 'media')
  ? content.value.contentType
  : content.value.type)

const title = computed(() => content.value?.isLoaded
  ? content.value.partNumber || content.value.name
  : i18n.global.tc(type.value, 1))

const contentHeaderStyle = computed(() => {
  const justify = hasInfoPageOpen.value ? 'space-between' : 'flex-start'
  return `justify-content: ${justify}; ` +
    'padding: 0.5rem; ' +
    'max-height: 10%;'
})
</script>