<template>
  <div class="openZoomContainer"
       :class="bomLayout && !isWidget">
    <div v-if="!failedToLoad"
         class="toolbarWrapper">
      <div id="openZoomToolbar"
           class="is-marginless">
        <div class="toolbar-left">
          <button id="zoom-in"
                  class="button fa fa-search-plus"/>
          <button id="zoom-out"
                  class="button fa fa-search-minus"/>
          <button id="zoom-reset"
                  class="button fa fa-arrows-alt"/>
          <button id="full-page"
                  class="button fa fa-expand-arrows-alt"/>
          <button id="callout-show-hide"
                  v-if="callouts && callouts.length"
                  :class="{'fa-minus-circle': showCallouts, 'fa-plus-circle': !showCallouts}"
                  class="button fa"
                  @click="showHideCallouts"/>
        </div>
      </div>
      <div class="layoutToolbar">
        <div v-if="sheets.length > 1"
             class="field has-addons sheets"
             :class="{'use-white-text': useWhiteText()}">
          <p class="control">
            <button id="previous"
                    class="button sheet-button fa fa-arrow-left is-primary"/>
          </p>
          <p class="control">
            <a class="button sheet-button is-primary">{{ currentPageOutOf }}</a>
          </p>
          <p class="control">
            <button id="next"
                    class="button sheet-button fa fa-arrow-right is-primary"/>
          </p>
        </div>
        <button class="button is-hidden-touch bom-layout-button"
                @click="updateBomLayout()">
          <span class="icon">
            <i class="fa fa-columns"
               :class="{'fa-rotate-270': bomLayout === 'horizontal'}"/>
          </span>
        </button>
      </div>
    </div>
    <div v-if="!failedToLoad"
         class="openZoomWrapper">
      <div id="openZoomViewer"
           class="openZoomViewer"/>
      <div class="top-left bom-info-popover">
        <bom-info v-if="showPreview"
                  :callout="selectedCallout"
                  :selected="selected && selected.length ? selected[0] : null"
                  @close="close"/>
      </div>
    </div>
    <div v-else
         class="error-loading">
      <h1 data-int="open-zoom-error"
          class="subtitle has-text-centered has-text-light">
        {{ $t('failedToLoad', { content: $tc('illustration', 0) }) }}
      </h1>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import BomInfo from '../bom/BomInfo'
import {getColorValue} from "@/store/modules/theme/getColorValue"
import contrastColors from "@/helpers/contrastColors"
export default {
  name: 'OpenZoomViewer',
  components: {
    BomInfo
  },
  data () {
    return {
      viewer: undefined,
      overlay: undefined,
      currentPage: 0,
      callouts: [],
      calloutsMap: {},
      calloutsAll: [],
      showPopOver: false,
      showCallouts: true,
      failedToLoad: false,
      currentItemId: 0
    }
  },
  watch: {
    sheets () {
      this.dispose()
      this.initialize()
    },
    selected () {
      if (window.d3) {
        d3.selectAll('g').classed('selected', false)
      }

      if (this.selected && this.selected.length) {
        this.showPopOver = true
        this.selectCallout(this.selected[0].itemText)
      } else {
        this.showPopOver = false
      }
    },
    bomItemsMap () {
      this.addCallouts()

      if (window.d3) {
        d3.selectAll('svg').style('display', this.showCallouts ? 'unset' : 'none')
      }
    }
  },
  computed: {
    ...mapGetters({
      isWidget: 'widgets/isWidget',
      widgetType: 'widgets/widgetType',
      getTheme: 'theme/getTheme'
    }),
    ...mapState({
      sheets: (state) => state.content.sheets,
      bomItemsMap: (state) => state.content.bom.itemsMap,
      selected: (state) => state.content.bom.selected,
      bomLayout: (state) => state.content.bom.layout,
      isWidget: ({ auth }) => auth.isWidget
    }),
    isPageWidget() {
      return (this.widgetType === 'page')
        && this.isWidget
    },
    selectedCallout () {
      if (this.selected) {
        return this.calloutsMap[this.selected[0].itemText]
          ? this.calloutsMap[this.selected[0].itemText]
          : undefined
      }
      return undefined
    },
    currentPageOutOf () {
      return `${this.currentPage + 1} ${this.$i18n.t('of')} ${this.sheets.length}`
    },
    enableHotpointZoom() {
      return this.$store.getters["user/enableHotpointZoom"]
    },
    showPreview() {
      return this.isWidget ? false : this.showPopOver
    }
  },
  /* eslint-disable */
  // TODO - re-enable eslint when we send the OpenSeadragon to the abyss.
  methods: {
    ...mapActions({
      updateBomLayout: 'content/updateBomLayout',
      selectBomItemsFromCallout: 'content/selectBomItemsFromCallout',
      setCurrenViewerPage: 'content/setCurrenViewerPage'
    }),
    async initialize() {
      const tileSources = this.sheets.map((sheet) => sheet.openZoomUrl).filter((url) => url);
      this.failedToLoad = !tileSources || !tileSources.length;

      if (window.OpenSeadragon) {
        this.viewer = OpenSeadragon({
          id: 'openZoomViewer',
          tileSources,
          immediateRender: true,
          showNavigator: true,
          prefixUrl: '/ui/static/openseadragon/',
          navigatorPosition: 'BOTTOM_LEFT',
          toolbar: 'openZoomToolbar',
          zoomInButton: 'zoom-in',
          zoomOutButton: 'zoom-out',
          homeButton: 'zoom-reset',
          fullPageButton: 'full-page',
          nextButton: 'next',
          previousButton: 'previous',
          maxZoomLevel: 4.5,
          zoomPerSecond: 1.5,
          zoomPerScroll: 1.2,
          zoomPerClick: 1.2,
          sequenceMode: true,
          visibilityRatio: 0.7,
          animationTime: 1.1,
          homeFillsViewer: false,
          constrainDuringPan: false,
          gestureSettingsMouse: {
            clickToZoom: false,
            dblClickToZoom: true
          }
        });
        this.overlay = this.viewer.svgOverlay();
        this.viewer.addHandler('page', (data) => {
          this.currentPage = data.page;
        }, this);
        this.viewer.addHandler('open', () => {
          this.updateCallouts();
        }, this);
        this.viewer.addHandler('tile-load-failed', () => {
          this.failedToLoad = true;
        }, this);

        if (window.d3) {
          d3.selection.prototype.moveToFront = function () {
            return this.each(function () {
              this.parentNode.appendChild(this);
            });
          };
          d3.selection.prototype.moveToBack = function () {
            return this.each(function () {
              let firstChild = this.parentNode.firstChild;
              if (firstChild) {
                this.parentNode.insertBefore(this, firstChild);
              }
            });
          };
        }
      }
    },
    isCalloutOnPage(page, text) {
      return this.calloutsAll[page].filter( co => co.item === text).length > 0;
    },
    dispose() {
      if (this.viewer) {
        this.viewer.destroy();
        this.viewer = null;
      }
    },
    updateCallouts() {
      this.callouts = this.calloutsAll[this.currentPage];
      this.addCallouts();
      d3.selectAll('svg').style('display', this.showCallouts ? 'unset' : 'none');
    },
    addCallouts() {
      this.calloutsMap = {};

      if (window.d3) {
        d3.select(this.overlay.node()).selectAll("*").remove();

        if (this.callouts) {
          this.callouts.forEach((callout) => {
            const bomItem = this.bomItemsMap[callout.item];

            let isHighlighted;
            if (bomItem && bomItem.length) {
              let index = -1;
              bomItem.some((item, i) => {
                if (item.highlight) {
                  index = i;
                  return true;
                }
                return false;
              });
              isHighlighted = index >= 0;
            }

            /*
             * Callouts may have links inside and links may exist without a valid hotpoint
             */
            this.calloutsMap[callout.item] = callout;

            if (callout.x != null && callout.y != null && callout.r != null) {
              const rect = this.viewer.viewport.imageToViewportRectangle(
                callout.x,
                callout.y,
                callout.r * 2);

              const _this = this;
              const g = d3.select(this.overlay.node()).append('g')
                .classed('highlight', isHighlighted)
                .classed('group-callout', true)
                .attr('id', callout.item)
                .on('mouseover', function () {
                  d3.select(this).moveToFront();
                })
                .on('touchstart', function () {
                  d3.select(this).moveToFront();
                  _this.selectBomItemsFromCallout(d3.select(this).attr('id'));
                });

              const hotpointScaleFactor = callout.scale ? callout.scale : 1
              let scaleFactor = hotpointScaleFactor
              if (callout.zoom) {
                scaleFactor = hotpointScaleFactor *
                  Math.min(
                    this.viewer.viewport._contentSize.x / this.viewer.viewport.containerSize.x,
                    this.viewer.viewport._contentSize.y / this.viewer.viewport.containerSize.y)
              }
              if (callout.link && !this.isPageWidget) {
                d3.select(g.node()).append('rect')
                  .classed('hotpoint', true)
                  .classed('highlight', isHighlighted)
                  .on('click', function () {
                    _this.selectBomItemsFromCallout(callout.item);
                  })
                  .attr('opacity', callout.a)
                  .attr('x', rect.x - Math.max(rect.width * scaleFactor, (rect.width * scaleFactor / 3) * callout.item.length) / 2)
                  .attr('y', rect.y - (rect.width * scaleFactor / 2))
                  .attr('width', Math.max(rect.width * scaleFactor, (rect.width * scaleFactor / 3) * callout.item.length))
                  .attr('height', rect.width * scaleFactor);
              } else {
                d3.select(g.node()).append('ellipse')
                  .classed('hotpoint', true)
                  .classed('highlight', isHighlighted)
                  .on('click', function () {
                    _this.selectBomItemsFromCallout(callout.item);
                  })
                  .attr('opacity', callout.a)
                  .attr('cx', rect.x)
                  .attr('cy', rect.y)
                  .attr('rx',
                    Math.max(
                      rect.width / 2,
                      (rect.width / 6) * callout.item.length) * scaleFactor)
                  .attr('ry', rect.width / 2 * scaleFactor);
              }

              d3.select(
                g.node()).append('text')
                .classed('hotpointText', true)
                .on('click', function () {
                  _this.selectBomItemsFromCallout(callout.item);
                })
                .attr('opacity', callout.a)
                .style('font-size', callout.r * scaleFactor + 'px')
                .attr('font-weight', 'bold')
                // The y translate uses the scale factor and the radius to center the hotpoint text. 12/9 is a constant used to push text from the top down.
                // Higher numbers push text down further while lower numbers pull text towards the top of the page.
                .attr('transform',
                  `scale(${rect.width / (callout.r * 2)})
                   translate(${callout.x}, ${(callout.y + callout.r * scaleFactor) - (callout.r * scaleFactor / (12 / 9))})`)
                .attr('text-anchor', 'middle')
                .text(callout.item);

            }
          });
        }
      }

      if (this.selected && this.selected.length) {
        this.showPopOver = true;
        this.selectCallout(this.selected[0].itemText);
      }
    },
    selectCallout(itemText) {
      if (window.d3) {
        let callouts = d3.selectAll(`[id='${itemText}']`);
        let minx = Number.MAX_SAFE_INTEGER;
        let miny = Number.MAX_SAFE_INTEGER;
        let maxx = 0;
        let maxy = 0;

        var found = false;
        callouts.each(function() {
          d3.select(this).classed('selected', true);
          const hotpoint = d3.select(this).select('.hotpoint');
          minx = Math.min(minx, Number(hotpoint.attr('x')) ||
            (Number(hotpoint.attr('cx')) - Number(hotpoint.attr('rx'))));
          miny = Math.min(miny, Number(hotpoint.attr('y')) ||
            (Number(hotpoint.attr('cy')) - Number(hotpoint.attr('ry'))));
          maxx = Math.max(maxx, (Number(hotpoint.attr('x')) + Number(hotpoint.attr('width'))) ||
            (Number(hotpoint.attr('cx')) + Number(hotpoint.attr('rx'))));
          maxy = Math.max(maxy, (Number(hotpoint.attr('y')) + Number(hotpoint.attr('width'))) ||
            (Number(hotpoint.attr('cy')) + Number(hotpoint.attr('ry'))));
          d3.select(this).moveToFront();
          found = true;
        });
        if (callouts.size() && window.OpenSeadragon && this.enableHotpointZoom) {
          this.zoomToCallouts(new OpenSeadragon.Rect(minx - .15, miny - .15, maxx - minx + .3, maxy - miny + .3, 0));
        }

        if (!found) {
          const nPage = this.findCalloutPage(itemText);
          if (nPage != null && nPage !== this.currentPage && this.currentItemId !== this.selected[0].id) {
            this.currentItemId = this.selected[0].id;
            this.viewer.goToPage(nPage);
          }
        }
      }
    },
    findCalloutPage(itemText) {
      if (this.calloutsAll.length === 0) return null;
      for (const sheet in this.calloutsAll) {
        const co = this.calloutsAll[sheet].filter( co => co.item === itemText);
        if (co.length > 0) {
              return Number(sheet);
        }
      }
      return null
    },
    zoomToCallouts(bounds) {
      this.currentItemId = this.selected[0].id;
      this.viewer.viewport.fitBoundsWithConstraints(bounds, false);
    },
    close() {
      this.showPopOver = false;
    },
    showHideCallouts() {
      this.showCallouts = !this.showCallouts;
      if (window.d3) {
        d3.selectAll('svg').style('display', this.showCallouts ? 'unset' : 'none');
      }
    },
    useWhiteText () {
      const primaryColor = getColorValue(this.getTheme.brand_primary)
      return contrastColors.colorText(primaryColor) === '#ffffff'
    },
  },
  async mounted () {
    if (!this.viewer) {
      this.initialize()
    }
    this.calloutsAll = this.$store.state.content.hotpoints ?? {}
    this.callouts = this.calloutsAll[0]
    this.updateCallouts()
  },
  beforeUnmount () {
    if (this.viewer) {
      this.viewer.setFullPage(false)
      this.viewer.setFullScreen(false)
      this.dispose()
    }
  }
  /* eslint-enable */
}
</script>

<style scoped>
.openZoomContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
}
svg {
  position: inherit;
}
.toolbarWrapper {
  padding-bottom: .25rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  border-bottom: lightgray 1px  solid;
  flex-shrink: 0;
}
.toolbar-left {
  flex: 1;
  display: flex;
}
.button {
  margin: .125rem;
}
.openZoomWrapper {
  position: relative;
  flex: 1;
  background-color: darkgray;
}
.openZoomViewer {
  height: 100%;
  width: 100%;
  position: relative;
}
.top-left {
  display: block;
  position: absolute;
  padding: .25rem;
  max-width: 60%;
  max-height: 80%;
  overflow: hidden;
  top: 0;
  background-color: transparent;
}
.button:focus, .button.is-focused {
  border-color: lightgray;
  box-shadow: none;
}
.sheet-button {
  margin: .125rem 0;
}
.sheet-button:disabled {
  background-color: var(--primary) !important;
}
.sheets {
  margin-bottom: 0;
}
.horizontal {
  border-right: lightgray 1px solid;
}
.vertical {
  border-bottom: lightgray 1px solid;
}
.layoutToolbar {
  display: flex;
}
.bom-layout-button {
  margin-left: .25rem;
}
@media only screen and (max-width: 1023px) {
  .top-left {
    display: none;
  }
}
.error-loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: darkgray;
}
</style>

<style>
.group-callout {
  pointer-events: none;
}
.hotpoint {
  pointer-events: visibleFill;
  fill: black;
  cursor: pointer;
}
.hotpointText {
  pointer-events: visibleStroke;
  fill: white;
  cursor: pointer;
}
.openZoomViewer.fullpage {
  height: calc(100% - 3rem) !important;
}
.openZoomViewer.fullpage > .top-left {
  display: block;
}

@media only screen and (max-width: 640px) {
  .toolbarWrapper {
    padding-bottom: 0 !important;
  }
  .field {
    margin-bottom: 0 !important;
  }
}
</style>
