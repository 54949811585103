<template>
  <b-field data-unit="search-field">
    <b-input
      data-unit="search-input"
      :expanded="expanded"
      icon="search"
      icon-right="times"
      icon-right-clickable
      @icon-right-click="searchTermCleared"
      :placeholder="$tc('filter', 1)"
      v-model="searchTerm"
      @input="searchTermChanged($event)"
    />
    <p class="control">
      <b-button
        class="ml-0"
        icon-left="sync"
        @click="refresh()"
      />
    </p>
  </b-field>
</template>

<script>

import debounce from '../../plugins/debounce'
/*
 * A simple search bar with two events:
 *  - Change: User-input for new search term or removing of search term (null)
 *  - Refresh: User action to refresh data
 */
export default {
  name: 'SearchBar',
  mixins: [debounce],
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchTerm: ''
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    },
    searchTermChanged (event) {
      this.$emit('change', event)
    },
    searchTermCleared () {
      this.searchTerm = ''
      this.$emit('change', null)
    }
  },
  debounceMethods: {
    searchTermChanged: 250
  }
}
</script>
