import { ref, readonly, computed, ComputedRef }  from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useWidgetNavigator } from './useWidgetNavigator'
import { WidgetRootBreadcrumbs } from '@/interfaces/global'
import { MediaWidgetEntityTypes, WidgetMediaTypes } from '@/interfaces/global/widgets'

const input = ref('')
const isExactMatch = ref(false)

export function useSearch() {
  const store = useStore()
  const router = useRouter()
  const { setRootBreadcrumb, 
    setCurrentWidgetView, 
    isWithinSearchContext,
    isWithinBrowseContext } = useWidgetNavigator()
  
  const searchTrackingId: ComputedRef<number|null> = computed(() => {
    return store.state.search.searchTrackingId
  })
  const browseSearchTrackingId: ComputedRef<number|null> = computed(() => {
    return store.getters["browse/getSearchTrackingId"]
  })
  const content = computed(() => {
    return store.state.content
  })
  const contentType = computed(() => {
    const isMedia = (content.value.type as MediaWidgetEntityTypes) === MediaWidgetEntityTypes.media
    return content.value[isMedia ? "contentType" : "type"]
  })
  const isValidContentForClickThrough = computed(() => {
    const validEntities = new Set([ 
      ...Object.keys(WidgetMediaTypes), 
      "page", 
      "part" ])

    return validEntities.has(contentType.value)
      && (typeof content.value.id === "number")
  })


  async function navigateToSearch(q = '', 
    { clearAllContent = false } = {}) {
      setRootBreadcrumb(WidgetRootBreadcrumbs.search)
    // Need to do logic found in both if & else block
    // of search module's navigate to search action
    // to maintain component reactivity
    await router.push({
      name: 'Search',
      query: {
        q,
        exact: isExactMatch.value,
        ...store.state.search.filters.selected
      }
    })
    setCurrentWidgetView('search', {  clearAllContent })
  }

  function setInput(val: string) {
    input.value = val
  }

  function setIsExactMatch(val: boolean) {
    isExactMatch.value = val
  }

  async function logSearchClickThrough() {
    const isValidSearch = (isWithinSearchContext.value === true)
      && (typeof searchTrackingId.value === "number")
      && isValidContentForClickThrough.value
    
    const isValidBrowseSearch = (isWithinBrowseContext.value === true)
      && (typeof browseSearchTrackingId.value === "number")
      && isValidContentForClickThrough.value

    const searchParams = {
      contentType: contentType.value,
      contentId: content.value.id
    }

    if (isValidSearch) {
      await store.dispatch("content/callSearchTracker", {
        searchTrackingId: searchTrackingId.value,
        ...searchParams
      })
      await store.dispatch("search/clearSearchTrackingId")
    } else if (isValidBrowseSearch) {
      store.dispatch("content/callSearchTracker", {
        searchTrackingId: browseSearchTrackingId.value,
        ...searchParams
      })
      await store.dispatch("browse/clearSearchTrackingId")
    }
  }

  return { 
    input: readonly(input), 
    isExactMatch: readonly(isExactMatch), 
    setInput,
    setIsExactMatch,
    navigateToSearch,
    logSearchClickThrough
  }
}