<template>
  <div class="field has-addons is-marginless" v-on-clickaway="closeActive">
    <div class="control">
      <div :class="{'is-active': isActive}" class="dropdown">
        <div class="dropdown-trigger">
          <button class="button type-dropdown-button" @click="toggleActive">
            <span>{{ name }} ({{ selectionIndicator }})</span>
            <span class="icon is-small"><i class="fa fa-angle-down"/></span>
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <div v-for="{key, count} in filter.values" :key="key" class="dropdown-item">
              <div class="field type-dropdown-item" @click="change({key, value: count})">
                <input type="checkbox" :checked="selected.indexOf(key) >= 0" class="is-checkradio"/>
                <label v-if="['part', 'page', 'document', 'book'].indexOf(key.toLowerCase()) > -1">
                  {{ $i18n.tc(key.toLowerCase() + 'Label', count) }} {{ count >= 0 ? `(${count})` : '' }}
                </label>
                <label v-else>
                  {{ $i18n.tc(key.toLowerCase(), count) }} {{ count >= 0 ? `(${count})` : '' }}
                </label>
              </div>
            </div>
            <div v-if="!hasOptions" class="dropdown-item">
              <div class="field">
                {{ $i18n.t("noItemsFound") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="control">
      <a class="button remove-filter-button" @click="removeFilter">
        <span class="icon is-small"><i class="fa fa-times"/></span>
      </a>
    </div>
  </div>
</template>

<script>
import {directive as onClickaway} from 'vue3-click-away';

export default {
  name: 'TypeFilter',
  directives: {
    onClickaway
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    selectedValues: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: [],
      isActive: false
    };
  },
  computed: {
    name() {
      if (this.filter.name.indexOf('%%') === 0) {
        return this.$i18n.tc(this.filter.name.replace(/%%/g, ''), 1);
      }
      return this.filter.name;
    },
    selectionIndicator() {
      if (this.selected.length === 1 && this.filter.values) {
        return this.$i18n.tc(this.selected[0].toLowerCase(), 2);
      } else if (this.selected.length > 1) {
        return this.selected.length;
      }
      return this.$i18n.t('any');
    },
    hasOptions() {
      return this.filter.values && this.filter.values.length > 0;
    }
  },
  watch: {
    selectedValues() {
      this.selected = this.selectedValues.slice();
    }
  },
  methods: {
    change({key}) {
      const index = this.selected.indexOf(key);
      if (index >= 0) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(key);
      }
      this.$emit('change', this.selected);
    },
    closeActive() {
      this.isActive = false;
    },
    toggleActive() {
      this.isActive = !this.isActive;
    },
    removeFilter() {
      this.$emit('remove');
    }
  }
};
</script>

<style scoped>
.dropdown-content {
  width: max-content;
  max-height: 50vh;
  overflow: auto;
}
.button {
  padding-left: .5rem;
  padding-right: .5rem;
}
.button .icon:last-child:not(:first-child) {
  margin-left: 0;
}
</style>
