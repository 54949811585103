<template>
  <div  v-if="content.isLoaded"
        style="min-height: fit-content;"
        class="library-content-header">
    <slot name="navigation">
      <div class="navigation">
        <h3 class="title-five">
          {{ content.name }}
        </h3>
        <bulma-button-icon  v-if="!isDetailsPage"
                            iconClass="fas fa-info-circle links-color"
                            class="pointer-on-hover"
                            @click="emit('infoButtonClicked')"/>
        <bulma-button-icon  v-if="isDetailsPage"
                            iconClass="fas fa-arrow-left black-color"
                            class="pointer-on-hover"
                            :text="$t('back')"
                            @click="emit('backButtonClicked')"/>
      </div>
    </slot>
    <slot name="contentActions">
      <div v-if="isDetailsPage">
        <content-actions :content="content"
                        :book="book"
                        :isDetailsPage="isDetailsPage"
                        :has-disabled-book-printing="disableBookPrinting"
                        :has-disabled-printing="disablePrinting"
                        :report-an-issue-enabled="reportAnIssueEnabled"/>
      </div>
    </slot>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { watch, ref, onMounted, computed } from 'vue'
import BulmaButtonIcon from "@/components/common/BulmaButtonIcon.vue"
import { useStore } from "vuex"
import contentActions from "@/components/library/content/ContentActions.vue"

const route = useRoute()
const store = useStore()

const props = defineProps({
  title:{
    type: String,
    required: true
  },
  content: Object
})

const isDetailsPage = ref(false)
const book = computed(() => store.state.content.toc.book)
const reportAnIssueEnabled = computed( () => store.state.user.contactOwnerEnabled)
const userHasBookPrintingDisabled = computed(() => store.getters['user/hasDisableBookPrinting'])
const disableBookPrinting = computed(() => userHasBookPrintingDisabled.value
  || book.value?.disableExport)
const disablePrinting = computed(() =>  userHasBookPrintingDisabled.value
  || props.content?.disableExport)


function setPageName(route) {
  isDetailsPage.value = route.path.includes('details')
}
watch([route], ([x,y,z]) => {
  setPageName(x)
})
onMounted(() => {
  setPageName(route)
})
const emit = defineEmits(['infoButtonClicked, backButtonClicked'])

</script>

<style scoped lang="scss">
.library-content-header {
  width: 100%;
  padding: 0 1em .5em 1em;
  min-height: 3em;
  display: flex;
  justify-content: space-between;
  .title-five {
    margin: .4em 1em 0;
    display: inline-block;
  }
  border-bottom: 1px solid lightgrey;
}
.button-wrap {
  margin: 0 1em;

}
</style>
