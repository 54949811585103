export const errorNotification = {
  errorNotification(textTr = null) {
    const error = {
      title: this.$t('error'),
      type: 'error',
      duration: 5000
    }
    if (textTr) error.text = textTr
    this.$notify(error)
  }
}

export const successNotification = {
  successNotification(title, text = "") {
    const success = {
      title: title,
      type: 'success',
      duration: 5000
    }
    if (!!text) success.text = text
    this.$notify(success)
  }
}