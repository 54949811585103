<template>
  <div class="card">
    <div class="card-content">
      <article class="media">
        <figure class="media-left has-text-centered">
          <p class="image add-to-cart">
            <a v-if="showCart"
               class="add-to-cart-button"
               @click="addToCart">
              <badged-icon badge="file"
                           :class="{'has-text-info': isHighlighted, 'has-text-danger': isInCart}"
                           :show-badge="isExactInCart"
                           icon="cart-plus"
                           size="is-large"/>
            </a>
          </p>
          <p v-if="selected.tagCount"
             class="image">
            <a class="tags-icon"
               @click="tags">
              <span class="icon is-small"
                    :class="{'has-text-info': isHighlighted}">
                <i class="fa fa-tags" />
              </span>
            </a>
          </p>
          <p v-if="selected.relatedCount"
             class="image">
            <a class="related-icon"
               @click="related">
              <span class="icon is-small"
                    :class="{'has-text-info': isHighlighted}">
                <i class="fa fa-paperclip" />
              </span>
            </a>
          </p>
          <p v-if="selected.commentCount"
             class="image">
            <a class="comments-icon"
               @click="comments">
              <span class="icon is-small"
                    :class="{'has-text-info': isHighlighted}">
                <i class="fa fa-comments" />
              </span>
            </a>
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p class="is-marginless part-info">
              <router-link :is="'router-link'"
                           :class="{'has-text-info': isHighlighted}"
                           :to="{ path: uri }">
                <strong>{{ selected.name }}</strong>
              </router-link> <br>
              <span
                class="has-text-weight-semibold"
                :class="{'has-text-info': isHighlighted}"
              >
                {{ selected.partNumber }}
              </span>
            </p>
            <div v-if="callout && callout.link">
              <label>Links</label> <br>
              <a v-for="link in callout.link"
                 :key="link.id"
                 class="hotpoint-link"
                 @click="openHotpointLink(link)">
                {{ link.name }}
                <span class="icon is-small">
                  <i class="fa fa-long-arrow-alt-right" />
                </span>
                <br>
              </a>
            </div>
          </div>
        </div>
        <div class="media-right">
          <button class="delete"
                  @click="close"/>
        </div>
      </article>
    </div>
    <bom-add-to-cart-modal v-if="addToCartModalOpen"
                           :bom-item="selected"
                           @addToCart="addToCartFromModal"
                           @cancel="addToCartModalOpen = false"/>
  </div>
</template>

<script>
import BomAddToCartModal from './BomAddToCartModal'
import BadgedIcon from '../../../../../../common/BadgedIcon'
import { mapState, mapGetters } from 'vuex'
import partLocationUtil from '@/components/common/partLocationUtil'

export default {
  name: 'BomInfo',
  persistentData: {
    showNewAdmin: {
      default: false
    }
  },
  props: {
    selected: {
      type: Object,
      required: true
    },
    callout: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BadgedIcon,
    BomAddToCartModal
  },
  data () {
    return {
      addToCartModalOpen: false
    }
  },
  computed: {
    ...mapGetters({
      isCartOrErpCartEnabled: 'user/isCartOrErpCartEnabled',
      hasCartPromptForQtyEnabled: 'user/hasCartPromptForQtyEnabled',
      targetUri: 'widgets/targetUri'
    }),
    ...mapState({
      addToCartQtyBehavior: ({ user }) => user.preferences.addToCartQtyBehavior,
      cart: ({ cart }) => cart.items,
      unprocessedCart: ({ cart }) => cart.unprocessedItems
    }),
    isOrderable () {
      return this.isCartOrErpCartEnabled && this.selected.orderable && this.selected.partNumber
    },
    showCart() {
      return this.isOrderable
    },
    isHighlighted () {
      return this.selected ? this.selected.highlight : false
    },
    isInCart () {
      if (this.cart.length) {
        let index = -1
        this.cart.some((cartItem, i) => {
          if (cartItem.partId === this.selected.id) {
            index = i
            return true
          }
          return false
        })

        return index >= 0
      }
      return false
    },
    isExactInCart () {
      if (this.unprocessedCart.length) {
        return this.unprocessedCart.some((cartItem) => {
          return cartItem.partId === this.selected.id &&
            cartItem?.mediaId === this.partLocation?.bookId &&
            cartItem?.chapterId === this.partLocation?.chapterId &&
            cartItem?.subChapterId === this.partLocation?.subChapterId &&
            cartItem?.pageId === this.partLocation?.pageId
        })
      }
      return false
    },
    // Additional cart context when adding cart items; books and pages are more accurately derived from store
    partLocation () {
      return partLocationUtil.getPartLocationData(this.$route.path)
    },
    uri () {
      const fullPath = this.$route.path

      if (this.selected.pagePartId) {
        return `${fullPath}/pagepart:${this.selected.pagePartId}`
      }
      return `${fullPath}/${this.selected.type}:${this.selected.id}`
    }
  },
  methods: {
    addToCart () {
      if (this.hasCartPromptForQtyEnabled) {
        this.addToCartModalOpen = true
      } else {
        this.addToCartFromModal()
      }
    },
    addToCartFromModal (qty) {
      this.addToCartModalOpen = false

      let quantity = 1
      switch (this.addToCartQtyBehavior) {
        case 'qtyZero':
          quantity = this.hasCartPromptForQtyEnabled ? 0 : 1
          break
        case 'qtyFromBom':
          quantity = parseInt(this.selected.quantity, 10) || 1
          break
        default:
          quantity = 1
      }

      const params = {
        part: this.selected,
        qty: qty || quantity,
        partLocation: this.partLocation
      }
      this.$store.dispatch('cart/addToCart', params)
    },
    openHotpointLink (link) {
      if (link.type === 'page') {
        const element = document.getElementById(`${link.type}:${link.id}`)
        if (element) {
          element.click()
          return
        }
      }
      this.$store.dispatch('content/navigateToContent', { content: link })
    },
    open ({ content, route }) {
      const fullPath = this.$route.path

      let path = `${fullPath}${content.type}:${content.id}${route}`
      if (content.pagePartId) {
        path = `${fullPath}/pagepart:${content.pagePartId}${route}`
      }
      this.$store.dispatch('content/navigateToContentPath', { path })
    },
    tags () {
      this.open({
        content: this.selected,
        route: '/info'
      })
    },
    related () {
      this.open({
        content: this.selected,
        route: '/related'
      })
    },
    comments () {
      this.open({
        content: this.selected,
        route: '/comments'
      })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.card {
  border: 1px solid lightgrey;
}
.card-content {
  max-height: 200px;
  padding: .5rem;
  overflow: auto;
}
.add-to-cart {
  margin-bottom: .5rem;
}
.part-info {
  padding-bottom: 1rem;
}
</style>
