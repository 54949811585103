<template>
  <div  v-if="bomLoaded && items.length"
        :class="[layout, 'bomContainer']">
    <nav  v-if="showSearchBar"
          class="navbar" style="border-right: 1px solid lightgrey;">
      <search-type-ahead :placeholder="$t('searchInContent', { contentName: searchContentNameLabel })"
                         :clear-on-enter="false"
                         :content="content"
                         :limit="5"
                         :number-of-results="bomItemCountInSearchResults"
                         :suggestion-types="['Part']"
                         :value="bomQuery"
                         class="bom-search"
                         @enter="search">
        <p v-if="bomItemCountInSearchResults"
           class="control">
          <button class="button is-primary is-outlined previous-button"
                  @click="previousResult">
            <span class="icon is-medium">
              <i class="fa fa-chevron-left" />
            </span>
          </button>
        </p>
        <p v-if="bomItemCountInSearchResults"
           class="control">
          <button class="button is-primary is-outlined next-button"
                  @click="nextResult">
            <span class="icon is-medium">
              <i class="fa fa-chevron-right" />
            </span>
          </button>
        </p>
      </search-type-ahead>
    </nav>
    <div class="bomWrapper"
         :class="{ 'widget-font': isWidget }">
      <table class="table is-bordered is-striped is-narrow is-hoverable is-marginless">
        <thead>
          <tr>
            <bom-header v-for="column in columns"
                        :key="column.property"
                        :column="column"
                        @togglePrice="togglePriceData($event.property)">
              <div v-if="isMobile" style="margin-left: -1em">
                <p class="control">
                  <a  data-int="search-button"
                      class="button is-primary is-small"
                      @click="isSearchOpen = !isSearchOpen">
                    <span class="icon">
                      <i class="fa fa-search" />
                    </span>
                  </a>
                </p>
              </div>
            </bom-header>
          </tr>
        </thead>

        <tbody class="table-body"
               :key="bomInstanceKey">
          <tr v-for="bomItem in effectiveBomItemsToShow"
              :id="bomItem.pagePartId"
              :key="bomItem.pagePartId"
              :class="{'is-selected': isSelected(bomItem)}"
              class="bom-item"
              @click="handleSelection(bomItem)">
            <bom-cell v-for="column in columns"
                      class="bom-cell"
                      :key="bomItem.id + '-' + column.property"
                      :add-to-cart-qty-behavior="addToCartQtyBehavior"
                      :bom-item="bomItem"
                      :column="column"
                      :has-cart-prompt-for-qty-enabled="hasCartPromptForQtyEnabled"
                      :has-enabled-widget-comments="hasEnabledWidgetComments"
                      :has-enabled-widget-tags="hasEnabledWidgetTags"
                      :is-library-beta-user="isLibraryBetaUser"
                      :is-mobile="isMobile"
                      :is-widget="isWidget"
                      :is-widget-cart-enabled="isWidgetCartEnabled"
                      :has-widget-info-landing-page="hasWidgetInfoLandingPage"
                      :item-label="itemLabel(bomItem)"
                      :hotpoint="getHotpointDtoForBomItem(bomItem)"
                      :popover-tags="popoverTags"
                      :popover-comments="popoverComments"
                      @openHotpointLink="openHotpointLink"
                      @openHotpointPopover="val => openHotpointPopover({ bomItem: bomItem, close: val })"
                      @openThumbnailPopover="openThumbnailPopover"
                      @closeThumbnailPopover="closeThumbnailPopover"
                      @openTagsPopover="openTagsPopover"
                      @closeTagsPopover="closeTagsPopover"
                      @openCommentsPopover="openCommentsPopover"
                      @closeCommentsPopover="closeCommentsPopover"
                      @widgetAddToCart="emitWidgetAddToCartEvent"
                      @openWidgetPartInfo="openWidgetPartInfo" />
          </tr>
        </tbody>
      </table>
    </div>
    <part3d-modal v-if="has3dFeatures && !!selectedBomItemFor3DModal"
                  :filepath="selectedBomItemFor3DModal.current3dThumbnailInfo?.scsFileUrl"
                  :title="`${selectedBomItemFor3DModal?.name} (${selectedBomItemFor3DModal?.partNumber})`"
                  @close="close3dModal" />
  </div>
  <div v-else-if="!bomLoaded"
       class="loading">
    <loading-icon />
  </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon'
import SearchTypeAhead from '@/components/common/SearchTypeAhead'
import BomCell from './cells/BomCell'
import BomHeader from './headers/BomHeader'
import Part3dModal from '@/components/library/content/Part3dModal.vue'
import partLocationUtil from '@/components/common/partLocationUtil'
import { computed } from 'vue'
import { mapState, mapActions, mapGetters, useStore } from 'vuex'
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage'
import { useOpenToContent } from '@/components/widgets/composables/useOpenToContent'
import { useWidgetAddToCart } from '@/components/widgets/composables/useWidgetAddToCart'
import { v4 as uuid } from 'uuid'
import { WidgetTypes } from '@/interfaces/global'

export default {
  name: 'Bom',
  components: {
    BomCell,
    BomHeader,
    LoadingIcon,
    Part3dModal,
    SearchTypeAhead
  },
  setup() {
    const { emitWidgetAddToCartEvent } = useWidgetAddToCart()
    const {
      hasHotpointLinkInBook,
      isSelectingBomItemViaWidgetConfig,
      setIsSelectingBomItemViaWidgetConfig,
      widgetOpenHotpointLink,
    } = useOpenToContent()
    const { openPartInfoPage: openWidgetPartInfo } = useInfoLandingPage()

    const store = useStore()
    const close3dModal = async () => await store.dispatch('content/close3dModal')
    const has3dFeatures = store.getters['user/has3dFeatures'] && !store.state['auth/isWidget']

    const searchContentNameLabel = computed(() => (store.state.content?.name?.length > 25) ? store.state.content?.name.substring(0,25) : store.state.content?.name)
    const selectedBomItemFor3DModal = computed(() => store.state.content.bom.selectedBomItemFor3DModal)

    const escEventListenerFor3dModal = (event) => {
      if (!selectedBomItemFor3DModal.value) return

      if (event.key === 'Escape' || event.key === 'Esc') {
        close3dModal()
      }
    }

    return {
      close3dModal,
      emitWidgetAddToCartEvent,
      escEventListenerFor3dModal,
      has3dFeatures,
      hasHotpointLinkInBook,
      isSelectingBomItemViaWidgetConfig,
      openWidgetPartInfo,
      selectedBomItemFor3DModal,
      setIsSelectingBomItemViaWidgetConfig,
      widgetOpenHotpointLink,
      searchContentNameLabel
    }
  },
  data () {
    return {
      bomInstanceKey: 0,
      bomItemCountInSearchResults: 0,
      bomQuery: '',
      effectiveBomItems: [], // Enriched bom data for viewing
      highlightedInstance: 0,
      scrollAfterSearchUpdate: false,

      // Reduce computed props
      addToCartQtyBehavior: this.$store.state.user.preferences.addToCartQtyBehavior,
      isSearchOpen: false,
      windowWidth: 1000
    }
  },
  watch: {
    items () {
      if (this.items.length) {
        this.search(this.tocQuery)
      }
    },
    tocQuery () {
      if (this.items.length) {
        this.search(this.tocQuery)
      }
    },
    unprocessedCart: {
      immediate: true,
      handler (value, oldValue) {
        if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
          this.preprocessBomItemData()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      hasCartPromptForQtyEnabled: 'user/hasCartPromptForQtyEnabled',
      hasEnabledWidgetComments: 'widgets/hasEnabledComments',
      hasEnabledWidgetTags: 'widgets/hasEnabledTags',
      hasWidgetInfoLandingPage: 'widgets/hasInfoLandingPage',
      hotpointsFlatList: 'content/hotpointsFlatList',
      isCartOrErpCartEnabled: 'user/isCartOrErpCartEnabled',
      isLibraryBetaUser: 'user/enableLibraryBetaFeature',
      isWidgetCartEnabled: 'widgets/isWidgetCartEnabled',
      popoverTags: 'content/tagNamesToValuesList',
      widgetType: 'widgets/widgetType',
    }),
    ...mapState({
      bomItems: (state) => state.content.bom.items,
      bomLoaded(state) {
        return this.isWidget
          ? state.content.bom.isLoaded
          : state.content.bom.isLoaded && state.cart.isLoaded
      },
      columns: (state) => state.content.bom.columns.items,
      content: (state) => state.content,
      hideDiscountPrice: (state) => state.user.pricing.hideDiscountPrice,
      hideRetailPrice: (state) => state.user.pricing.hideRetailPrice,
      hideWholeSalePrice: (state) => state.user.pricing.hideWholeSalePrice,
      isWidget: ({ auth }) => auth.isWidget,
      items: (state) => state.content.bom.items,
      layout: (state) => state.content.bom.layout,
      popoverComments: ({ widgets }) => widgets.partComments.items,
      selected: (state) => state.content.bom.selected,
      unprocessedCart: (state) => state.cart.unprocessedItems
    }),
    isMediaWidget() {
      return (this.widgetType === WidgetTypes.media)
        && this.isWidget
    },
    isPageWidget() {
      return (this.widgetType === WidgetTypes.page)
        && this.isWidget
    },
    tocQuery() {
      if (!!this.$store.state.content.toc.query) {
        return this.$store.state.content.toc.query
      } else {
        return this.$route.query.exact && this.$route.query.q
          ? `"${this.$route.query.q}"`
          : this.$route.query.q || ''
      }
    },
    isMobile() {
      return this.windowWidth <= 640
    },
    effectiveBomItemsToShow() {
      const childrenToHide = this.effectiveBomItems.reduce((map, it) => {
        if (it.isCollapsed && !!it.children.length) {
          it.children.forEach(child => map.set(child, child))
        }
        return map
      }, new Map())
      return this.effectiveBomItems.filter(it => !childrenToHide.has(it.refKey))
    },
    effectiveBomItemsRefKeyToIndex() {
      return this.effectiveBomItems.reduce((map, item, idx) => {
        map.set(item.refKey, idx)
        return map
      }, new Map())
    },
    bomToParentAndChildrenMap() {
      return this.items.reduce((map, it, i, array) => {
        const parent = it.refKey
        const children = []
        const itemIndent = it.indention
        const truncatedBom = array.slice(i + 1)

        for (let index = 0; index < truncatedBom.length; index++) {
          const { indention, refKey } = truncatedBom[index]
          const isSibling = indention === itemIndent
          const isChild = itemIndent === (indention - 1)
          if (isSibling) break
          else if (isChild) { children.push(refKey) }
        }

        map.set(parent, map.has(parent)
          ? { ...map.get(parent), children }
          : { children } )
        children.forEach(child => {
          map.set(child, map.has(child)
            ? { ...map.get(child), parent }
            : { parent })
        })
        return map
      }, new Map())
    },
    showSearchBar() {
      return (this.isMobile && this.isSearchOpen) || !this.isMobile
    }
  },
  methods: {
    ...mapActions({
      select: 'content/selectBomItems',
      selectBomItemsFromCallout: 'content/selectBomItemsFromCallout',
      getBomSearchWithReturn: 'content/bomSearchWithReturn'
    }),
    getHotpointDtoForBomItem(bomItem) {
      for (const hotpointDto of this.hotpointsFlatList) {
        if (hotpointDto.item === bomItem.itemText) {
          return hotpointDto
        }
      }
      return {}
    },
    handleSelection(bomItem) {
      const isDropdownCaret = event.target.classList.value.includes("caret")
      if (isDropdownCaret) {
        this.expandOrCollapseItem(bomItem)
      } else {
        this.select({item: bomItem})
      }
      this.closeHotpointInfo(bomItem.refKey)
    },
    itemLabel(bomItem) {
      return (bomItem.overrideItemText || !bomItem.itemText)
        ? this.$t('itemNotShown')
        : bomItem?.itemText ?? ''
    },
    expandOrCollapseItem(bomItem) {
      const idx = this.effectiveBomItems.findIndex(it => it.refKey === bomItem.refKey)
      const { isCollapsed } = bomItem
      setIsCollapsed.call(this, idx, !isCollapsed)
      for (let index = idx + 1; index < this.effectiveBomItems.length; index++) {
        const element = this.effectiveBomItems[index];
        const isSibling = element.indention === bomItem.indention
        if (isSibling) break
        else if (isCollapsed) continue
        else setIsCollapsed.call(this, index, !isCollapsed)
      }

      function setIsCollapsed(idx, isCollapsed) {
        const oldItem = this.effectiveBomItems[idx]
        const updatedItem = { ...oldItem, isCollapsed }
        this.effectiveBomItems.splice(idx, 1, updatedItem)
      }
    },
    openHotpointPopover (val) {
      const hotpoint = this.getHotpointDtoForBomItem(val.bomItem)
      if (hotpoint?.link?.length === 1) {
        this.openHotpointLink(hotpoint.link[0])
      } else {
        const index = this.effectiveBomItemsRefKeyToIndex.get(val.bomItem.refKey)
        const isValidItem = (index >= 0)
        if(!val.close && isValidItem){
          this.effectiveBomItems[index].showHotpointLinkPopover = true
        } else if (isValidItem) {
          this.effectiveBomItems[index].showHotpointLinkPopover = false
        }
        this.closeHotpointInfo(val.bomItem.refKey)
      }
    },
    openThumbnailPopover (refKey) {
      const index = this.effectiveBomItemsRefKeyToIndex.get(refKey)
      if (index >= 0) {
        this.effectiveBomItems[index] = {
          ...this.effectiveBomItems[index],
          showThumbnailPopover: true,
          showTagsPopover: false,
          showCommentsPopover: false
        }
      }
    },
    closeThumbnailPopover (refKey) {
      const index = this.effectiveBomItemsRefKeyToIndex.get(refKey)
      if (index >= 0) {
        this.effectiveBomItems[index].showThumbnailPopover = false
      }
    },
    openTagsPopover(refKey) {
      const index = this.effectiveBomItemsRefKeyToIndex.get(refKey)
      if (index >= 0) {
        this.effectiveBomItems[index] = {
          ...this.effectiveBomItems[index],
          showTagsPopover: true,
          showThumbnailPopover: false,
          showCommentsPopover: false
        }
      }
    },
    closeTagsPopover(refKey) {
      const index = this.effectiveBomItemsRefKeyToIndex.get(refKey)
      if (index >= 0) {
        this.effectiveBomItems[index].showTagsPopover = false
      }
    },
    openCommentsPopover(refKey) {
      const index = this.effectiveBomItemsRefKeyToIndex.get(refKey)
      if (index >= 0) {
        this.effectiveBomItems[index] = {
          ...this.effectiveBomItems[index],
          showCommentsPopover: true,
          showTagsPopover: false,
          showThumbnailPopover: false,

        }
      }
    },
    closeCommentsPopover(refKey) {
      const index = this.effectiveBomItemsRefKeyToIndex.get(refKey)
      if (index >= 0) {
        this.effectiveBomItems[index].showCommentsPopover = false
      }
    },
    closeHotpointInfo (selectedRefKey) {
      this.effectiveBomItems.forEach(bomItem => {
        if (bomItem.refKey !== selectedRefKey) {
          bomItem.showHotpointLinkPopover = false
        }
      })
    },
    openHotpointLink (link) {
      if (this.isWidget) {
        this.widgetOpenHotpointLink(link)
        return
      }

      if (link.type === 'page') {
        const element = document.getElementById(`${link.type}:${link.id}`)
        if (element) {
          element.click()
          return
        }
      }
      this.$store.dispatch('content/navigateToContent', { content: link })
    },
    togglePriceData (column) {
      let property
      switch (column) {
        case 'discounted':
          property = 'hideDiscountPrice'
          break
        case 'retail':
          property = 'hideRetailPrice'
          break
        case 'wholesale':
          property = 'hideWholeSalePrice'
          break
      }

      this.effectiveBomItems.forEach((item) => {
        item[property] = !item[property]
      })
      this.bomInstanceKey++
    },
    async search (query = '') {
      const searchResults = await this.getBomSearchWithReturn({ query })
      let bomItemCount = 0

      this.effectiveBomItems.forEach((item) => {
        item.isCollapsed = false
        item.isInSearchResults = searchResults.some(
          (result) => {
            if (result.entityId === item.id) {
              bomItemCount++
            }
            return result.entityId === item.id
          }
        )
      })
      this.bomItemCountInSearchResults = bomItemCount
      this.bomInstanceKey++

      this.scrollAfterSearchUpdate = true
      this.bomQuery = searchResults.length && query ? query : ''
    },
    isSelected (bomItem) {
      if (this.selected && this.selected.length) {
        let index = -1
        this.selected.some((item, i) => {
          if (item.pagePartId === bomItem.pagePartId) {
            index = i
            return true
          }
          return false
        })
        return index >= 0
      }
      return false
    },
    previousResult () {
      this.highlightedInstance = this.highlightedInstance - 1 < 0
        ? this.bomItemCountInSearchResults - 1
        : this.highlightedInstance - 1
      this.navigateToResult()
    },
    nextResult () {
      this.highlightedInstance = this.highlightedInstance + 1 >= this.bomItemCountInSearchResults
        ? 0
        : this.highlightedInstance + 1
      this.navigateToResult()
    },
    navigateToResult () {
      const highlighted = document.getElementsByClassName('indicator-cell highlighted')
      if (highlighted.length) {
        const item = highlighted[this.highlightedInstance].nextElementSibling.innerText
        this.selectBomItemsFromCallout(item)
      } else {
        this.selectBomItemsFromCallout()
      }
    },
    preprocessBomItemData () {
      const bomItems = [...this.items]
      const hotpoints = this.hotpointsFlatList.map(hp => {
        // Hotpoint Link AKA hp.link type:
        // Array<{id: number, name: string, type: string}>?
        if (this.isPageWidget) {
          delete hp.link
        }
        else if (this.isMediaWidget) {
          const filteredLinks = hp.link?.filter(it => {
            return this.hasHotpointLinkInBook(it)
          }) ?? []
          if (!!filteredLinks.length) {
            hp.link = filteredLinks
          } else {
            delete hp.link
          }
        }
        return hp
      })

      const partLocation = partLocationUtil.getPartLocationData(this.$route.path)
      const partIdsInCart = this.unprocessedCart.map((cartItem) => cartItem.partId)

      bomItems.forEach(bi => {
        bi.refKey = uuid()
        const hotpoint = hotpoints.find(hp => hp.item === bi.itemText)
        bi.hasHotpointLink = !!hotpoint &&
          Object.prototype.hasOwnProperty.call(hotpoint, 'link')
        bi.hideDiscountPrice = this.hideDiscountPrice
        bi.hideRetailPrice = this.hideRetailPrice
        bi.hideWholeSalePrice = this.hideWholeSalePrice
        bi.isOrderable = !(bi.partNumber && bi.orderable) ? false
          : this.isWidget ? this.isWidgetCartEnabled
          : this.isCartOrErpCartEnabled
        bi.pagePartUrl = bi.pagePartId
          ? `${this.$route.path}/pagepart:${bi.pagePartId}`
          : `${this.$route.path}/${this.bomItem.type}:${this.bomItem.id}`

        if (partIdsInCart.includes(bi.id)) {
          if (this.unprocessedCart.length) {
            bi.isInCart = true
            if (this.unprocessedCart.some((ci) => {
              return ci.partId === bi.id &&
                ci?.mediaId === partLocation?.bookId &&
                ci?.chapterId === partLocation?.chapterId &&
                ci?.subChapterId === partLocation?.subChapterId &&
                ci?.pageId === partLocation?.pageId
            }
            )) {
              bi.isExactInCart = true
            }
          }
        } else {
          bi.isInCart = false
          bi.isExactInCart = false
        }
      })
      this.effectiveBomItems = bomItems.map(it => {
        const { children, parent } = this.bomToParentAndChildrenMap.get(it.refKey)
        const extendedItem = { ...it, children, isCollapsed: false }
        return !!parent ? { ...extendedItem, parent } : extendedItem
      })
      this.bomInstanceKey++
    },
    getWindowWidth(e) {
      this.windowWidth = e.target.innerWidth
    }
  },
  async mounted () {
    if (this.tocQuery && this.items.length) {
      await this.search(this.tocQuery)
    }
    await this.$nextTick()
    this.windowWidth = window.innerWidth
    window.addEventListener("keyup", this.escEventListenerFor3dModal)
    window.addEventListener("resize", this.getWindowWidth)
  },
  beforeUnmount() {
    window.removeEventListener("keyup", this.escEventListenerFor3dModal)
    window.removeEventListener("resize", this.getWindowWidth)
  },
  updated () {
    if (this.isWidget && this.isSelectingBomItemViaWidgetConfig) {
      this.setIsSelectingBomItemViaWidgetConfig(false)
    }
    else if (this.scrollAfterSearchUpdate) {
      this.highlightedInstance = 0
      this.navigateToResult()
      this.scrollAfterSearchUpdate = false
    }
  }
}
</script>

<style scoped>
.navbar {
  z-index: 10;
  padding: .25rem;
  min-height: 2rem;
  flex-shrink: 0;
}
.field {
  flex-grow: 1;
}
.bomContainer {
  min-height: auto;
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}
.bomWrapper {
  overflow: auto;
  flex-grow: 1;
}
.table {
  min-width: 100%;
  border-bottom: 1px solid lightgrey;
  border-collapse: separate;
}
.loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.horizontal {
  border-left: lightgray 1px solid;
}
.vertical {
  border-top: lightgray 1px solid;
}
.is-outlined {
  background-color: white !important;
  color: grey !important;
}

thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: white !important;
  box-shadow: 1px 0 0 0 #ccc;
  z-index: 2;
}

.widget-font {
  font-size: 0.8em;
}

@media screen and (max-width: 640px) {
  .widget-font {
    font-size: 0.7em;
  }
}
.bom-cell {
  position: relative;
}
</style>
