import { ref, readonly, Ref, toRaw, computed, ComputedRef } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useWidgetNavigator } from './useWidgetNavigator'
import { TaxonDto } from '@/interfaces/library'
import { WidgetRootBreadcrumbs, LibraryWidgetOnlyPages } from '@/interfaces/global'

const { setCurrentWidgetView,
  setRootBreadcrumb } = useWidgetNavigator()

const taxons: Ref<TaxonDto[]> = ref([])
const isInitialSearchResultsMount = ref(false)

const isBrowseSearch = computed(() => {
  const lastIdx = taxons.value.length - 1
  return taxons.value[lastIdx]?.hasChildren === false
})
const taxonIdList: ComputedRef<string[]> = computed(() => {
  return taxons.value.map((dto: TaxonDto) => String(dto.id))
})

export function useBrowseFlows() {
  const store = useStore()
  const router = useRouter()

  function setIsInitialSearchResultsMount(val: boolean) {
    isInitialSearchResultsMount.value = val
  }
  
  function resetWidgetBrowseState() {
    taxons.value = []
  }

  function navigateToBrowse(dto: TaxonDto) {
    setRootBreadcrumb(WidgetRootBreadcrumbs.browse)
    setTaxons(dto)
    setCurrentWidgetView(LibraryWidgetOnlyPages.browse)
  }

  function setTaxons(dto: TaxonDto) {
    const idx = taxonIdList.value.indexOf(String(dto.id))
    if (idx === -1) {
      taxons.value.push(dto)
    } else {
      taxons.value = taxons.value.slice(0, idx + 1)
    }
    if (!dto.hasChildren) {
      setIsInitialSearchResultsMount(true)
    }
    router.push({ params: { id: toRaw(taxonIdList.value) } })
  }

  function goToLastBrowse() {
    store.dispatch('browse/navigateToSearch', true)
    setCurrentWidgetView(LibraryWidgetOnlyPages.browse, 
      { clearAllContent: true })
  }

  function removeLastFromBrowseHistory() {
    taxons.value.pop()
    setIsInitialSearchResultsMount(false)
    router.push({ params: { id: toRaw(taxonIdList.value) } })
  }

  return { 
    taxonIdList: readonly(taxonIdList),
    isBrowseSearch: readonly(isBrowseSearch),
    isInitialSearchResultsMount: readonly(isInitialSearchResultsMount),
    setIsInitialSearchResultsMount,
    navigateToBrowse, 
    resetWidgetBrowseState,
    setTaxonIdList: setTaxons,
    goToLastBrowse,
    removeLastFromBrowseHistory
  }
}