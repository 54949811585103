<template>
  <div  class="card"
        style="min-width: fit-content;">
    <div
      class="card-image"
      @click="click"
    >
      <thumbnail
        :thumbnail-url="item.thumbnailUrl"
        :thumbnail-type="entityType"
        data-int="suggestions card-thumbnail"
        class="is-128x128"
      />
    </div>
    <div class="card-content">
      <div class="content">
        <p
          data-int="suggestions-card-name"
          class="has-text-centered truncate is-marginless"
          data-toggle="tooltip"
          :title="item.name"
        >
          <span v-if="isWidget"
                @click="$emit('widgetNavToContent', item)">
            <strong :class="{ 'has-text-link': hasNavigableWidget,
                              'pointer-on-hover': hasNavigableWidget }">
              {{ item.name }}
            </strong>
          </span>
          <router-link  v-else
                        :to="{ path: uri }" 
                        @click.stop="">
            <strong>{{ item.name }}</strong>
          </router-link>
        </p>
        <p
          data-int="suggestions-card-identifier"
          class="subtitle is-size-6 has-text-centered truncate-subtitle"
          data-toggle="tooltip"
          :title="item.partNumber"
        >
          {{ item.partNumber }}
        </p>
      </div>
    </div>
    <footer
      v-if="hasAccessToCarts"
      class="card-footer"
      data-testid="add-to-carts"
    >
      <div class="field has-addons">
        <p class="control is-expanded">
          <input
            v-model="quantityInput"
            type="number"
            :min="min"
            :max="max"
            data-int="suggestions-card-quantity-input"
            class="input addToCartInput has-text-centered"
          >
        </p>
        <p class="control addToCartButtonContainer">
          <a
            data-int="suggestions-card-quantity-add"
            class="button is-primary addToCartButton"
            @click="addToCart"
          >
            {{ $t('add') }}
          </a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import Thumbnail from '@/components/common/Thumbnail'

export default {
  name: 'Card',
  props: {
    item: {
      type: Object,
      required: true
    },
    addToCartQtyBehavior: {
      type: String,
      default: ''
    },
    isWidget: Boolean,
    hasAccessToCarts: Boolean,
    hasNavigableWidget: Boolean
  },
  components: {
    Thumbnail
  },
  data () {
    return {
      quantityInput: 0
    }
  },
  computed: {
    uri () {
      return `/${this.entityType}/${this.item.id}`
    },
    entityType () {
      return this.item.type
    },
    quantity () {
      if (this.addToCartQtyBehavior === 'qtyFromBom' && this.item && this.item.quantity) {
        return parseInt(this.item.quantity, 10)
      } else {
        return 1
      }
    },
    min () {
      return 1
    },
    max () {
      return undefined
    }
  },
  methods: {
    click () {
      const event = this.isWidget ? 'widgetNavToContent' : 'click'
      this.$emit(event, this.item)
    },
    addToCart () {
      if (this.isWidget) {
        this.$emit('widgetAddToCart', {
          content: this.item,
          qty: this.quantityInput,
          addToCartQtyBehavior: this.addToCartQtyBehavior
        })
      } else {
        this.$emit('addToCart', {
          part: this.item,
          qty: this.quantityInput
        })
      }
    }
  },
  mounted () {
    this.quantityInput = this.quantity
  }
}
</script>

<style scoped>
.card {
  height: 100%;
  max-width: 200px;
}
.card-content {
  padding: .5rem;
  padding-bottom: 0;
  line-height: normal;
}
.card-image {
  padding: 1rem;
  cursor: pointer;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
  min-height: 2.5rem;
}
.truncate-subtitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
  min-height: 2rem;
}
.addToCartInput{
  border: none;
  box-shadow: none;
  height: 100%;
}
.addToCartButtonContainer {
  min-width: 6rem;
}
.addToCartButton {
  height: 100%;
  padding: .35rem 2rem;
  border-width: 1px;
}
.field.has-addons .control:first-child .button,
.field.has-addons .control:first-child .input,
.field.has-addons .control:first-child .select select {
  border-radius: 0 !important;
}
.field.has-addons .control:last-child .button,
.field.has-addons .control:last-child .input,
.field.has-addons .control:last-child .select select {
  border-radius: 0 !important;
}
</style>
