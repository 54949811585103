import http from '@/http'
import boilerplateActions from '../../actions'
import boilerplateMutations from '../../mutations'
import * as types from '../../mutations/mutations-types'
import i18n from '@/locales'
import { notify } from "@kyvg/vue3-notification"

const ORGANIZATIONS_RECEIVED = 'ORGANIZATIONS_RECEIVED'
const ORGANIZATIONS_TREE_RECEIVED = 'ORGANIZATIONS_TREE_RECEIVED'

const state = {
  data: null,
  error: null,
  loading: false,
  pagination: {
    offset: 0
  },
  searchTerm: '',
  sort: {
    field: 'name',
    order: 'asc'
  },

  items: [],
  isLoaded: false,
  tree: [],
  treeLoaded: false,
  includeParentInSearch: false
}

const actions = {
  ...boilerplateActions,

  async loadOrganizationsForSuperAdmin (context, tenantId, loadMore = false, configs = { pageSize: 100, limit: 100 }) {
    if (!loadMore) context.commit('RESET_PAGINATION')

    const offset = context.state.pagination.offset + (loadMore ? configs.pageSize : 0)
    const params = {
      tenantId: tenantId,
      limit: configs.limit, // Helpful debugger in conjunction with pageSize
      filter: context.state.searchTerm,
      offset: offset,
      sort: `${context.state.sort.field}:${context.state.sort.order}`,
      includeParentOrgNameInFilter: context.state.includeParentInSearch
    }

    try {
      context.commit(types.DATA_REQUEST)
      const { data, headers } = await http.get('/org-admin/super-admin/all', { params })

      context.commit(types.DATA_LOADING, true)
      if (data && headers) {
        if (loadMore) {
          context.commit('LOAD_MORE', data)
        } else {
          context.commit('DATA_SUCCESS', data)
        }

        context.commit('SET_PAGINATION', {
          count: Number(headers['x-count']),
          limit: Number(headers['x-limit']),
          offset: Number(headers['x-offset']),
          pageCount: Number(headers['x-page-count']),
          currentPage: Number(headers['x-page-num'])
        })
      }
    } catch (err) {
      console.log('err', err)
      notify({
        title: i18n.global.t('error'),
        text: err ? err.data.message : err,
        type: 'error'
      })
    } finally {
      context.commit(types.DATA_LOADING, false)
    }
  },
  setSearchTerm (context, term) {
    context.commit('SET_SEARCH_TERM', term)
  },
  setSort (context, data) {
    context.commit('SET_SORT', data)
  },
  setIncludeParentInSearch (context, data) {
    context.commit('SET_INCLUDE_PARENT', data)
  },
  setLoading (context, data) {
    context.commit('DATA_LOADING', data)
  }
}
const mutations = {
  ...boilerplateMutations,
  [ORGANIZATIONS_RECEIVED] (state, { organizations }) {
    state.items = organizations
    state.isLoaded = true
  },
  [ORGANIZATIONS_TREE_RECEIVED] (state, { organizations }) {
    state.tree = organizations
    state.treeLoaded = true
  },
  // ADMIN GRIDS MUTATIONS
  'DATA_LOADING' (state, payload) {
    state.loading = payload
  },
  'LOAD_MORE' (state, payload) {
    const newItems = []

    payload.forEach((item) => {
      if (state.data.findIndex((existing) => existing.id === item.id) === -1) {
        newItems.push(item)
      }
    })
    state.data = state.data.concat(newItems)
  },
  'RESET_PAGINATION' (state, payload) {
    state.pagination.offset = 0
  },
  'SET_SEARCH_TERM' (state, payload) {
    state.searchTerm = payload
  },
  'SET_PAGINATION' (state, payload) {
    Object.assign(state.pagination, payload)
  },
  'SET_SORT' (state, payload) {
    Object.assign(state.sort, payload)
  },
  'SET_INCLUDE_PARENT' (state, payload) {
    state.includeParentInSearch = payload
  },
  'CLEAR_PAGINATED_ORGS' (state) {
    state.data = null
    state.error = null
    state.loading = false
    state.pagination = {
      offset: 0
    }
    state.searchTerm = ''
    state.sort = {
      field: 'name',
      order: 'asc'
    }
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state
}
