import actions from '../actions';
import mutations from '../mutations';
import http from '@/http';
import i18n from '@/locales'
import { notify } from "@kyvg/vue3-notification"

export default {
  namespaced: true,
  state: {
    activePart: null,
    error: false,
    loading: false,
    data: [],
    partIdRef: null,
    pagination: {
      count: 100,
      limit: 100,
      offset: 0,
      pageCount: 12,
      currentPage: 1
    }
  },
  actions: {
    ...actions,
    async getProperty(context, {parentPartId, loadMore = false}) {
      try {
        context.commit('DATA_REQUEST');
        const {data, headers} = await http.get(`/admin/part-suggestions/part/${parentPartId}?limit=${100000}`);

        context.commit('DATA_SUCCESS', data);

        context.commit('SET_PAGINATION', {
          count: Number(headers['x-count']),
          limit: Number(headers['x-limit']),
          offset: Number(headers['x-offset']),
          pageCount: Number(headers['x-page-count']),
          currentPage: Number(headers['x-page-num'])
        });
      } catch (error) {
        notify({
          title: i18n.global.t('error'),
          text: i18n.global.t('failedToLoad', {
            content: i18n.global.tc('part', 2)
          }),
          type: 'error',
          duration: 5000
        })
        context.commit('DATA_ERROR', error);
      }
    },
    setRefPartId(context, id) {
      context.commit('SET_REF_PART_ID', id);
    },
    unsetActivePart(context, data) {
      context.commit('UNSET_ACTIVE_PART', data);
    },
    setActivePart(context, data) {
      context.commit('SET_ACTIVE_PART', data);
    }
  },

  mutations: {
    ...mutations,
    ['SET_REF_PART_ID'](state, payload) {
      state.partId = payload;
    },
    ['SET_ACTIVE_PART'](state, payload) {
      state.activePart = payload;
    },
    ['LOAD_MORE'](state, payload = []) {
      state.data = [...state.data, ...payload];
      state.loading = false;
    },
    ['UNSET_ACTIVE_PART'](state) {
      state.activePart = null
    }
  },
};
