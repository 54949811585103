<template>
  <div class="comments-form">
    <div class="field">
      <p class="control">
        <textarea v-model="text"
                  data-int="comment-text-area"
                  class="textarea"
                  :placeholder="$t('addComment') + '...'"
                  rows="2"
                  @keyup.enter="enterSubmit"/>
      </p>
    </div>
    <div class="field">
      <div class="control">
        <button :disabled="text.length === 0"
                data-int="comment-submit-button"
                class="button is-primary"
                @click="submit">
          {{ buttonLabel }}
        </button>
        <button v-if="isEditing" data-int="comment-cancel-button" class="button" @click="cancel">
          {{ $t('cancel') }}
        </button>
        <span class="checkbox global" @click="isGlobal = !isGlobal">
          <input type="checkbox" :checked="isGlobal" class="is-checkradio"/>
          <label>Global</label>
        </span>
        <span class="checkbox" @click="isPublicBelowOrg = !isPublicBelowOrg">
          <input type="checkbox" :checked="isPublicBelowOrg" class="is-checkradio"/>
          <label>Public</label>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentsEdit',
  props: {
    comment: {
      type: Object,
      required: true
    }
  },
  computed: {
    isEditing() {
      return this.comment.id;
    },
    buttonLabel() {
      return this.isEditing
        ? this.$i18n.t('update')
        : this.$i18n.t('postComment');
    },
    text: {
      get() {
        return this.comment.text || '';
      },
      set(value) {
        this.comment.text = value;
      }
    },
    isGlobal: {
      get() {
        return this.comment.global;
      },
      set(value) {
        this.comment.global = value;
      }
    },
    isPublicBelowOrg: {
      get() {
        return this.comment.publicBelowOrg;
      },
      set(value) {
        this.comment.publicBelowOrg = value;
      }
    }
  },
  methods: {
    enterSubmit(event) {
      if (event.shiftKey) {
        this.submit();
      }
    },
    submit() {
      this.$emit('submit', this.comment);
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style scoped>
.comments-form {
  padding: 1rem 1rem .5rem 1rem;
}
.textarea {
  min-height: 8rem;
}
.button {
  margin-right: .25rem;
}
.checkbox {
  padding: .5rem 0 .5rem 0;
}
.global {
  margin-left: 1rem;
}
</style>
