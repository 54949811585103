<template>
  <div class="field has-addons">
    <div class="control is-expanded has-icons-right">
      <input v-model="input"
             :placeholder="placeholder"
             autocomplete="off"
             class="input is-primary is-flex-grow-1"
             data-int="search-input"
             type="text"
             @input="debounceInput"
             @keydown.up="up"
             @keydown.down="down"
             @keyup.enter="enter" />
      <span class="icon is-small is-right has-text-info">
        {{ numberOfResults || '' }}
      </span>
      <ul v-if="hasSuggestions && suggestionsOpen"
          v-on-clickaway="close"
          class="menu-list">
        <li v-for="(item, index) in suggestions"
            :key="item.id"
            data-int="search-suggestion">
          <a :class="{'is-active': index === selectedSuggestionIndex}"
             class="search-suggestion"
             @click="select(item)">
            {{ item }}
          </a>
        </li>
      </ul>
    </div>
    <slot/>
    <p class="control">
      <a class="button is-primary"
         data-int="search-button"
         @click="enter">
        <span class="icon">
          <i class="fa fa-search"/>
        </span>
      </a>
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { directive as onClickaway } from 'vue3-click-away'
import debounce from 'lodash.debounce'

export default {
  name: 'SearchTypeAhead',
  props: {
    clearOnEnter: Boolean,
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    propertyField: {
      type: String,
      default: 'q'
    },
    content: {
      type: Object,
      default: () => {}
    },
    suggestionTypes: {
      type: Array,
      default: null
    },
    limit: {
      type: Number,
      default: 10
    },
    numberOfResults: {
      type: Number,
      default: 0
    }
  },
  directives: {
    onClickaway
  },
  data () {
    return {
      input: this.clearOnEnter ? '' : this.value || '',
      suggestions: [],
      selectedSuggestionIndex: -1,
      suggestionsOpen: false
    }
  },
  watch: {
    value () {
      this.input = this.clearOnEnter ? '' : this.value || ''
    }
  },
  computed: {
    hasSuggestions () {
      return this.suggestions && this.suggestions.length > 0
    }
  },
  methods: {
    ...mapActions({
      getSearchSuggestions: 'search/searchSuggestionsWithReturn'
    }),
    /* eslint-disable no-invalid-this */
    // TODO - fix this if you happen to be working in this file.  The value of 'this' is potentially
    // ambiguous
    debounceInput: debounce(function (e) {
      if (e.target.value.length) {
        this.getSuggestions()
      } else {
        this.suggestions = []
      }
    }, 500),
    /* eslint-enable no-invalid-this */
    async getSuggestions () {
      const suggestions = await this.getSearchSuggestions({
        field: this.propertyField,
        q: this.input,
        content: this.content,
        searchableType: this.suggestionTypes
      })

      this.suggestions = suggestions ? suggestions.slice(0, this.limit) : []
      this.suggestionsOpen = true
      this.selectedSuggestionIndex = -1
    },
    select (value) {
      this.suggestionsOpen = false
      this.input = value
      this.$emit('enter', value)

      if (this.clearOnEnter) {
        this.input = ''

        const elements = document.getElementsByClassName('search-input')
        for (let i = 0; i < elements.length; i++) { // eslint-disable-line
          elements[i].blur()
        }
      }
    },
    up () {
      this.selectedSuggestionIndex = Math.max(-1, this.selectedSuggestionIndex - 1)
    },
    down () {
      this.selectedSuggestionIndex = Math.min(this.suggestions.length - 1, this.selectedSuggestionIndex + 1)
    },
    enter () {
      if (this.selectedSuggestionIndex >= 0) {
        this.input = this.suggestions[this.selectedSuggestionIndex]
        document.activeElement.blur()
      }
      this.select(this.input)
    },
    close () {
      this.suggestionsOpen = false
    }
  }
}
</script>

<style scoped>
.is-expanded {
  z-index: 1;
}
.menu-list {
  position: absolute;
  display: block;
  background-color: #fff;
  width: 100%;
  overflow: auto;
  border: 1px solid lightgrey;
  z-index: auto;
}
input {
  box-shadow: none;
}
</style>
