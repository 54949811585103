<template>
  <div v-d-click-outside="closePopover">
    <popover :isBottom="isBottom"
             :popoverPosition="popoverPosition"
             arrowPosition="right">
      <virtual-list :style="listStyle"
                    :data-component="PopoverComment"
                    :data-sources="comments"
                    data-key="id"/>
    </popover>
  </div>
</template>

<script setup>
import VirtualList from 'vue3-virtual-scroll-list'
import Popover from "@/components/common/Popover"
import PopoverComment from "./PopoverComment"
import { usePopoverKeyUp } from "./usePopoverKeyUp"
import { CLOSE_EVENT, listStyle } from "./const"

const emits = defineEmits([CLOSE_EVENT])
const { closePopover } = usePopoverKeyUp(emits, CLOSE_EVENT)

defineProps({
  isBottom: Boolean,
  bomItemId: String,
  popoverPosition: Number,
  comments: {
    type: Array,
    default: () => []
  }
})
</script>