import i18n from '@/locales'
import { notify } from "@kyvg/vue3-notification"

import {
  CONTENT_RECEIVED,
  PRICING_RECEIVED,
  PRICING_LOADING,
  CLEAR_PRICING
} from './contentTypes'
import { asyncFetcher } from '@/store'

const state = getDefaultState()

const actions = {
  async getPricing ({ commit, rootState, dispatch }) {
    try {
      if (rootState.content.orderable) {
        const entityType = rootState.content.type
        const entityId = rootState.content.id

        const erpPricing = await dispatch('getPricingFromErp', {
          entityType,
          entityId,
          mediaIdentifier: rootState.content.toc && rootState.content.toc.book
            ? rootState.content.toc.book.identifier
            : '',
          quantity: parseInt(rootState.content.quantity, 10) || 1
        })
        commit(PRICING_RECEIVED, { pricing: erpPricing })
      }
    } catch (err) {
      // na
    }
  },
  async setPricing ({ commit }, erpPricing) {
    commit(PRICING_RECEIVED, { pricing: erpPricing })
  },
  async getPricingWithReturn ({ dispatch, state }, content) {
    let pricing = {}
    try {
      const entityType = content.type
      const entityId = content.id

      const erpPricing = await dispatch('getPricingFromErp', {
        entityType,
        entityId,
        mediaIdentifier: '',
        quantity: content.quantity || 1
      })
      pricing = erpPricing || {}
    } catch (err) {
      pricing = state
    }
    pricing.isLoaded = true
    return pricing
  },
  async getPricingFromErp ({ commit, rootState }, { entityType, entityId, mediaIdentifier, quantity }) {
    let pricing
    try {
      if (entityType === 'part') {
        if (rootState.user.erpEnabled && !rootState.content.partCode &&
            rootState.user.accessPrivileges.indexOf('PARTS_LIST_FETCH_PART_INFO_FROM_ERP_ENABLED') >= 0) {
          await commit(PRICING_LOADING)
          const params = {
            partId: entityId,
            mediaIdentifier: mediaIdentifier || '',
            qty: quantity || 1
          }

          pricing = await asyncFetcher('erp/real-time-pricing-async', null,
            { params }, 'get',
            'erp/real-time-pricing-async-poll', 'partDto',
            1000, 1000, 600000, (message) => {
              notify({
                title: i18n.global.t('error'),
                text: message,
                type: 'error',
                duration: 5000
              })
            })

          if (pricing == null) {
            pricing = {
              retailPrice: i18n.global.t('noPrice'),
              discountPrice: i18n.global.t('noPrice'),
              wholesalePrice: i18n.global.t('noPrice'),
              availability: '',
              eta: ''
            }
          }
        }
      }
    } catch (err) {
      let message = err
      const response = err.response

      if (response) {
        message = response.headers['x-message'] || response.data.message
      }
      notify({
        title: i18n.global.t('error'),
        text: message,
        type: 'error',
        duration: 5000
      })

      pricing = {
        retailPrice: i18n.global.t('noPrice'),
        discountPrice: i18n.global.t('noPrice'),
        wholesalePrice: i18n.global.t('noPrice'),
        availability: '',
        eta: ''
      }
    }

    return pricing
  }
}

const mutations = {
  [PRICING_RECEIVED] (state, { pricing }) {
    state.retailAmount = pricing.retailAmount >= 0 ? pricing.retailAmount : -1
    state.retailPrice = pricing.retailPrice || ''
    state.discountAmount = pricing.discountAmount >= 0 ? pricing.discountAmount : -1
    state.discountPrice = pricing.discountPrice || ''
    state.wholesaleAmount = pricing.wholesaleAmount >= 0 ? pricing.wholesaleAmount : -1
    state.wholesalePrice = pricing.wholesalePrice || ''
    state.availability = pricing.availability || ''
    state.eta = pricing.eta || ''
    state.isLoaded = true
  },
  [PRICING_LOADING] (state) {
    state.isLoaded = false
  },
  [CONTENT_RECEIVED] (state, { content }) {
    state.retailAmount = content.retailAmount >= 0 ? content.retailAmount : -1
    state.retailPrice = content.retailPrice || ''
    state.discountAmount = content.discountAmount >= 0 ? content.discountAmount : -1
    state.discountPrice = content.discountPrice || ''
    state.wholesaleAmount = content.wholesaleAmount >= 0 ? content.wholesaleAmount : -1
    state.wholesalePrice = content.wholesalePrice || ''
    state.availability = ''
    state.eta = ''
    state.isLoaded = true
  },
  [CLEAR_PRICING] (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  actions,
  mutations
}

function getDefaultState() {
  return {
    retailAmount: -1,
    retailPrice: '',
    discountAmount: -1,
    discountPrice: '',
    wholesaleAmount: -1,
    wholesalePrice: '',
    availability: '',
    eta: '',
    isLoaded: false
  }
}
