<template>
  <main class="order-container">
    <nav
      v-if="orderLoaded"
      class="level is-marginless"
    >
      <div class="level-left">
        <div class="level-item">
          <div class="subtitle is-5">
            <strong>{{ $t('status') }}:</strong>
          </div>
          <div class="control">
            <div
              v-if="isSaveable"
              class="select status"
            >
              <b-select
                v-if="isSaveable"
                v-model="status"
              >
                <option
                  v-for="sc in availableStatusCodes"
                  :key="sc.code"
                  :value="sc.code"
                >
                  {{ sc.name }}
                </option>
              </b-select>
            </div>
            <label
              class="subtitle status is-5"
              v-else
            >{{ this.statusCodes[status] }}</label>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a
            :href="downloadURL"
            download
            class="button print-button"
          >
            <span class="icon"><i class="fa fa-download" /></span>
            <span>{{ $t('download') }}</span>
          </a>
          <button
            class="button history-button"
            @click="history"
          >
            <span class="icon"><i class="fa fa-history" /></span>
            <span>{{ $t('history') }}</span>
          </button>
        </div>
      </div>
    </nav>
    <section
      v-if="orderLoaded"
      class="order"
    >
      <div
        v-for="(row, index) in orderLayout"
        :key="index"
        class="columns"
      >
        <div
          v-for="(column, index) in row"
          :key="index"
          class="column"
        >
          <order-cell
            v-for="item in column"
            :key="item"
            :item="orderFields[item]"
            :data="orderData[item] || null"
            :can-edit="isEditable"
          />
        </div>
      </div>
      <div class="items">
        <label class="label">{{ $tc('part', 2) }}</label>
        <order-part-table-wrapper :can-edit="isEditable">
          <div class="level-left">
            <button
              v-if="isEditable && isQuickAddEnabled"
              class="button level-item is-info quick-add-button"
              @click="quickAddModalOpen = true"
            >
              {{ $t('add') }}
            </button>
          </div>
        </order-part-table-wrapper>
      </div>
    </section>
    <section
      v-else
      class="order-loading"
    >
      <loading-icon />
    </section>
    <nav
      v-if="orderLoaded"
      class="level is-mobile"
    >
      <div class="level-left">
        <div
          v-if="isSaveable"
          class="level-item"
        >
          <button
            class="button is-primary"
            data-int="submit-order"
            :disabled="!canUpdateOrder"
            @click="updateOrderAndNavToOrders(status)"
          >
            {{ $t('update') }}
          </button>
        </div>
        <div class="level-item">
          <button
            class="button close-button"
            @click="navigateToOrders"
          >
            {{ $t('close') }}
          </button>
        </div>
      </div>
    </nav>
    <order-history-modal
      v-if="historyModalOpen"
      :order="order"
      @close="historyModalOpen = false"
    />
    <order-quick-add-modal-wrapper
      v-if="quickAddModalOpen && isQuickAddEnabled"
      @cancel="quickAddModalOpen = false"
    />
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LoadingIcon from '@/components/common/LoadingIcon'
import OrderQuickAddModalWrapper from './parts/OrderQuickAddModalWrapper'
import OrderHistoryModal from './OrderHistoryModal'
import OrderCell from './cells/OrderCell'
import OrderPartTableWrapper from './parts/OrderPartTableWrapper'
import { mapGetters } from 'vuex'

export default {
  name: 'Order',
  components: {
    OrderCell,
    OrderPartTableWrapper,
    OrderHistoryModal,
    OrderQuickAddModalWrapper,
    LoadingIcon
  },
  data () {
    return {
      historyModalOpen: false,
      quickAddModalOpen: false,
      status: this.order ? this.order.statusCode : ''
    }
  },
  watch: {
    order () {
      this.status = this.order.statusCode
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.order.info,
      orderLayout: (state) => state.order.layout,
      orderFields: (state) => state.order.fields,
      orderData: (state) => state.order.data,
      orderLoaded: (state) => state.order.isLoaded,
      orderUpdating: (state) => state.order.isUpdating,
      statusCodes: (state) => state.statuscodes.items,
      userId: (state) => state.user.id
    }),
    ...mapGetters({
      isQuickAddEnabled: 'user/isQuickAddEnabled',
      isOrderViewer: 'user/isOrderViewer',
      isOrderApprover: 'user/isOrderApprover',
      canSubmitOrder: 'order/canSubmitOrder',
      isOrderAdmin: 'user/isOrganizationAdmin',
      hasErpCart: 'user/hasCartSendToErp',
      requiredOrderFields: 'order/requiredFields'
    }),
    canUpdateOrder () {
      if (this.isOrderAdmin || this.isOrderApprover) {
        return true
      }
      return this.canSubmitOrder
    },
    isEditable () {
      return this.isOrderApprover && this.order.statusCode === 'OPEN'
    },
    isCancellable () {
      if (this.isOrderAdmin || this.isOrderApprover) {
        return true
      }
      return this.order.statusCode === 'SUBMITTED'
    },
    isSaveable () {
      return !this.hasErpCart &&
        (this.isOrderAdmin || this.isOrderApprover || this.isOrderViewer)
    },
    availableStatusCodes () {
      const statusCodes = []
      Object.keys(this.statusCodes).forEach((key) => {
        if (key === 'CANCELLED' && !this.isCancellable) {
          return null
        }
        if (key === 'OPEN') {
          return null
        }
        return statusCodes.push({ code: key, name: this.statusCodes[key] })
      })
      return statusCodes
    },
    downloadURL () {
      return `/api/orders/${this.orderId}/print/pdf`
    },
    orderId () {
      if (this.$route.params.id) {
        return this.$route.params.id
      } else {
        return undefined
      }
    }
  },
  methods: {
    ...mapActions({
      updateOrder: 'order/updateOrder',
      duplicateOrder: 'order/duplicateOrder',
      navigateToOrders: 'order/navigateToOrders',
      getOrder: 'order/getOrder',
      getStatusCodes: 'statuscodes/getStatusCodes'
    }),
    history () {
      this.historyModalOpen = true
    },
    async updateOrderAndNavToOrders (status) {
      await this.updateOrder(status)
      this.navigateToOrders()
    },
  },
  async created() {
    await this.getOrder(this.$route.params.id)
    await this.getStatusCodes()
    await this.$nextTick()
    document.title = this.order?.orderNumber ?? ''
  }
}
</script>

<style scoped>
.order-container {
  flex: 1;
  display: flex;
  flex-flow: column;
  background-color: darkgray;
}
.order-loading {
  flex: 1;
  display: flex;
  flex-flow: column;
  background-color: white;
  justify-content: center;
  align-items: center;
}
.order {
  flex: 1;
  overflow: auto;
  padding: 1rem;
  background-color: white;
}
.level {
  padding: 1rem;
}
.level.is-mobile .level-item:last-child {
  margin-right: 0;
}
.print-button {
  margin-right: .5rem;
}
.status {
  margin-left: .25rem;
}
.level {
  border-bottom: lightgray 1px solid;
  background-color: white;
}
</style>
