<template>
  <div class="field">
    <label class="label is-size-5" v-html="item.name" />
  </div>
</template>

<script>
export default {
  name: 'OrderCellLabel',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.field {
  padding: .5rem 1rem;
  width: 100%;
}
</style>
