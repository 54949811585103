<template>
  <div class="modal" :class="{'is-active': showModal}">
    <div class="modal-background" />
    <div class="modal-content box">
      <h5 class="title-five mb-3">{{ title }}</h5>
      <b-progress type="is-primary is-large" :value="progressValue" show-value :format="progressType" />
    </div>
  </div>
</template>

<script>
import RaisedCard from '@/components/common/RaisedCard.vue';

export default {
  name: 'ProgressModal',
  components: {
    RaisedCard
  },
  props: {
    progressValue: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: 'File Upload'
    },
    usePercentage: {
      type: Boolean,
      default: true
    },
    isActive: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    progressType() {
      return this.usePercentage ? 'percent' : 'raw';
    },
    showModal() {
      // Auto-close when complete
      if (this.isActive && this.usePercentage) {
        return !(this.progressValue === 100);
      }
      return this.isActive;
    }
  }
};
</script>

<style scoped lang="scss">
.progress-wrapper {
  margin-bottom: 0.75rem;
}
.modal-content {
  width: 50% !important;
}
</style>
