import http from '@/http'
import i18n from '@/locales'
import { notify } from "@kyvg/vue3-notification"

import columns from './columns'
import {
  addToErpCart,
  addToInternalCart,
  clearCart,
  getCart,
  getSuggestions,
  importCart,
  openSavedCart,
  openSharedCart,
  removeFromCart,
  sendToErp,
  updateQuantity
} from '@/controllers/library/cart'

const CART_RECEIVED = 'CART_RECEIVED'
const CART_ITEM_UPDATED = 'CART_ITEM_UPDATED'
const CART_ITEM_REMOVED = 'CART_ITEM_REMOVED'
const CART_ITEM_SELECT = 'CART_ITEM_SELECT'
const CART_SUGG_RECEIVED = 'CART_SUGG_RECEIVED'
const CART_NOT_IMPORTED = 'CART_NOT_IMPORTED'
export const CART_LOADING = 'CART_LOADING'

const getToastDuration = async tenantKey => {
  const { data } = await http.get(`/tenants/${tenantKey}/properties`)
  return (data.ADD_TO_CART_TOAST_DURATION_SECONDS || 4) * 1000
}

/**
 * Totals all possible prices. Hiding prices handled separately at Vue components.
 *
 * @param items
 * @param locale
 * @returns {{totalDiscountedPrice: string, totalRetailPrice: string, totalWholesalePrice: string}}
 */
function runTotalCartItems (items, locale = 'en-US') {
  function formatPrice (amount, currency) {
    if (amount === 0.0) return ''
    const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: currency })
    return formatter.format(amount)
  }

  let totalDiscountedPrice = 0.0
  let totalRetailPrice = 0.0
  let totalWholesalePrice = 0.0

  let discountedCurrency = 'USD'
  let retailCurrency = 'USD'
  let wholesaleCurrency = 'USD'

  if (items !== null && items.length > 0) {
    items.forEach((item) => {
      const itemTotalDiscounted = item.discountAmount * item.qty
      const itemTotalRetail = item.retailAmount * item.qty
      const itemTotalWholesalePrice = item.wholesaleAmount * item.qty

      /**
       * Update currency and price when item total is greater than zero.
       * Zero prices are invisible. All item currency can default to USD,
       * and can be different than items with prices.
       */
      if (itemTotalDiscounted > 0) {
        totalDiscountedPrice += itemTotalDiscounted
        discountedCurrency = item.discountCurrency
      }

      if (itemTotalRetail > 0) {
        totalRetailPrice += itemTotalRetail
        retailCurrency = item.retailCurrency
      }

      if (itemTotalWholesalePrice > 0) {
        totalWholesalePrice += itemTotalWholesalePrice
        wholesaleCurrency = item.wholesaleCurrency
      }
    })
  }

  return {
    totalDiscountedPrice: formatPrice(totalDiscountedPrice, discountedCurrency),
    totalRetailPrice: formatPrice(totalRetailPrice, retailCurrency),
    totalWholesalePrice: formatPrice(totalWholesalePrice, wholesaleCurrency)
  }
}

/**
 * Important when cart item updates or deletes
 *
 * @param item
 * @param cartItems
 * @returns number
 */
function findIndexOfItem (item, cartItems) {
  return cartItems.findIndex((ci) => {
    return item.partId === ci.partId &&
      item.mediaId === ci.mediaId &&
      item.chapterId === ci.chapterId &&
      item.subChapterId === ci.subChapterId &&
      item.pageId === ci.pageId
  })
}

function replaceItemInCart (item, cartItems) {
  const index = findIndexOfItem(item, cartItems)
  const ci = [...cartItems]
  if (index >= 0) {
    ci.splice(index, 1, item)
  } else {
    ci.push(item)
  }
  return ci
}

function deleteItemInCart (item, cartItems) {
  const index = findIndexOfItem(item, cartItems)
  const ci = [...cartItems]
  if (index >= 0) {
    ci.splice(index, 1)
  }
  return ci
}

const state = {
  isLoaded: false,

  currencyCode: '',
  selected: undefined,
  totalRetailPrice: '',
  totalWholesalePrice: '',
  totalDiscountedPrice: '',

  suggestions: [],
  items: [],
  unprocessedItems: [] // Helpful when referencing for a traditional cart
}

const actions = {
  async getCart ({ commit, dispatch, rootState }) {
    try {
      if (rootState.user.accessPrivileges.indexOf('SHOPPING_CART_ENABLED') >= 0) {
        await commit(CART_LOADING)

        const columnsFetcher = dispatch('getCartColumns')
        const suggestFetcher = dispatch('reloadSuggestions')
        const data = await getCart()

        if ((data.itemsRemoved > 0)) {
          notify({
            title: i18n.global.t('success'),
            text: i18n.global.t('cartLoadedWithItemsRemoved', { count: data.itemsRemoved }),
            type: 'success',
            duration: 5000
          })
        }

        await commit(CART_RECEIVED, { cart: data })
        await Promise.all([columnsFetcher, suggestFetcher])
      } else {
        await commit(CART_LOADING, false)
      }
    } catch (err) {
      commit(CART_RECEIVED, { cart: {} })
    }
  },
  async reloadSuggestions ({ rootState, commit }) {
    let suggestions = []

    if (rootState.user.accessPrivileges.indexOf('PART_ORDER_SUGGESTIONS_ENABLED') >= 0) {
      try {
        let suggestionCount

        // Full size
        if (window.innerWidth > 1024) {
          suggestionCount = 6
        } else if (window.innerWidth > 769) { // Tablet size
          suggestionCount = 4
        } else { // Mobile size
          suggestionCount = 3
        }

        const { data } = await getSuggestions(suggestionCount)
        suggestions = data
      } catch (error) {
        console.log('error :>> ', error)
      }
    }

    commit(CART_SUGG_RECEIVED, suggestions)
  },
  async selectCartItem ({ commit }, { item }) {
    const found = this.selected === item

    if (!found) {
      await commit(CART_ITEM_SELECT, { item })
    } else {
      await commit(CART_ITEM_SELECT, { item: undefined })
    }
  },
  /**
   * Part location data added to request only if provided. In cases like Quick Add, they should
   * have no part-location data.
   */
  async addToCart ({ rootState, dispatch, commit }, { part, qty, partLocation = null }) {
    try {
      commit(CART_LOADING)
      const qtyNumber = parseInt(qty, 10)

      if (rootState.user.erpEnabled &&
        rootState.user.accessPrivileges.indexOf('ADD_TO_CART_ERP_ENABLED') >= 0) {
        await dispatch('addToERPCart', {
          part,
          qty: qtyNumber.toString() || '1',
          cartContext: partLocation
        })
      } else if (rootState.user.accessPrivileges.indexOf('SHOPPING_CART_ENABLED') >= 0) {
        await dispatch('addToInternalCart', {
          part,
          qty: qtyNumber.toString() || '1',
          cartContext: partLocation
        })
      }

      await dispatch('reloadSuggestions')
    } catch (err) {
      console.log('error :>> ', err)
    } finally {
      commit(CART_LOADING, false)
    }
  },
  async addToInternalCart ({ commit, rootState }, { part, qty, cartContext }) {
    try {
      const payload = {
        partId: part.id || part.entityId,
        qty: qty || 1,
        mediaId: cartContext ? cartContext.bookId : null,
        chapterId: cartContext ? cartContext.chapterId : null,
        subChapterId: cartContext ? cartContext.subChapterId : null,
        pageId: cartContext ? cartContext.pageId : null
      }
      const data = await addToInternalCart(payload)
      const duration = await getToastDuration(rootState.user.tenantKey)

      // null data -> error
      if (data === null) return

      /*
       * There are edge cases (e.g. suggestions) that can allow users to add an orphaned part
       * And the ACs in cart will filter it out. Thus, this check is in place.
       */
      if (data.itemsRemoved === null || data.itemsRemoved === 0) {
        commit(CART_ITEM_UPDATED, { cart: data })
        if (duration > 0) {
          notify({
            title: i18n.global.t('add'),
            text: i18n.global.t('addToCartMessage', { partNumber: part.partNumber }),
            type: 'success',
            duration: duration
          })
        }
      } else if (duration > 0) {
        notify({
          title: i18n.global.t('success'),
          text: i18n.global.t('cartLoadedWithItemsRemoved', { count: data.itemsRemoved }),
          type: 'success',
          duration: 5000
        })
      }
    } catch (err) {
      const response = err.response
      notify({
        title: i18n.global.t('error'),
        text: response ? response.data.message : err,
        type: 'error',
        duration: 5000
      })
    }
  },
  async addToERPCart ({ rootState }, { part, qty, cartContext }) {
    try {
      const { tenantKey } = rootState.user
      const duration = await getToastDuration(tenantKey)
      await addToErpCart(part, qty, duration, cartContext)
    } catch (err) {
      console.log('error :>> ', err)
    }
  },
  async sendToErp () {
    try {
      const headers = await sendToErp()

      let message = i18n.global.t('cartErpMessage')
      if (headers && headers['x-message']) {
        message = headers['x-message']
      }
      notify({
        title: i18n.global.t('cart'),
        text: message,
        type: 'success'
      })
    } catch (err) {
      const response = err.response
      notify({
        title: i18n.global.t('erp'),
        text: response ? response.data.message : err,
        type: 'error',
        duration: 5000
      })
    }
  },
  async removeFromCart ({ commit, dispatch }, { item }) {
    commit(CART_LOADING)
    try {
      // Stringify at asyncFetncher that the payload is readable for the mutation
      const payload = {
        partId: item.partId,
        qty: 0,
        mediaId: item.mediaId,
        chapterId: item.chapterId,
        subChapterId: item.subChapterId,
        pageId: item.pageId
      }
      await removeFromCart(payload)
      commit(CART_ITEM_REMOVED, { removedItem: payload })

      await dispatch('reloadSuggestions')
    } catch (err) {
      console.log('error :>> ', err)
    } finally {
      commit(CART_LOADING, false)
    }
  },
  async updateQuantity ({ commit, dispatch }, { item, qty }) {
    try {
      commit(CART_LOADING)
      // Stringify later; can use payload for mutation, especially qty is zero
      const payload = {
        partId: item.partId,
        qty: qty,
        mediaId: item.mediaId,
        chapterId: item.chapterId,
        subChapterId: item.subChapterId,
        pageId: item.pageId
      }
      const data = await updateQuantity(payload)

      if (payload.qty > 0) {
        await commit(CART_ITEM_UPDATED, { cart: data })
      } else {
        await commit(CART_ITEM_REMOVED, { removedItem: payload })
        await dispatch('reloadSuggestions')
      }
    } catch (err) {
      console.log('error :>> ', err)
    } finally {
      commit(CART_LOADING, false)
    }
  },
  async clearCart ({ commit, dispatch }) {
    try {
      await clearCart()
      await Promise(
        [
          commit(CART_RECEIVED, { cart: {} }),
          dispatch('reloadSuggestions')
        ]
      )
    } catch (err) {
      // na
    }
  },
  async importCart ({ commit, dispatch }, { attachment, clear }) {
    try {
      await commit(CART_LOADING)
      await importCart(attachment, clear)
      await dispatch('getCart')
    } catch (err) {
      // If import has failed, disable loading icon and display error notification
      await commit(CART_NOT_IMPORTED)
      notify({
        title: i18n.global.t('error'),
        text: i18n.global.t('cartImportError'),
        type: 'error',
        duration: 5000
      })
    }
  },
  async openSavedCart ({ commit, rootState }, { cartId, clear }) {
    try {
      if (rootState.user.accessPrivileges.indexOf('SAVE_SHOPPING_CARTS') >= 0) {
        commit(CART_LOADING)
        const data = await openSavedCart(cartId, clear)

        if ((data.itemsRemoved > 0)) {
          notify({
            title: i18n.global.t('success'),
            text: i18n.global.t('cartLoadedWithItemsRemoved', { count: data.itemsRemoved }),
            type: 'success',
            duration: 5000
          })
        }

        commit(CART_RECEIVED, { cart: data })
      }
    } catch (err) {
      notify({
        title: i18n.global.t('error'),
        text: i18n.global.t('failedAction', {
          content: i18n.global.tc('cart', 1),
          action: i18n.global.t('open').toLocaleLowerCase()
        }),
        type: 'error',
        duration: 5000
      })
    }
  },
  async openSharedCart ({ commit, rootGetters, rootState }, { cartId, clear }) {
    try {
      commit(CART_LOADING)

      if (rootGetters['user/isSharedCartEnabled']) {
        const data = await openSharedCart(cartId, clear)

        commit(CART_RECEIVED, { cart: data })

        if ((data.itemsRemoved > 0)) {
          notify({
            title: i18n.global.t('success'),
            text: i18n.global.t('cartLoadedWithItemsRemoved', { count: data.itemsRemoved }),
            type: 'success',
            duration: 5000
          })
        }
      }
    } catch (e) {
      notify({
        title: i18n.global.t('error'),
        text: i18n.global.t('failedAction', {
          content: i18n.global.tc('sharedCart', 1),
          action: i18n.global.t('open').toLocaleLowerCase()
        }),
        type: 'error',
        duration: 5000
      })
    }
  }
}

/**
 * Currency formats and totals now run on the frontend after cart changes update that ONE item --
 * an effort to reduce call volume, especially ERP-integrated tenants. This made totals a Rocket
 * responsibility using a JS library (as opposed to the Java). There are small differences
 * between JS and Java libraries. Thus, ALL currency formats and totals should be done
 * on the frontend.
 *
 * TLDR; don't use totals in the object, which is overloaded with other use cases.
 */
const mutations = {
  [CART_RECEIVED] (state, { cart }) {
    state.items = cart.items || []
    state.unprocessedItems = cart.unprocessedItems || []
    state.currencyCode = cart.currencyCode

    /**
     * Because Rocket must format prices in some cases, Rocket ignores ALL total price data and
     * runs them on the frontend
     */
    const cartTotals = runTotalCartItems(state.items, state['user/locale'])
    state.totalDiscountedPrice = cartTotals.totalDiscountedPrice
    state.totalRetailPrice = cartTotals.totalRetailPrice
    state.totalWholesalePrice = cartTotals.totalWholesalePrice
    state.isLoaded = true
  },
  [CART_ITEM_UPDATED] (state, { cart }) {
    // Replace the updated items in the existing lists
    cart.items.forEach((updatedItem) => {
      state.items = replaceItemInCart(updatedItem, state.items)
    })
    cart.unprocessedItems.forEach((updatedItem) => {
      state.unprocessedItems = replaceItemInCart(updatedItem, state.unprocessedItems)
    })

    const cartTotals = runTotalCartItems(state.items, state['user/locale'])
    state.totalDiscountedPrice = cartTotals.totalDiscountedPrice
    state.totalRetailPrice = cartTotals.totalRetailPrice
    state.totalWholesalePrice = cartTotals.totalWholesalePrice
    state.isLoaded = true
  },
  [CART_ITEM_REMOVED] (state, { removedItem }) {
    state.items = deleteItemInCart(removedItem, state.items)
    state.unprocessedItems = deleteItemInCart(removedItem, state.items)

    const cartTotals = runTotalCartItems(state.items, state['user/locale'])
    state.totalDiscountedPrice = cartTotals.totalDiscountedPrice
    state.totalRetailPrice = cartTotals.totalRetailPrice
    state.totalWholesalePrice = cartTotals.totalWholesalePrice
    state.isLoaded = true
  },
  [CART_ITEM_SELECT] (state, { item }) {
    state.selected = item
  },
  [CART_LOADING] (state, payload = true) {
    state.isLoaded = !payload
  },
  [CART_SUGG_RECEIVED] (state, items) {
    state.suggestions = items
  },
  [CART_NOT_IMPORTED] (state) {
    state.isLoaded = true
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    columns
  }
}
