import { ref, Ref, computed, ComputedRef, toRaw, readonly } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useFetch } from './useFetchContent'
import i18n from '@/locales'
import { Breadcrumb, MediaWidgetEntityTypes, 
  WidgetMediaTypes, WidgetRootBreadcrumbs } from '@/interfaces/global/widgets'
import { MediaDto, ContentDto, PageDto, PartDto } from '@/interfaces/global'

const { fetchDecorator, 
  setErrorFetchingContent,
  setIsLoading,
  isLoading } = useFetch()

const currentWidgetView: Ref<string|null> = ref(null)
const previousWidgetView: Ref<string|null> = ref(null)

const media: Ref<MediaDto|null> = ref(null)
const isBook = computed(() => {
  return media.value?.contentType === WidgetMediaTypes.book
})

const chapter: Ref<ContentDto|null> = ref(null)
const parentChapters = ref([] as ContentDto[])
const parentChaptersPath = computed(() => {
  return ("/chapter:").concat(
    parentChapters.value
      .map(dto => dto.id)
      .join("/chapter:")
    )
})
const hasChapter = computed(() => !!chapter.value)
const chapterChildren = computed(() => chapter.value?.children)
const chapterPath = computed(() => {
  return `${media.value?.id}${parentChaptersPath.value}/chapter:${chapter.value?.id}`
})

const page: Ref<PageDto|null> = ref(null)
const pagePath = computed(() => {
  const mediaId = media.value?.id
  const chapterId = chapter.value?.id
  const pgId = page.value?.id

  return (!pgId) ? ''
    : (!isBook.value) ? `${pgId}`
    : (!hasChapter.value) ? `${mediaId}/page:${pgId}`
    : `${mediaId}/chapter:${chapterId}/page:${pgId}`
})

const part: Ref<PartDto|null> = ref(null)

const partPath = computed(() => {
  return String(part.value?.id) ?? ''
  // Code might be needed in future to add BOM drill down*
  // const partId = part.value.id
  // const pgPartId = part.value.pagePartId
  // const pgId = page.value?.id
  // const chapterId = chapter.value?.id
  // const mediaId = media.value?.id
  // const isChapterBook = isBook.value && hasChapter.value
  //   && !!pgPartId
  // const isChapterLess = isBook.value && !hasChapter.value 
  //   && !!pgPartId
  
  // const result = (!partId) ? ''
  //   : isChapterBook ? `${mediaId}/chapter:${chapterId}/page:${pgId}/pagepart:${pgPartId}`
  //   : isChapterLess ? `${mediaId}/page:${pgId}/pagepart:${pgPartId}`
  //   : `${partId}`
})

const rootCrumb: Ref<WidgetRootBreadcrumbs|null> = ref(null)
const rootCrumbDto = computed(() => {
  const type = rootCrumb.value
  return (!type) ? []
    : [{ name: i18n.global.t(type), type }] as any
})

const breadcrumbs: ComputedRef<Breadcrumb[]> = computed(() => {
  try {
    const crumbs = ([] as Breadcrumb[])
      .concat(rootCrumbDto.value)
      .concat(media.value?.name ? toRaw(media.value) : [])
      .concat(chapter.value?.name 
        ? (toRaw(parentChapters.value) as any)
            .concat(toRaw(chapter?.value)) 
        : [])
      .concat(page.value?.name ? toRaw(page.value) : [])
      .concat(part.value?.name ? toRaw(part.value) : [])

    return crumbs
  } catch {
    return []
  }
})

const isWithinSearchContext = computed(() => {
  return rootCrumb.value === WidgetRootBreadcrumbs.search
})
const isWithinBrowseContext = computed(() => {
  return rootCrumb.value === WidgetRootBreadcrumbs.browse
})

const contentPath = computed(() => {
  return breadcrumbs.value.reduce((path, crumb) => {
    const types = new Set([ 
      MediaWidgetEntityTypes.chapter, 
      MediaWidgetEntityTypes.page ])

    if (types.has(crumb.type as MediaWidgetEntityTypes)) {
      path += `/${crumb.type}:${crumb.id}`
    }

    return path
  }, '')
})

const contentInViewId = computed(() => {
  const contentToIdMap = new Map([
    [MediaWidgetEntityTypes.media, media.value?.id],
    [MediaWidgetEntityTypes.chapter, chapter.value?.id],
    [MediaWidgetEntityTypes.page, page.value?.id]
  ])
  return contentToIdMap.get(currentWidgetView.value as MediaWidgetEntityTypes)
})

export function useWidgetNavigator() {
  const router = useRouter()
  const store = useStore()

  const isPageContentLoaded = computed(() => {
    return store.state.content.isLoaded && !isLoading.value
  })

  function setMedia(val: MediaDto|null) {
    media.value = val
  }

  async function navToMediaDecorator(asset: any) {
    setIsLoading(true)
    const entity: MediaWidgetEntityTypes = MediaWidgetEntityTypes[asset.type as MediaWidgetEntityTypes]

    if (!entity || isCurrentContent(asset)) {
      // When selecting either exact same content
      // or 2nd instance of same content in a book,
      // just show spinners & refresh content
      setTimeout(() => setIsLoading(false), 250)
      return
    }

    switch(entity) {
      case MediaWidgetEntityTypes.media:
        media.value = asset
        chapter.value = null
        page.value = null
        parentChapters.value = []
        break
      case MediaWidgetEntityTypes.chapter:
        updatePreviousChapters(asset)
        chapter.value = asset
        page.value = null
        break
      case MediaWidgetEntityTypes.page:
        page.value = asset
    }
    setCurrentWidgetView(MediaWidgetEntityTypes[entity])
  }

  async function navToViewWithLoading(asset: any) {
    await navToMediaDecorator(asset)
  }
  function isCurrentContent(asset: any) {
    switch (currentWidgetView.value as MediaWidgetEntityTypes) {
      case MediaWidgetEntityTypes.media:
        return media.value?.id === asset.id
      case MediaWidgetEntityTypes.chapter:
        return chapter.value?.id === asset.id
      case MediaWidgetEntityTypes.page:
        return page.value?.id === asset.id
      default:
        return false
    }
  }

  function clearParentChapters() {
    parentChapters.value = []
  }
  function updatePreviousChapters(asset: any) {
    if (isChildChapter(asset)) {
      parentChapters.value = (parentChapters.value as any)
        .concat(chapter.value)
    } else {  // Check if selected chapter is w/n previous chapters/breadcrumbs
      const idx = parentChapters.value
        .findIndex((it: any) => it.id === asset.id)
      if (idx === -1) return
      parentChapters.value = [ 
        ...parentChapters.value.slice(0, idx) 
      ]
    }
  }

  function isChildChapter(asset: any) {
    return chapter.value?.children
      ?.some((child: any) => child.id === asset.id)
      ?? false
  }

  function updateChapters(updatedChapter: ContentDto|null, updatedParentChapters: ContentDto[] = []) {
    chapter.value = updatedChapter
    parentChapters.value = updatedParentChapters
  }

  function setCurrentWidgetView(component: any, { clearAllContent = false } = {}) {
    if (clearAllContent) {
      clearAllContentState()
    }
    previousWidgetView.value = currentWidgetView.value
    currentWidgetView.value = component
  }

  function setPart(dto: PartDto|null) {
    part.value = dto
  }

  function clearPart() {
    setPart(null)
  }

  async function setCurrentWidgetViewWithLoading(component: any) {
    await fetchDecorator(setCurrentWidgetView)(component)
  }

  function getComponentInViewFromMapping(viewToComponent: {[key: string]: any}) {
    return function() {
      try {
        return viewToComponent[currentWidgetView.value as string]
      } catch(e) {
        setErrorFetchingContent(e)
      }
    }
  }

  function mapDtoAndNavToWidgetContent(
    proxy: any, map: Map<string, string>, isSearch = false) {
    const dto = toRaw(proxy)
    const mappedDto: any = {}
    map.forEach((value, key) => {
      if (map.has(key) && !!dto[value]) {
        mappedDto[key] = dto[value]
      }
    })
    if (isSearch) {
      setRootBreadcrumb(WidgetRootBreadcrumbs.search)
    }
    navigateToContent(mappedDto)
  }

  function navigateToContent(dto: any) {
    switch(dto.type) {
      case MediaWidgetEntityTypes.media as string:
      case MediaWidgetEntityTypes.chapter as string:
      case MediaWidgetEntityTypes.page as string:
        navToViewWithLoading(dto)
        break
      case "part":
        setPart(dto)
        setCurrentWidgetViewWithLoading('info')
        // WidgetInfoPage.vue handles supplementary logic
        // for fetching content & tags in onMounted
    }
  }

  function goHome() {
    router.push({ query: {} })
    setCurrentWidgetView('home')
    clearAllContentState()
    setRootBreadcrumb(null)
  }

  function clearContentState() {
    media.value = null
    chapter.value = null
    parentChapters.value = []
    page.value = null
    clearPart()
  }

  function clearAllContentState() {
    clearContentState()
    if (isWithinSearchContext.value) {
      store?.commit('search/SEARCH_FILTERS_CLEAR_SELECTED')
    }    
  }

  function setRootBreadcrumb(value: WidgetRootBreadcrumbs|null) {
    const safeValue = (value as WidgetRootBreadcrumbs) ?? null
    rootCrumb.value = safeValue
  }

  function setPageHashkey(hashKey: string) {
    if (!!page.value) {
      page.value.hashKey = hashKey
    }
  }

  function setParentChapters(parents: ContentDto[]) {
    parentChapters.value = parents
  }

  function setPage(val: PageDto|null) {
    page.value = val
  }

  const entityIdToIdDtoMap = new Map([
    ["contentType", "mediaType"],
    ["created", "created"],
    ["description", "description"],
    ["id", "entityId"],
    ["identifier", "identifier"],
    ["name", "name"],
    ["type", "entityType"],
    ["updated", "updated"]
  ])

  return { 
    media: readonly(media), 
    chapter: readonly(chapter), 
    page: readonly(page), 
    part: readonly(part),
    currentWidgetView: readonly(currentWidgetView), 
    parentChapters: readonly(parentChapters),
    breadcrumbs,
    pagePath,
    partPath,
    chapterChildren,
    chapterPath,
    isBook,
    hasChapter,
    contentInViewId,
    contentPath,
    isWithinBrowseContext,
    isWithinSearchContext,
    setCurrentWidgetView,
    getComponentInViewFromMapping,
    navigateToContent,
    mapDtoAndNavToWidgetContent,
    goHome,
    clearPart,
    navToViewWithLoading,
    clearParentChapters,
    setPageHashkey,
    updateChapters,
    setRootBreadcrumb,
    setMedia,
    clearAllContentState,
    setParentChapters,
    setPart,
    entityIdToIdDtoMap: readonly(entityIdToIdDtoMap),
    isPageContentLoaded,
    clearContentState,
    previousWidgetView,
    setPage
  }
}