<template>
  <global-search-bar  :isExactMatch="isExactMatch"
                      :input="input"
                      @update:input="navigateToSearch($event, {
                        clearAllContent: true
                      })"
                      @update:isExactMatch="setIsExactMatch"/>
</template>

<script setup>
import GlobalSearchBar from '@/components/GlobalSearchBar'
import { useSearch } from '@/components/widgets/composables/useSearch'

const { navigateToSearch, input, 
  isExactMatch, setIsExactMatch } = useSearch()
</script>