<template>
  <section v-if="related.length" class="section is-paddingless">
    <section class="related-results">
      <div data-int="related" class="columns is-multiline is-mobile">
        <div v-for="item in related" :key="item.id" class="column is-2-desktop is-3-tablet is-4-mobile">
          <card :item="item" 
                :isWidget="isWidget"
                data-int="related-card" 
                @click="select"
                @navToWidget="navToWidgetContent"/>
        </div>
      </div>
    </section>
    <pagination v-if="related.length"
                :offset="offset"
                :limit="limit"
                :total="total"
                @changePage="changePage"
                @changePerPage="changePerPage"/>
  </section>
  <section v-else-if="!total" class="no-data">
    <h1 data-int="related-no-data" class="subtitle has-text-centered">
      {{ $t('assetsNotFound', {type: $t('related')}) }}
    </h1>
  </section>
</template>

<script>
import { computed, toRaw } from 'vue'
import { mapState, mapActions, useStore } from 'vuex';
import Card from '@/components/common/Card';
import Pagination from '@/components/common/Pagination';
import { WidgetTypes } from '@/interfaces/global';
import { useInfoLandingPage } from "@/components/widgets/composables/useInfoLandingPage";

export default {
  name: 'Related',
  setup() {
    const store = useStore()
    const { navigateToRelatedContent } = useInfoLandingPage()

    const isWidget = computed(() => store.getters['widgets/isWidget'])
    const widgetType = computed(() => store.getters['widgets/widgetType'])
    const isLibraryWidget = computed(() => isWidget.value
      && widgetType.value === WidgetTypes.library)

    async function navToWidgetContent(statefulDto) {
      const dto = toRaw(statefulDto)
      const isRelatedMedia = dto.type === "media"
      const isFile = Object.hasOwn(dto, 'contentUrl') 
        && Object.hasOwn(dto, 'name')
        && !Object.hasOwn(dto, 'type')
      if (isLibraryWidget.value && isRelatedMedia) {
        navigateToRelatedContent(dto)
      }
      else if (isFile) {
        const fileData = await fetch(dto.contentUrl)
        const blob = await fileData.blob()
        
        window.parent.postMessage(
          { item: { blob, name: dto.name }, 
            action: "downloadFile" }, 
          store.getters['widgets/targetUri'])
      }
    }

    const widgetRelated = computed(() => {
      if (isLibraryWidget.value) {
        return store.state.content.related.items
      }
      return store.state.content.related.items
    })
    const related = computed(() => {
      return isWidget.value 
        ? widgetRelated.value
        : store.state.content.related.items
    })

    return { related, isWidget, navToWidgetContent }
  },
  components: {
    Card,
    Pagination
  },
  computed: {
    ...mapState({
      contentLoaded: ({ content }) => content.isLoaded,
      total: ({ content }) => content.related.total,
      offset: ({ content }) => content.related.offset,
      limit: ({ content }) => content.related.limit
    }),
  },
  watch: {
    async contentLoaded() {
      await this.getRelated();
    },
    offset() {
      this.scrollToTop();
    }
  },
  methods: {
    ...mapActions({
      getRelated: 'content/getRelated',
      navigateToContent: 'content/navigateToContent',
      changePage: 'content/setRelatedPage',
      changePerPage: 'content/setRelatedResultsPerPage'
    }),
    scrollToTop() {
      const results = document.getElementsByClassName('related-results');
      if (results.length) {
        results[0].scrollTo(0, 0);
      }
    },
    select(content) {
      this.navigateToContent({ content })
    }
  },
  async created() {
    await this.getRelated();
  }
};
</script>

<style scoped>
.related-results {
  flex: 1;
  overflow: auto;
  padding: 1rem;
  border-bottom: lightgray 1px solid;
}
.no-data {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
</style>
