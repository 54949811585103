import http from '@/http';
import router from '@/router';

import {
  CONTENT_RECEIVED, WHEREUSED_RECEIVED, WHEREUSED_COUNT, WHEREUSED_UPDATE_OFFSET, WHEREUSED_UPDATE_LIMIT, CLEAR_WHEREUSED
} from './contentTypes';
import { getRoute } from "@/router/getRoute";

const state = getDefaultState();

const actions = {
  async getWhereUsed({commit, rootState}) {
    try {
      if (rootState.content.isLoaded) {
        if (rootState.content.type === 'part' || rootState.content.type === 'page') {
          const entityType = rootState.content.type;
          const entityId = rootState.content.id;

          const params = {
            limit: state.limit,
            offset: state.offset * state.limit
          };
          params[`fk_${entityType}_id`] = entityId;

          if (rootState.content.type === 'page') {
            params.entity_type = 'Media';
          }

          const {headers, data} = await http.get('search', {params});
          commit(WHEREUSED_RECEIVED, {items: data.items, meta: headers});
        } else {
          const currentRoute = getRoute();
          const contentPath = currentRoute.path.substring(0, currentRoute.path.indexOf('where-used'));

          router.replace({path: `${contentPath}info`});
        }
      }
    } catch (err) {
      commit(WHEREUSED_RECEIVED, {items: [], meta: {'x-count': 0}});
    }
  },
  setWhereUsedPage({commit, dispatch}, page) {
    commit(WHEREUSED_UPDATE_OFFSET, {
      offset: Math.max(page, 0)
    });
    dispatch('getWhereUsed');
  },
  setWhereUsedResultsPerPage({commit, dispatch}, limit) {
    commit(WHEREUSED_UPDATE_LIMIT, {limit});
    dispatch('getWhereUsed');
  },
  setWhereUsedReceivedCount({commit}, count ) {
    commit(WHEREUSED_COUNT, { count });
  }
};

const mutations = {
  [WHEREUSED_COUNT](state, { count }) {
    state.total = count;
  },
  [CONTENT_RECEIVED](state, {content}) {
    state.items = [];
    state.offset = 0;
    state.limit = 20;
    state.total = content.whereUsedCount || 0;
  },
  [WHEREUSED_UPDATE_OFFSET](state, {offset}) {
    state.offset = offset;
  },
  [WHEREUSED_UPDATE_LIMIT](state, {limit}) {
    state.limit = limit;
    state.offset = 0;
  },
  [CLEAR_WHEREUSED] (state) {
    Object.assign(state, getDefaultState())
  }
};

export default {
  state,
  actions,
  mutations
};

function getDefaultState() {
  return {
    items: [],
    offset: 0,
    limit: 20,
    total: 0
  }
}
