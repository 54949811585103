import http from '@/http'
import {cloneDeep} from "lodash"
import { getRoute } from "@/router/getRoute"

function sortOrder (a, b, ...fields) {
  const [comp] = fields
    .map((f) => a[f] - b[f])
    .filter((c) => c !== 0)

  return comp || 0
}

function currySortOrder (...fields) {
  return (a, b) => sortOrder(a, b, ...fields)
}

function tagNameSorter () {
  return currySortOrder(
    (o) => o.displayOrder,
    (o) => o.name,
    (o) => o.id
  )
}

export default {
  namespaced: true,
  state: {
    activeTagName: null,
    tagNamesData: [],
    tagNamesError: false,
    tagNamesLoading: false,
    tableData: [],
    filter: ''
  },
  mutations: {
    'TAG_NAMES_LOADING' (state, payload) {
      state.tagNamesLoading = payload
    },
    'TAG_NAMES_ERROR' (state, payload) {
      state.tagNamesError = payload
    },
    'TAG_NAMES_SUCCESS' (state, payload) {
      state.tagNamesData = payload
    },
    'SET_ACTIVE_TAG_NAME' (state, payload) {
      state.activeTagName = payload
    },
    'UNSET_ACTIVE_TAG_NAME' (state) {
      state.activeTagName = null
    },
    'SET_TABLE_DATA' (state, tagData) {
      state.tableData = tagData
    },
    'SET_FILTER' (state, term) {
      state.filter = term
    }
  },
  actions: {
    async getTagNames (context, type) {
      try {
        const route = getRoute()
        context.commit('TAG_NAMES_LOADING', true)
        const endPoint = type === "tenant" && context.rootState.user.superAdmin
          ? `/super-admin/tag-names/by-tenant/${route.params.id}`
          : "/tag-names"
        const result = await http.get(endPoint).then((res) => res.data)

        result.sort(tagNameSorter)
        context.commit('TAG_NAMES_SUCCESS', result)
      } catch (error) {
        console.log('error :>> ', error)
        context.commit('TAG_NAMES_ERROR', error)
      } finally {
        context.commit('TAG_NAMES_LOADING', false)
      }
    },
    setActiveTagName (context, data) {
      context.commit('SET_ACTIVE_TAG_NAME', data)
    },
    unsetActiveTagName (context) {
      context.commit('UNSET_ACTIVE_TAG_NAME')
      context.commit('SET_FILTER', '')
    },
    setTableData(context, tableData) {
      context.commit('SET_TABLE_DATA', tableData)
    },
    addToTableData(context, dtoList) {
      const tableData = cloneDeep(context.state.tableData)
      for (let index = 0; index < dtoList.length; index++) {
        const dto = dtoList[index];
        const dtoIndex = tableData
          .findIndex(tag => tag.name === dto.tagName)

        if (dtoIndex === -1) {
          tableData.push({
            name: dto.tagName,
            data: {
              tagCount: 1,
              tagValues: [dto]
            }
          })
          continue
        }

        const values = tableData[dtoIndex ].data.tagValues
        const valueIdx = values
          .findIndex(it => it.pendingId === dto.pendingId)

        if (valueIdx === -1) {
          tableData[dtoIndex].data.tagCount++
          tableData[dtoIndex].data.tagValues.push(dto)
        } else {
          Object.assign(values[valueIdx], dto)
        }
      }
      context.commit('SET_TABLE_DATA', tableData)
    },
    updateTableData(context, dtoList) {
      const tableData = cloneDeep(context.state.tableData)

      for (let index = 0; index < dtoList.length; index++) {
        const dto = dtoList[index]
        const tagIdx = tableData
          .findIndex(it => it.name === dtoList[index].tagName)
        const tag = tableData[tagIdx]
        const tagValueidx = tag.data.tagValues
          .findIndex(it => it.id === dto.id)
        tableData[tagIdx].data.tagValues[tagValueidx] = dto
      }

      context.commit('SET_TABLE_DATA', tableData)
    },
    clearTableData(context) {
      context.commit('SET_TABLE_DATA', [])
      context.commit('SET_FILTER', '')
    },
    setFilter(context, term) {
      context.commit('SET_FILTER', term)
    }
  },
  getters: {
    applicableTagNames: (state) => (type) => {
      if (state.tagNamesData.length) {
        const filterInput = type === 'page-part' ? 'PAGE_PART' : type.toUpperCase()
        return state.tagNamesData.filter(({ applicableTypes }) =>
          applicableTypes && applicableTypes.includes(filterInput)
        )
      }
      return []
    }
  }
}
