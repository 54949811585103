<template>
  <component  :is="widgetInView"/>
  <widget-documoto-logo />
</template>

<script setup>
import { computed } from "vue"
import { useStore } from "vuex"
import { WidgetTypes } from '@/interfaces/global/widgets'
import { useFetch } from '@/components/widgets/composables/useFetchContent'
import WidgetContentError from '@/components/widgets/errorPages/WidgetContentError.vue'
import WidgetRefreshError from '@/components/widgets/errorPages/WidgetRefreshError.vue'
import LoadingWidget from '@/components/widgets/types/LoadingWidget.vue'
import MediaWidget from '@/components/widgets/types/MediaWidget.vue'
import LibraryWidget from '@/components/widgets/types/LibraryWidget.vue'
import WidgetDocumotoLogo from '../components/documotoLogo/WidgetDocumotoLogo'
import PageWidget from '@/components/widgets/types/PageWidget.vue'

const store = useStore()
const widgetType = computed(() => {
  return store.getters['widgets/widgetType']
})
const hasRefreshFailed = computed(() => {
  return !store.state.auth.isRefreshTokenValid
})
const { errorFetchingContent } = useFetch()

const WidgetTypeToComponent = Object.freeze({
  [WidgetTypes.media]: MediaWidget,
  [WidgetTypes.library]: LibraryWidget,
  [WidgetTypes.loading]: LoadingWidget,
  [WidgetTypes.page]: PageWidget
})

const widgetInView = computed(() => {
  return (!!errorFetchingContent.value) ? WidgetContentError
    : hasRefreshFailed.value ? WidgetRefreshError
    : WidgetTypeToComponent[widgetType.value]
    ?? WidgetContentError
})

</script>
<style>
#app {
  overflow: hidden;
}
</style>