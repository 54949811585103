import { ref, computed, ComputedRef, readonly, toRaw }  from 'vue'
import { useStore } from 'vuex'

import { useWidgetNavigator } from './useWidgetNavigator'
import { useWidgetToc } from './useWidgetToc'
import { useWidgetEvents } from './useWidgetEvents'
import { useSearch } from './useSearch'
import { useFetch } from './useFetchContent'

import { WidgetTypes, 
  WidgetEventErrors,
  LibraryWidgetPages, 
  WidgetRootBreadcrumbs,
  WidgetMediaTypes,
  MediaWidgetEntityTypes, 
  TocFlatListItemDto } from '@/interfaces/global/widgets'

import { ContentDto, PageDtoExtended, 
  TocFlatListDto } from '@/interfaces/global'

import { getPageByHashKey, 
  getPage,
  getMediaById,
  getMediaByIdentifier } from '@/controllers/library'

const tocQuery = ref('')
const isSelectingBomItemViaWidgetConfig = ref(false)

export function useOpenToContent() {
  const store = useStore()

  const { goToContentInBook } = useWidgetToc()
  const { emitLoginEvent, emitErrorEvent } = useWidgetEvents()
  const { navigateToSearch } = useSearch()
  const { setErrorFetchingContent } = useFetch()

  const { 
    currentWidgetView,
    isBook,
    media,
    page,
    pagePath,
    setMedia,
    setCurrentWidgetView,
    setRootBreadcrumb,
    navigateToContent,
    navToViewWithLoading,
    setPageHashkey,
    clearAllContentState,
    setParentChapters } = useWidgetNavigator()
  
  let landingPage: string|undefined
  let isSettingLandingPage = false

  const widgetType: ComputedRef<WidgetTypes> = computed(
    () => store.getters['widgets/widgetType']
  )
  const mediaIdentifier = computed(() => {
    return store.getters["widgets/mediaIdentifier"];
  })
  const pageHashKey = computed(() => {
    return store.getters['widgets/pageHashKey']
  })
  const partNumber = computed(() => {
    return store.getters['widgets/partNumber']
  })
  const hasContentPath = computed(() => {
    const hasPageOrPart = !!pageHashKey.value
      || !!partNumber.value

    switch(widgetType.value) {
      case WidgetTypes.library:
        return !!mediaIdentifier.value || hasPageOrPart
      case WidgetTypes.media:
        return hasPageOrPart
      default:
        return false
    }
  })
  const tocFlatList = computed(() => {
    return store.getters['content/widgetTocFlatList']
  })

  const tocFlatMap = computed(() => {
    return store.getters['content/widgetTocFlatMap']
  })

  const widgetsWithHomeBreadcrumb = computed(() => {
    return new Set([ WidgetTypes.library ])
  })

  const configHasPageAndPart = computed(() => {
    return !!pageHashKey.value && !!partNumber.value
  })

  const hasWidgetTocEnabled = computed(() => {
    return store.getters['widgets/showToc']
  })

  const willPreFetchBomItems = computed(() => {
    return !!media.value 
      && configHasPageAndPart.value
      && isSettingLandingPage
  })

  function setHomeBreadcrumb() {
    if (widgetsWithHomeBreadcrumb.value.has(widgetType.value)) {
      setRootBreadcrumb(WidgetRootBreadcrumbs.home)
    }
  }

  async function fetchAndSetMedia(identifier: string) {
    const media = await getMediaByIdentifier(identifier)
    setMedia(media)
    setHomeBreadcrumb()
    landingPage = LibraryWidgetPages.media
  }

  async function handleLibraryWidget() {
    if (!hasContentPath.value) {
      setCurrentWidgetView(LibraryWidgetPages.home)
    } else {
      await openToContentPath()
    }
  }

  async function openToContentPath() {
    try {
      if (!!mediaIdentifier.value) {
        await fetchAndSetMedia(mediaIdentifier.value)
      }
      if (!!pageHashKey.value) {
        await getContentForPage()
      }
      if (!!partNumber.value) {
        await navigateToPartNumber()
      }
    } catch(e: any) {
      const isMediaWidget = (widgetType.value === WidgetTypes.media)
      const isRequestForMedia = e?.request?.responseURL?.includes('/medias/by-identifier') ?? false
      if (isMediaWidget && isRequestForMedia && isSettingLandingPage) {
        throw WidgetEventErrors.config
      } 
      throw WidgetEventErrors.location
    } finally {
      if ((landingPage === LibraryWidgetPages.media) 
        && (currentWidgetView.value !== LibraryWidgetPages.media)) {
        navToViewWithLoading(media.value)
      } else if (!currentWidgetView.value) {
        setCurrentWidgetView(LibraryWidgetPages.home)
      }
      landingPage = undefined
    }
  }

  async function selectFirstOccurrenceInBom() {
    await fetchBomContentForVuex()
    const bomItem = store.state.content.bom.items
      .find((it: { partNumber: string }) => {
        return it.partNumber === partNumber.value
      })
    if (!bomItem) throw WidgetEventErrors.location

    // There is an existing bug affecting Bom.vue & bom.js module.
    // BOM_RECEIVED mutation too loosely assigns keys in itemsMap,
    // resulting in sometimes a key of 'undefined'.
    // This collides with a search query value of '', resulting
    // in a false match that auto-selects a BOM item on mount.
    // This state var supports an if check to block the bug from overriding
    // selection in the BOM of the provided partNumber.
    setIsSelectingBomItemViaWidgetConfig(true)
    await store.dispatch('content/selectBomItems', { item: bomItem })
  }

  async function navigateToPartNumber() {
    // When:
      // !!page -> select first occurrence of part in BOM
      // !!media && !page -> Book TOC search on part
      // !media && !page -> navigateToSearch & search on part
    if (!!page.value) {
      await selectFirstOccurrenceInBom()
    } 
    else if (!!media.value && !page.value) {
      // Navigate to the book
      await navToViewWithLoading(media.value)
      // TableOfContents.vue doesn't finish loading toc on mount.
      // Consequently, it uses a watcher to call search
      // when the toc finishes loading. Setting this state here,
      // allows said watcher to use it to update the search query
      // param to the provided partNumber.
      // Attempts at pre-fetching TOC result so it's present on mount
      // resulted in race condition with follow-on navigation to BOM.
      setTocQuery(partNumber.value)
    } 
    else if ((!media.value && !page.value) 
      && (widgetType.value === WidgetTypes.library)) {
      navigateToSearch(partNumber.value)
    }
  }

  async function handleMediaWidget() {
    if (!hasContentPath.value) {
      try {
        const mediaDto = await getMediaByIdentifier(mediaIdentifier.value)
        setMedia(mediaDto)
        navToViewWithLoading(mediaDto)
      } catch(error) {
        setErrorFetchingContent(error)
        throw WidgetEventErrors.config
      }
    } else {
      await openToContentPath()
    }
  }

  async function getContentForPage() {
    try {
      const page = await getPageByHashKey(pageHashKey.value)
      if (!!isBook.value) {
        await fetchTocForVuex()
        const idx = getPageIndexInFlatList(page)
        if (idx >= 0) {
          const content = tocFlatList.value[idx]
          await goToContentInBook(content)
        } else {
          throw WidgetEventErrors.location
        }
      } else {
        navigateToContent(page)
      }
      if (widgetType.value === WidgetTypes.library) {
        setHomeBreadcrumb()
      }
      landingPage = LibraryWidgetPages.page
    } catch(e) {
      throw WidgetEventErrors.location
    }
  }

  async function handlePageWidget() {
    try {
      const page = await getPageByHashKey(pageHashKey.value)
      navigateToContent(page)
    } catch {
      throw WidgetEventErrors.config
    }

    if (!!partNumber.value) {
      await selectFirstOccurrenceInBom()
    }
  }

  async function setLandingPage() {
    const handlerMap = new Map([
      [WidgetTypes.library, handleLibraryWidget],
      [WidgetTypes.media, handleMediaWidget],
      [WidgetTypes.page, handlePageWidget], 
    ])
    try {
      isSettingLandingPage = true
      const handler = handlerMap.get(widgetType.value) 
      if (!handler) throw 'invalidType'
      await handler()
      emitLoginEvent()
    } catch(error) {
      switch(error as WidgetEventErrors) {
        case WidgetEventErrors.location:
          emitLoginEvent()
          emitErrorEvent({ error })
          break
        default:
          emitErrorEvent({ error: WidgetEventErrors.config }) 
      }
    } finally {
      isSettingLandingPage = false
    }
  }

  async function fetchTocForVuex() {
    await store.dispatch('content/getTOC', 
      { id: media.value?.id, type: 'book' })
  }

  async function fetchBomContentForVuex() {
    const params = {
      id: pagePath.value,
      type: isBook.value 
        ? WidgetMediaTypes.book 
        : MediaWidgetEntityTypes.page
    }
    await store.dispatch("content/getContent", params)
    if (pageHashKey.value !== store.state.content.pageHashkey) {
      setPageHashkey(store.state.content.pageHashkey)
    }
  }

  function getPageIndexInFlatList(page: PageDtoExtended) {
    return tocFlatList.value
      .findIndex(({ item: { type, id } }: TocFlatListDto) => {
        return (type === 'page') && (id === page.id)
      })
  }

  function setTocQuery(value: string) {
    if (hasWidgetTocEnabled.value) {
      tocQuery.value = value
    }
  }

  function setIsSelectingBomItemViaWidgetConfig(value: boolean) {
    isSelectingBomItemViaWidgetConfig.value = value
  }

  function getFirstMatchingIdxInBook({ type, id }: { type: string, id: number }) {
    const itemPath = `${type}:${id}`
    const tocKeys: string[] = [ ...tocFlatMap.value.keys() ]
    return tocKeys
      .findIndex((it: string) => it.includes(itemPath)) 
  }

  function hasHotpointLinkInBook(linkDto: { type: string, id: number }) {
    const idx = getFirstMatchingIdxInBook(linkDto)
    return ((linkDto.type === WidgetTypes.page as string) || (linkDto.type === 'chapter'))
      && (idx >= 0)
  }

  async function openToContentDecorator(fun: Function, id: number) {
    try {
      clearAllContentState()
      const contentDto = await fun(id)
      navigateToContent(contentDto)
    } catch {
      setErrorFetchingContent(true)
    }
  }

  async function openToChapter(id: number, mediaId: number) {
    const getMediaAndChapter = async () => {
      const mediaDto = await getMediaById(mediaId)
      setMedia(mediaDto)
      await fetchTocForVuex() 
      const chapterDto = store.getters['content/widgetTocFlatList']
        .find((it: TocFlatListItemDto) => (it.item.type === 'chapter') && (it.item.id === id))
        .item
      return chapterDto
    }
    await openToContentDecorator(getMediaAndChapter, id)
    updateParentChaptersForChapter(id)
  }

  function updateParentChaptersForChapter(chapterId: number) {
    const chapterTocDto: TocFlatListItemDto|undefined = store.getters['content/widgetTocFlatList']
      .find((it: TocFlatListItemDto) => (it.item.id === chapterId) && (it.item.type === 'chapter'))
    const parentChapterIdList = Array.from(chapterTocDto?.chapters ?? [])
      .filter(id => id !== chapterId)
    const parentChapters: ContentDto[] = parentChapterIdList.map(id => {
      return toRaw(store.getters['content/widgetTocFlatList'].find((it: TocFlatListItemDto) => {
        return (it.item.id === id) && (it.item.type === 'chapter')
      }).item)
    })
    if (!!parentChapters.length) {
      setParentChapters(parentChapters)
    }
  }

  async function widgetOpenHotpointLink(link: { id: number, name: string, type: string, mediaId?: number }) {
    const authorizedWidgets = new Set([ 
      WidgetTypes.library, 
      WidgetTypes.media ])

    if (!authorizedWidgets.has(widgetType.value)) return

    const firstMatchIdx = getFirstMatchingIdxInBook(link)
    if (firstMatchIdx >= 0) {
      const item:  TocFlatListItemDto = toRaw(tocFlatList.value[firstMatchIdx])
      await goToContentInBook(item)
    } else if (widgetType.value === WidgetTypes.library) {
      switch (link.type) {
        case MediaWidgetEntityTypes.media as string:
          await openToContentDecorator(getMediaById, link.id)
          break
        case MediaWidgetEntityTypes.page as string:
          await openToContentDecorator(getPage, link.id)
          break
        case MediaWidgetEntityTypes.chapter as string:
          if (!!link.mediaId && typeof link.mediaId === 'number') {
            await openToChapter(link.id, link.mediaId)
          }
      }
    }
  }

  return { 
    tocQuery: readonly(tocQuery), 
    isSelectingBomItemViaWidgetConfig: readonly(isSelectingBomItemViaWidgetConfig),
    configHasPageAndPart,
    willPreFetchBomItems,
    setTocQuery, 
    setLandingPage, 
    fetchBomContentForVuex,
    setIsSelectingBomItemViaWidgetConfig,
    widgetOpenHotpointLink,
    hasHotpointLinkInBook
   }
}